import React, { useContext } from 'react';
import { FaAddressBook, FaBuilding, FaPills, FaPlus, FaSync } from 'react-icons/fa';
import HeaderReusable from '../../Global/HeaderContent';
import { FaClipboardList } from "react-icons/fa6";

import { SettingGeneralContext, SettingGeneralProvider } from '../../../context/settingGeneralContext';

export default function HeaderMedicalItem({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh,
  showCustomButton,
  handleFileUpload,
  customButtonLabel,
}) {
  return (
    <SettingGeneralProvider>
      <HeaderMedicalItemContent
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        viewModalAdd={viewModalAdd}
        buttonRefresh={buttonRefresh}
        showCustomButton={showCustomButton}
        handleFileUpload={handleFileUpload}
        customButtonLabel={customButtonLabel}
      />
    </SettingGeneralProvider>
  )
}


function HeaderMedicalItemContent({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh,
  showCustomButton,
  handleFileUpload,
  customButtonLabel,
}) {
  return (
    <>
      <HeaderReusable
        title="List Diagnosa SSP"
        icon={FaClipboardList}
        isTabletOrMobile={false}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        onAdd={viewModalAdd}
        onRefresh={buttonRefresh}
        showAddButton={true}
        showRefreshButton={true}
        showCustomButton={showCustomButton}
        handleFileUpload={handleFileUpload}
        customButtonLabel={customButtonLabel}
      />
    </>
  )
}
