import React, { useContext, useState, useEffect } from "react";
import { Button, Col, Form, Modal, Row } from "react-bootstrap";
import { API } from "../../../../config/api";
import { SettingGeneralContext } from "../../../../context/settingGeneralContext";
import swal from "sweetalert";
import ModalCustomizeSidebar from "./Modal/CustomizeSidebar";

const General = () => {
    const context = useContext(SettingGeneralContext);
    const [formData, setFormData] = useState({});
    const [isEditing, setIsEditing] = useState(false);
    const [loading, setLoading] = useState(false);
    const [show, setShow] = useState(false);

    const { state, dispatch } = context;
    const { generalSettings } = state;

    useEffect(() => {
        if (!isEditing) {
            setFormData(generalSettings || {});
        }
    }, [generalSettings]);

    const handleClose = () => {
        setShow(false);
    };

    const handleShow = () => {
        setShow(true);
    };

    const handleChange = (e) => {
        setIsEditing(true);
        const { name, value } = e.target;

        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
    };

    const handleSubmit = async (e = null) => {
        if (e) e.preventDefault();
        setLoading(true);

        const token = localStorage.getItem("token");
        let fetchParams = {
            headers: { Authorization: `${token}`, "Content-Type": "application/json" },
        };

        try {
            const response = await API.post("user-service/settings/general/store", formData, fetchParams);
            if (response?.status === 200) {
                dispatch({ type: "SET_GENERAL_SETTINGS", payload: response.data.data.general });
                setIsEditing(false);
                swal({
                    title: "Berhasil",
                    text: "Data berhasil disimpan!",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                });
            }
        } catch (error) {
            swal({
                title: "Gagal",
                text: error.response?.data?.message || "Gagal menyimpan data",
                icon: "error",
                timer: 3000,
                buttons: false,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            <Row className="float-end">
                <Form.Group className="mb-3">
                    <Button variant="primary" onClick={handleShow}>
                        Add Theme
                    </Button>
                </Form.Group>
                <ModalCustomizeSidebar
                    formData={formData}
                    setFormData={setFormData}
                    handleClose={handleClose}
                    handleSubmit={handleSubmit}
                    show={show}
                    loading={loading}
                />
            </Row>
            <Row>
                <Form onSubmit={handleSubmit}>
                    <Row>
                        <Col xl="6" sm="6">
                            <Form.Group className="mb-3">
                                <Form.Label>Nama Aplikasi</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="app_name"
                                    value={formData.app_name || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Deskripsi Aplikasi</Form.Label>
                                <Form.Control
                                    as="textarea"
                                    name="app_desc"
                                    value={formData.app_desc || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Logo (URL)</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="app_logo"
                                    value={formData.app_logo || ""}
                                    onChange={handleChange}
                                />
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Tema</Form.Label>
                                <Form.Select
                                    name="app_theme"
                                    value={formData.app_theme || "light"}
                                    onChange={handleChange}
                                >
                                    <option value="light">Light</option>
                                    <option value="dark">Dark</option>
                                </Form.Select>
                            </Form.Group>

                            <Button type="submit" variant="primary" className="float-end" disabled={loading}>
                                {loading ? "Menyimpan..." : "Simpan"}
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Row>
        </>
    );
};

export default General;
