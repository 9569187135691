import React, { useContext } from "react";
import { SettingGeneralContext, SettingGeneralProvider } from "../../../context/settingGeneralContext";


export default function WellcomeCard({
    getDataProfileNull,
    getDataProfileFirstName,
    getDataProfileLastName,
}) {
    return (
        <SettingGeneralProvider>
            <WellcomeCardContent
                getProfileNull={getDataProfileNull}
                getFirstName={getDataProfileFirstName}
                getLastName={getDataProfileLastName}
            />
        </SettingGeneralProvider>
    );
}

function WellcomeCardContent({
    getProfileNull,
    getFirstName,
    getLastName
}) {
    const { state } = useContext(SettingGeneralContext);

    const appName = state?.generalSettings?.app_name;
    const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
    const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
    const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
    const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
    const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;

    const boxShadow =  appSidebarColor3 || "#BFBFBF";
    const colorText =  appSidebarColor2 || "#001F8B";

    return (
        <div
            style={{
                display: "flex",
                justifyContent: "center",
                backgroundColor: "white",
                boxShadow: `2px 2px 10px ${boxShadow}`,
            }}
        >
            <div
                style={{
                    padding: "10px",
                    textAlign: "center"
                }}
            >
                <div
                    style={{
                        fontSize: "20px",
                        color: `${colorText}`
                    }}>
                    {getProfileNull ? "" : getFirstName} {getProfileNull ? "" : getLastName}
                </div>
                <div
                    style={{
                        fontSize: "10px",
                        color: "#848484"
                    }}>
                    Selamat Bertugas, Have A Nice Day
                </div>
            </div>
        </div>
    );
};

