/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Col } from 'reactstrap'
import { API } from "../../config/api";
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";

import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import LoaderHome from "../Loader/LoaderHome"
import TableRoom from "./Components/TableRoom";
import PaginationRoom from "./Components/PaginationRoom";
import HeaderRoom from "./Components/HeaderRoom";
import { useMediaQuery } from "react-responsive";
import WellcomeCard from "../Admin/Components/Wellcome";
import { Row } from "react-bootstrap";



export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [IconUpdate, setIconUpdate] = useState();
  const [idFloorUpdate, setIdFloorpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })



  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/rooms?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      } else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfileDetail(response.data.data.user_detail)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataProfile()
  }, [page, keyword, limit, ascending])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.delete(`transaction-service/rooms/${id}`, fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }

  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, name, id_floor, idFloor) => {
    setModalUpdate(true)
    setId(id)
    setNameUpdate(name)
    setIconUpdate(id_floor)
    setIdFloorpdate(idFloor)
  }


  return (
    <>
      <Row>
        <WellcomeCard
          getDataProfileNull={getDataProfileDetail === null}
          getDataProfileFirstName={getDataProfileDetail?.firstname}
          getDataProfileLastName={getDataProfileDetail?.lastname}
        />
      </Row>
      <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
        {modalAdd && <ModalAddMedis GetResponseData={GetResponseData} show={modalAdd} onHide={() => setModalAdd(false)} />}
        {modalUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} nameUpdate={nameUpdate} floorUpdate={IconUpdate}  idFloorUpdate={idFloorUpdate} id={id} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
        {loading && <LoaderHome />}
        {isTabletOrMobile ?
          <>
            {/* start header */}
            <HeaderRoom
              isTabletOrMobile={isTabletOrMobile}
              query={query}
              setQuery={setQuery}
              searchData={searchData}
              viewModalAdd={viewModalAdd}
              buttonRefresh={buttonRefresh}
            />
            {/* end header */}
            <Col xl='12' sm='12'>
              <div>
                {/* start table */}
                <TableRoom
                  getData={getData}
                  rows={rows}
                  page={page}
                  pages={pages}
                  changePage={changePage}
                  msg={msg}
                  viewModalUpdate={viewModalUpdate}
                  deleteById={deleteById}
                  isTabletOrMobile={isTabletOrMobile}
                />
                {/* end table */}
              </div>
            </Col>
          </>
          :
          <>
            {/* start header */}
            <HeaderRoom
              isTabletOrMobile={isTabletOrMobile}
              query={query}
              setQuery={setQuery}
              searchData={searchData}
              viewModalAdd={viewModalAdd}
              buttonRefresh={buttonRefresh}
            />
            {/* end header */}

            <Col xl='12' sm='12'>
              <div>
                {/* start table */}
                <TableRoom
                  getData={getData}
                  rows={rows}
                  page={page}
                  pages={pages}
                  changePage={changePage}
                  msg={msg}
                  viewModalUpdate={viewModalUpdate}
                  deleteById={deleteById}
                  isTabletOrMobile={isTabletOrMobile}
                />
                {/* end table */}
              </div>
            </Col>
          </>
        }
      </div>
    </>
  );
}

// Clear
