/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { API } from "../../../../config/api";
import ModalDataKey from "./Modal/Keys";
import swal from "sweetalert";
import { FaEye } from "react-icons/fa";



const Component = (props) => {
    const [getData, setGetData] = useState([]);

    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    const token = localStorage.getItem("token");
    let fetchParams = {
        headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
    };


    const GetResponseData = async () => {
        try {
            // e.preventDefault();
            props?.setLoading(true)
            const response = await API.get(`user-service/settings/cards`, fetchParams)
            // Checking process
            if (response?.status === 200) {
                setGetData(response.data.data)
                props?.setLoading(false)
            }
        } catch (error) {
            props?.setLoading(false)
            swal({
                title: 'Failed',
                text: `${error.response.data.message}`,
                icon: 'error',
                timer: 3000,
                buttons: false
            });
        }
    }


    useEffect(() => {
        GetResponseData()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.page, props.limit, props.ascending, props.keyword])

    const [formData, setFormData] = useState({
        page_name: "",
        key: "",
        value: "",
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            props?.setLoading(true);

            if (!formData.page_name || !formData.key || !formData.value) {
                await swal({
                    title: "Gagal",
                    text: "Semua field harus diisi!",
                    icon: "error",
                    timer: 3000,
                    buttons: false
                });
                return;
            }

            // Kirim data ke backend
            const response = await API.post("user-service/settings/card", formData, fetchParams);

            if (response?.status === 201) {
                await swal({
                    title: "Berhasil",
                    text: "Data berhasil disimpan!",
                    icon: "success",
                    timer: 3000,
                    buttons: false
                });

                // setGetData([...getData, response.data.data]);

                setFormData({ page_name: "", key: "", value: "" });

                GetResponseData()
            }

        } catch (error) {
            await swal({
                title: "Gagal",
                text: error.response?.data?.message || "Gagal menyimpan data",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            props?.setLoading(false);
        }
    };

    const handleDelete = async (id) => {
        try {
            // Konfirmasi sebelum menghapus
            const confirmDelete = await swal({
                title: "Apakah Anda yakin?",
                text: "Data yang sudah dihapus tidak bisa dikembalikan!",
                icon: "warning",
                buttons: ["Batal", "Hapus"],
                dangerMode: true,
            });

            if (!confirmDelete) return; // Jika user membatalkan, hentikan proses

            props.setLoading(true);
            const response = await API.delete(`user-service/settings/card/${id}`, fetchParams);

            if (response.status === 200) {
                swal({
                    title: "Berhasil!",
                    text: "Data berhasil dihapus.",
                    icon: "success",
                    timer: 3000,
                    buttons: false
                });

                // Filter data yang tersisa tanpa harus memanggil API lagi
                setGetData(getData.filter(item => item.id !== id));
            }
        } catch (error) {
            swal({
                title: "Gagal!",
                text: error.response?.data?.message || "Terjadi kesalahan saat menghapus data.",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            props.setLoading(false);
        }
    };


    return (
        <>
            <Row>
                <Col xl="6" sm="6">
                    <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3">
                            <Form.Label>Page Name</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Masukkan Page Name"
                                    name="page_name"
                                    value={formData.page_name}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Key</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="text"
                                    placeholder="Masukkan Key"
                                    name="key"
                                    value={formData.key}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Label>Value</Form.Label>
                            <InputGroup>
                                <Form.Control
                                    type="number"
                                    placeholder="Masukkan Value"
                                    name="value"
                                    value={formData.value}
                                    onChange={handleChange}
                                />
                            </InputGroup>
                        </Form.Group>
                        <Button type="submit" variant="primary" className="float-end" disabled={props.loading}>
                            {props.loading ? "Menyimpan..." : "Simpan"}
                        </Button>
                    </Form>
                </Col>

                <Col xl="6" sm="6">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Name Page</th>
                                <th>Key</th>
                                <th>Setup</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getData.length > 0 ? (
                                getData.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.page_name}</td>
                                        <td>
                                            <Button variant="primary" className="btn-sm" onClick={handleShow}>
                                                <FaEye />
                                            </Button>
                                            <ModalDataKey id={item.id} pageName={item.page_name} page={props?.page} limit={props.limit} ascending={props.ascending} keyword={props.keyword} loading={props.loading} setLoading={props?.setLoading} show={show} refreshData={GetResponseData} handleClose={handleClose} />

                                        </td>
                                        <td>
                                            <td>
                                                <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                                    Hapus
                                                </Button>
                                            </td>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="5" className="text-center">Belum ada data</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </>
    );
};

export default Component;
