/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaBold, FaItalic, FaStrikethrough, FaMicrophone, FaTelegram, FaTelegramPlane } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import "bulma/css/bulma.css";
import "../Styled.css"
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import React from 'react';
import logo_kemenkes from "../../../assets/signature/logo_kemeskes.png"
import axios from "axios";

export default function ModalRoleAdd(props) {
  // untuk code uuid ini :  100465661 merupakan organisasi ID GRATIA JAYA
  // untuk code uuid ini : 
  const token = localStorage.getItem("token");


  // Untuk Close Proops Data;
  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  // const filteredRoles = rolesData.filter(role => role.name === 'Doctor');
  // const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');
  // const filteredRolesANurse = rolesData.filter(role => role.name === 'Nurse');
  const levelUser = localStorage.getItem('level');

  const [isAppointmentExists, setIsAppointmentExists] = useState(false);
  const [buttonText, setButtonText] = useState("Loading");

  const [loading, setLoading] = useState(false);
  const [getDataPatients, setGetDataPatients] = useState([]);
  const [getDataDoctorByID, setGetDataDoctorByID] = useState([]);
  const [getDataPhysicalCheck, setGetDataPhysicalCheck] = useState([]);
  const [getDataAppointmentSsp, setGetDataAppointmentSsp] = useState([]);
  const [isUpdating, setIsUpdating] = useState(false);
  const [getDataSSP, setGetDataSSP] = useState([]);
  const [status, setStatus] = useState(false);
  const [forceUpdate, setForceUpdate] = useState(0);
  const [dataMedicalRecord, setGetDataMedicalRecord] = useState("");
  const [dataRoomSatuSehat, setGetDataRoomSatuSehat] = useState("");


  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    id_appointment: "",
    id_patient: "",
    id_doctor: "",
    patient_name: "",
    token_satu_sehat: "",
    date_appointment: "",
    time_appointment: "",
    arrangement_data: "",
    appointment_ssp: ""
  });

  const GetDataResponseRoomFromSatuSehat = async () => {
    try {
      setLoading(true);
      const token = localStorage.getItem('token');
      const response = await axios.get('https://api-ts.signatureanugerah.co.id/api/satu-sehat/location', {
        params: {
          'id_room': form?.arrangement_data.id_room
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`,
        }
      });

      if (response?.data.entry && response.data.entry.length !== 0) {
        if (response?.status === 200 || response?.status === 201) {
          setLoading(false);
          setGetDataRoomSatuSehat(response.data.entry[0].resource);
        }
      } else {
        swal({
          title: 'Info',
          text: 'No data found for this ID Room',
          icon: 'info',
          timer: 3000,
          buttons: false
        });
      }
    } catch (error) {
      setLoading(false);
      swal({
        title: 'Failed',
        text: error.response?.data?.message || 'Something went wrong',
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  const GetResponseDataPatients = async () => {
    try {
      setLoading(true)
      const response = await API.get(`/transaction-service/patients/${form?.id_patient}`, fetchParams)
      // Checking process
      if (response?.status === 200 || response?.status === 201) {
        setGetDataPatients(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataMedicalRecord = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/medical-invoices/${form?.id_appointment}`, fetchParams)
      // Checking process
      if (response?.status === 200 || response?.status === 201) {
        setGetDataMedicalRecord(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  const GetResponseDataDoctorByID = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/privileges/users/${form?.id_doctor}`, fetchParams)
      // Checking process
      if (response?.status === 200 || response?.status === 201) {
        setGetDataDoctorByID(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataPhisicalChecks = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/emr-service/phy-checks/patient/${form?.id_appointment}`, fetchParams)
      // Checking process
      if (response?.status === 200 || response?.status === 201) {
        setGetDataPhysicalCheck(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataAppointmentSSP = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/appointment-ssp`, fetchParams)
      if (response?.status === 200 || response?.status === 201) {
        setGetDataSSP(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.data.length !== 0) {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  const GetResponseDataAppointmentSSPByIdAppointment = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/user-service/appointment-ssp/${form?.id_appointment}`, fetchParams)
      if (response?.status === 200 || response?.status === 201) {
        setGetDataAppointmentSsp(response.data.data);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.data.length !== 0) {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      } else if (error.response.data.data.length === 0) {
        setButtonText("Kirim Encounter");
      }
    }
  }

  useEffect(() => {
    setForm({
      ...form,
      id_appointment: props?.idAppointmentModalSatuSehat === null ? "" : props?.idAppointmentModalSatuSehat,
      id_patient: props?.idPatientModalSatuSehat === null ? "" : props?.idPatientModalSatuSehat,
      id_doctor: props?.idDoctorModalSatuSehat === null ? "" : props?.idDoctorModalSatuSehat,
      patient_name: props?.patientNameModalSatuSehat === null ? "" : props?.patientNameModalSatuSehat,
      token_satu_sehat: props?.getDataSatuSehat === null ? "" : props?.getDataSatuSehat,
      date_appointment: props?.dateSatuSehat === null ? "" : props?.dateSatuSehat,
      time_appointment: props?.timeSatuSehat === null ? "" : props?.timeSatuSehat,
      arrangement_data: props?.arrangementSatuSehat === null ? "" : props?.arrangementSatuSehat,
      appointment_ssp: props?.appointmentSSP === null ? "" : props?.appointmentSSP
    });
  }, [props])

  useEffect(() => {
    if (
      form?.id_patient !== "" &&
      form?.id_doctor !== "" &&
      form?.id_appointment !== "" &&
      form?.date_appointment !== "" &&
      form?.time_appointment !== "" &&
      form?.arrangement_data !== ""
    ) {
      setLoading(true);

      Promise.allSettled([
        GetResponseDataPatients(),
        GetResponseDataDoctorByID(),
        GetResponseDataPhisicalChecks(),
        GetResponseDataMedicalRecord(),
        GetDataResponseRoomFromSatuSehat(),
        GetResponseDataAppointmentSSPByIdAppointment(),
        GetResponseDataAppointmentSSP()
      ]).finally(() => {
        setLoading(false);
      });
    }
  }, [form?.id_patient, form?.id_doctor, form?.id_appointment]);

  // 
  const [updateStatus, setUpdateStatus] = useState({
    sistole: false,
    distole: false,
    observation: false,
    diagnosis: false,
    procedure: false,
  });

  const checkAllUpdates = () => {
    const { sistole, distole, observation, diagnosis, procedure } = updateStatus;

    // Jika semua sudah true, jalankan aksi
    if (sistole && distole && observation && diagnosis && procedure) {
      swal({
        title: 'Success',
        text: 'Semua data SSP berhasil diperbarui!',
        icon: 'success',
        timer: 3000,
        buttons: false
      });

      // Reset status agar bisa digunakan kembali
      setUpdateStatus({
        sistole: false,
        distole: false,
        observation: false,
        diagnosis: false,
        procedure: false
      });
    }
  };

  useEffect(() => {
    const { sistole, distole, observation, diagnosis, procedure } = updateStatus;
    if (sistole && distole && observation && diagnosis && procedure) {
      swal({
        title: 'Success',
        text: 'Semua data SSP berhasil diperbarui!',
        icon: 'success',
        timer: 3000,
        buttons: false
      });

      (async () => {
        await props.GetResponseData();
        await GetResponseDataAppointmentSSP();

        setForceUpdate(prev => prev + 1);
        props.onHide();
      })();
    }
  }, [updateStatus, forceUpdate]);



  const UpdateDataSistoleSSPLocal = async (patient_id) => {
    try {
      setLoading(true);
      const response = await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_sistole_ssp: 1,
        id_patient_ssp: patient_id,
        id_appointment_ssp: getDataAppointmentSsp?.id_appointment_ssp
      }, fetchParams);

      if (response?.status === 200) {
        setUpdateStatus(prev => ({ ...prev, sistole: true }));
        checkAllUpdates();
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'Terjadi kesalahan'}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };
  const UpdateDataDistoleSSPLocal = async (patient_id, id) => {
    try {
      await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_distole_ssp: 1,
        id_patient_ssp: patient_id,
        id_appointment_ssp: id,
      }, fetchParams);

      setUpdateStatus(prev => ({ ...prev, distole: true }));
      checkAllUpdates();

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'Terjadi kesalahan'}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };

  const UpdateDataObservationSSPLocal = async (patient_id) => {
    try {
      setLoading(true);

      await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_observation_ssp: 1,
        id_patient_ssp: patient_id,
        id_appointment_ssp: getDataAppointmentSsp?.id_appointment_ssp,
      }, fetchParams);

      setUpdateStatus(prev => ({ ...prev, observation: true }));
      checkAllUpdates();

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'Terjadi kesalahan'}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };

  const UpdateDataDiagnosisSSPLocal = async (patient_id, id) => {
    try {
      setLoading(true);

      await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_diagnosis_ssp: 1,
        id_patient_ssp: patient_id,
        id_appointment_ssp: id
      }, fetchParams);

      setUpdateStatus(prev => ({ ...prev, diagnosis: true }));
      checkAllUpdates();

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'Terjadi kesalahan'}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };


  const UpdateDataProcedureSSPLocal = async (patient_id, id) => {
    try {
      setLoading(true);
      await API.put(`/user-service/appointment-ssp/${form?.id_appointment}`, {
        status_procedure_ssp: 1,
        id_patient_ssp: patient_id,
        id_appointment_ssp: id
      }, fetchParams);

      setUpdateStatus(prev => ({ ...prev, procedure: true }));
      checkAllUpdates();

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message || 'Terjadi kesalahan'}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };
  const PostDataPatientEcounter = async (patient_id, encounter_id) => {
    try {
      if (!patient_id) {
        return swal({ title: "Error", text: "Patient ID is required", icon: "error", timer: 3000 });
      }
      if (!form?.id_appointment) {
        return swal({ title: "Error", text: "Appointment ID is required", icon: "error", timer: 3000 });
      }
      if (!form?.date_appointment) {
        return swal({ title: "Error", text: "Date appointment is required", icon: "error", timer: 3000 });
      }
      if (!form?.time_appointment) {
        return swal({ title: "Error", text: "Time appointment is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.first_name) {
        return swal({ title: "Error", text: "Patient first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.last_name) {
        return swal({ title: "Error", text: "Patient last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.firstname) {
        return swal({ title: "Error", text: "Doctor first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.lastname) {
        return swal({ title: "Error", text: "Doctor last name is required", icon: "error", timer: 3000 });
      }
      if (!dataRoomSatuSehat?.id) {
        return swal({ title: "Error", text: "Room ID is required", icon: "error", timer: 3000 });
      }
      if (!dataRoomSatuSehat?.name) {
        return swal({ title: "Error", text: "Room name is required", icon: "error", timer: 3000 });
      }

      // Insert data for login process
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/encounter',
        {
          "patient_id": patient_id,
          "id_appointment": form?.id_appointment,
          "date_appointment": form?.date_appointment,
          "time_appointment": form?.time_appointment,
          "patient_firstname": getDataPatients?.first_name,
          "patient_lastname": getDataPatients?.last_name,
          "doctor_firstname": getDataDoctorByID?.user_detail?.firstname,
          "doctor_lastname": getDataDoctorByID?.user_detail?.lastname,
          "room_id": dataRoomSatuSehat.id,
          "room_name": dataRoomSatuSehat.name
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );


      if (response?.data.status === "arrived") {
        props.GetResponseData()
        const responseSSP = await API.post("/user-service/appointment-ssp/store", {
          id_appointment: form?.id_appointment,
          id_appointment_ssp: encounter_id,
          id_patient: form?.id_patient,
          id_patient_ssp: patient_id,
          status_sistole_ssp: 0,
          status_distole_ssp: 0,
          status_observation_ssp: 0,
          status_diagnosis_ssp: 0,
          status_procedure_ssp: 0,
        }, fetchParams);

        if (responseSSP.status === 200) {
          props.GetResponseData()
          GetResponseDataAppointmentSSP()
        }
      }
    } catch (error) {
      setLoading(false)
      if (error.response.data.error.issue[0].code === "duplicate") {
        if (getDataAppointmentSsp?.status_sistole_ssp === 0) {
          if (error.response.data.error.issue[0].code !== 'duplicate') {
            PostDataPatientSistole(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
          if (error.response.data.error.issue[0].code === 'duplicate') {
            UpdateDataSistoleSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
        }
        if (getDataAppointmentSsp?.status_distole_ssp === 0) {
          if (error.response.data.error.issue[0].code !== 'duplicate') {
            PostDataPatientDistole(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
          if (error.response.data.error.issue[0].code === 'duplicate') {
            UpdateDataDistoleSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
        }
        if (getDataAppointmentSsp?.status_observation_ssp === 0) {
          if (error.response.data.error.issue[0].code !== 'duplicate') {
            PostDataPatientSuhu(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }

          if (error.response.data.error.issue[0].code === 'duplicate') {
            UpdateDataObservationSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
        }
        if (getDataAppointmentSsp?.status_diagnosis_ssp === 0) {
          if (error.response.data.error.issue[0].code !== 'duplicate') {
            PostDataDiagnosis(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }

          if (error.response.data.error.issue[0].code === 'duplicate') {
            UpdateDataDiagnosisSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp)
          }
        }
        if (getDataAppointmentSsp?.status_procedure_ssp === 0) {
          if (error.response.data.error.issue[0].code !== 'duplicate') {
            PostDataTindakan(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }

          if (error.response.data.error.issue[0].code === 'duplicate') {
            UpdateDataProcedureSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp);
          }
        }
      } else {
        setLoading(false)
        swal({
          title: 'Failed',
          text: "Error",
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
    }
  }

  const PostDataPatientSistole = async (patient_id, id) => {
    try {
      if (!patient_id) {
        return swal({ title: "Error", text: "Patient ID is required", icon: "error", timer: 3000 });
      }
      if (!id) {
        return swal({ title: "Error", text: "Encounter ID is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.first_name) {
        return swal({ title: "Error", text: "Patient first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.last_name) {
        return swal({ title: "Error", text: "Patient last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.firstname) {
        return swal({ title: "Error", text: "Doctor first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.lastname) {
        return swal({ title: "Error", text: "Doctor last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPhysicalCheck?.blood_pressure) {
        return swal({ title: "Error", text: "Blood pressure is required", icon: "error", timer: 3000 });
      }
      if (!getDataPhysicalCheck?.created_at) {
        return swal({ title: "Error", text: "Created at timestamp is required", icon: "error", timer: 3000 });
      }
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/sistole',
        {
          "patient_id": patient_id,
          "id_encounter": id,
          "patient_firstname": `${getDataPatients?.first_name}`,
          "patient_lastname": `${getDataPatients?.last_name}`,
          "doctor_firstname": `${getDataDoctorByID?.user_detail?.firstname}`,
          "doctor_lastname": `${getDataDoctorByID?.user_detail?.lastname}`,
          "blood_pressure": parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[0]),
          "created_at": `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );

      if (response.status === 200 || response.status === 201) {

        props.GetResponseData()
        if (getDataAppointmentSsp.length === 0) {
          swal({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            timer: 5000,
            buttons: false
          })
        }
      } else {
        swal({
          title: 'Failed',
          text: "Sistole wajib diisi",
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }

    } catch (error) {
      const requestData = JSON.parse(error.config?.data || "{}");
      setLoading(false)
      if (error.response.data.error.issue[0].code === "duplicate") {
        UpdateDataSistoleSSPLocal(requestData.patient_id)
      } else {
        swal({
          title: 'Failed',
          text: error?.response?.data.error.message,
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
    }
  }

  const PostDataPatientDistole = async (patient_id, id) => {
    try {
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/distole',
        {
          "patient_id": patient_id,
          "id_appointment": id,
          "patient_firstname": `${getDataPatients?.first_name}`,
          "patient_lastname": `${getDataPatients?.last_name}`,
          "doctor_firstname": `${getDataDoctorByID?.user_detail?.firstname}`,
          "doctor_lastname": `${getDataDoctorByID?.user_detail?.lastname}`,
          "blood_pressure": parseInt(getDataPhysicalCheck?.blood_pressure.split("/")[1]),
          "created_at": `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );
      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()
        if (getDataAppointmentSsp.length === 0) {
          swal({
            title: 'Success',
            text: response.data.message,
            icon: 'success',
            timer: 5000,
            buttons: false
          })
        }
      } else {
        swal({
          title: 'Failed',
          text: "Distole wajib diisi",
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
    } catch (error) {
      const requestData = JSON.parse(error.config?.data || "{}");
      setLoading(false)
      if (error.response.data.error.issue[0].code === "duplicate") {
        UpdateDataDistoleSSPLocal(requestData.patient_id, getDataAppointmentSsp?.id_appointment_ssp)
      } else {
        swal({
          title: 'Failed',
          text: error?.response?.message,
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
    }
  }

  const PostDataPatientSuhu = async (patient_id, id) => {
    try {
      if (!patient_id) {
        return swal({ title: "Error", text: "Patient ID is required", icon: "error", timer: 3000 });
      }
      if (!id) {
        return swal({ title: "Error", text: "Appointment ID is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.first_name) {
        return swal({ title: "Error", text: "Patient first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.last_name) {
        return swal({ title: "Error", text: "Patient last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.firstname) {
        return swal({ title: "Error", text: "Doctor first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.lastname) {
        return swal({ title: "Error", text: "Doctor last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPhysicalCheck?.blood_pressure) {
        return swal({ title: "Error", text: "Blood pressure is required", icon: "error", timer: 3000 });
      }
      if (!getDataPhysicalCheck?.created_at) {
        return swal({ title: "Error", text: "Created at timestamp is required", icon: "error", timer: 3000 });
      }
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/observation',
        {
          "patient_id": patient_id,
          "id_appointment": id,
          "patient_firstname": `${getDataPatients?.first_name}`,
          "patient_lastname": `${getDataPatients?.last_name}`,
          "doctor_firstname": `${getDataDoctorByID?.user_detail?.firstname}`,
          "doctor_lastname": `${getDataDoctorByID?.user_detail?.lastname}`,
          "body_temperature": parseInt(getDataPhysicalCheck?.body_temperature),
          "created_at": `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );

      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()
        if (form?.status_observation_ssp === false || form?.status_observation_ssp === 0) {
          // UpdateDataObservationSSPLocal()
        }
        setLoading(false)
      }
    } catch (error) {
      const requestData = JSON.parse(error.config?.data || "{}");
      setLoading(false)
      if (error.response.data.error.issue[0].code === "duplicate") {
        UpdateDataObservationSSPLocal(requestData.patient_id, getDataAppointmentSsp?.id_appointment_ssp)
      } else {
        swal({
          title: 'Failed',
          text: error?.response?.message,
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
    }
  }

  const PostDataDiagnosis = async (patient_id, id) => {
    try {
      setLoading(true)
      if (!patient_id) {
        return swal({ title: "Error", text: "Patient ID is required", icon: "error", timer: 3000 });
      }
      if (!id) {
        return swal({ title: "Error", text: "Appointment ID is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.first_name) {
        return swal({ title: "Error", text: "Patient first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPatients?.last_name) {
        return swal({ title: "Error", text: "Patient last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.firstname) {
        return swal({ title: "Error", text: "Doctor first name is required", icon: "error", timer: 3000 });
      }
      if (!getDataDoctorByID?.user_detail?.lastname) {
        return swal({ title: "Error", text: "Doctor last name is required", icon: "error", timer: 3000 });
      }
      if (!getDataPhysicalCheck?.created_at) {
        return swal({ title: "Error", text: "Created at timestamp is required", icon: "error", timer: 3000 });
      }
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/diagnosis',
        {
          "patient_id": patient_id,
          "id_appointment": id,
          "patient_firstname": `${getDataPatients?.first_name}`,
          "patient_lastname": `${getDataPatients?.last_name}`,
          "doctor_firstname": `${getDataDoctorByID?.user_detail?.firstname}`,
          "doctor_lastname": `${getDataDoctorByID?.user_detail?.lastname}`,
          "code_diagnose": dataMedicalRecord?.medical_treatment_invoices[0]?.medical_record?.code_diagnose,
          "diagnose": dataMedicalRecord?.medical_treatment_invoices[0]?.medical_record?.diagnose,
          "created_at": `${getDataPhysicalCheck?.created_at.split(" ")[0]}T${getDataPhysicalCheck?.created_at.split(" ")[1]}+00:00`
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );

      // Checking process
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()
        if (form?.status_diagnosis_ssp === false || form?.status_diagnosis_ssp === 0) {
          UpdateDataDiagnosisSSPLocal(patient_id, id)
        }
        setLoading(false)
      }

    } catch (error) {

      setLoading(false)
      if (levelUser === 'developer') {
        swal({
          title: 'Not Found',
          text: error.response.data.error.issue[0].details.text,
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }
      if (levelUser === 'user') {
        const errorMessage = error.response.data.error.issue[0].details.text;
        const match = errorMessage.match(/'([^']+)'/);
        if (match) {
          swal({
            title: 'Not Found',
            text: `Code ${match[0]} ICD-10 `,
            icon: 'error',
            timer: 3000,
            buttons: false
          })
        }
      }

      if (error.response.data.error.issue[0].code === "duplicate") {
        UpdateDataDiagnosisSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp)
      } else {
        swal({
          title: 'Failed',
          text: error?.response?.message,
          icon: 'error',
          timer: 3000,
          buttons: false
        })
      }

    }
  }

  const PostDataTindakan = async (patient_id, id) => {
    try {
      const response = await axios.post('https://api-ts.signatureanugerah.co.id/api/satu-sehat/procedure',
        {
          "patient_id": patient_id,
          "id_appointment": id,
          "patient_firstname": `${getDataPatients?.first_name}`,
          "patient_lastname": `${getDataPatients?.last_name}`,
          "code": dataMedicalRecord?.medical_treatment_invoices
            ?.flatMap(invoice => invoice?.medical_record?.treatment_plan_detail?.treatment_data) || []
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Authorization": `${token}`
          }
        }
      );

      // Handle the response
      if (response.status === 200 || response.status === 201) {
        props.GetResponseData()
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false,
        });

        UpdateDataProcedureSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp)
        setLoading(false);
      }
    } catch (error) {
      if (error.response.data.issue[0].code !== "duplicate") {
        setLoading(false)
        if (error.response.data.error.issue[0].code === "duplicate") {
          UpdateDataProcedureSSPLocal(patient_id, getDataAppointmentSsp?.id_appointment_ssp)
        } else {
          swal({
            title: 'Failed',
            text: error?.response?.message,
            icon: 'error',
            timer: 3000,
            buttons: false
          })
        }
      }
    }
  };



  const PostDataPatient = async (patient_id) => {
    try {
      if (
        getDataPatients?.nic_number === null ||
        getDataPatients?.nic_number === "null" ||
        getDataPatients.nic_number.trim() === ""
      ) {
        swal({
          title: 'Failed',
          text: `Harap isi NIK pasien!`,
          icon: 'warning',
          timer: 3000,
          buttons: false,
        });
        return;
      }

      if (
        getDataPatients?.province === null ||
        getDataPatients.province.trim() === "" ||
        getDataPatients?.regency === null ||
        getDataPatients.regency.trim() === "" ||
        getDataPatients?.district === null ||
        getDataPatients.district.trim() === "" ||
        getDataPatients?.sub_district === null ||
        getDataPatients.sub_district.trim() === ""
      ) {
        swal({
          title: 'Failed',
          text: `Harap lengkapi data alamat (province, regency, district, sub-district)!`,
          icon: 'warning',
          timer: 3000,
          buttons: false,
        });
        return;
      }


      const token = localStorage.getItem('token');

      const response = await axios.get(`https://api-ts.signatureanugerah.co.id/api/satu-sehat/by-nik`, {
        params: {
          'id_nik': `${getDataPatients?.nic_number}`
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`,
        }
      });

      let patientId;
      if (!response?.data?.entry || response?.data?.entry.length === 0) {
        const newPatient = await axios.post(
          `https://api-ts.signatureanugerah.co.id/api/satu-sehat/patient`,
          {
            nic_number: getDataPatients?.nic_number,
            first_name: getDataPatients?.first_name,
            last_name: getDataPatients?.last_name,
            phone_number: getDataPatients?.phone_number,
            gender: getDataPatients?.gender,
            date_birth: getDataPatients?.date_birth,
            residence_address: getDataPatients?.residence_address,
            province: getDataPatients?.province,
            regency: getDataPatients?.regency,
            district: getDataPatients?.district,
            sub_district: getDataPatients?.sub_district,
            status: getDataPatients?.status,
          },
          {
            headers: {
              "Content-Type": "application/json",
              "Authorization": `${token}`,
            }
          }
        );

        patientId = newPatient?.id;
      } else {
        patientId = response.data.entry[0].resource.id;
      }


      const responseEncounter = await axios.get(`https://api-ts.signatureanugerah.co.id/api/satu-sehat/encounter`, {
        params: {
          'id_patient': patientId
        },
        headers: {
          "Content-Type": "application/json",
          "Authorization": `${token}`,
        }
      });

      if (form?.appointment_ssp === "" && response?.data?.entry.length === 0) {
        const responseSSP = await API.post("/user-service/appointment-ssp/store", {
          id_appointment: form?.id_appointment,
          id_appointment_ssp: responseEncounter?.data?.entry[0].resource?.id,
          id_patient: form?.id_patient,
          id_patient_ssp: patient_id,
          status_sistole_ssp: 0,
          status_distole_ssp: 0,
          status_observation_ssp: 0,
          status_diagnosis_ssp: 0,
          status_procedure_ssp: 0,
        }, fetchParams);

        if (responseSSP.status === 200) {
          swal({
            title: 'Success',
            text: 'Appointment berhasil disimpan!',
            icon: 'success',
            timer: 3000,
            buttons: false
          });
          props.GetResponseData()
          GetResponseDataAppointmentSSP()
        }
      }
      PostDataPatientEcounter(response.data.entry[0].resource.id, responseEncounter?.data?.entry[0].resource?.id);
      // Insert data for login process
      if (form?.appointment_ssp === "" && response?.data?.entry.length !== 0) {
        if (getDataAppointmentSsp.length === 0) {
          const responseSSP = await API.post("/user-service/appointment-ssp/store", {
            id_appointment: form?.id_appointment,
            id_appointment_ssp: responseEncounter?.data?.entry[0].resource?.id,
            id_patient: form?.id_patient,
            id_patient_ssp: response.data.entry[0].resource.id,
            status_sistole_ssp: 0,
            status_distole_ssp: 0,
            status_observation_ssp: 0,
            status_diagnosis_ssp: 0,
            status_procedure_ssp: 0,
          }, fetchParams);
          if (responseSSP.status === 200) {
            swal({
              title: 'Success',
              text: 'Appointment berhasil disimpan!',
              icon: 'success',
              timer: 3000,
              buttons: false
            });

            props?.GetResponseData()
            GetResponseDataAppointmentSSP()
            GetResponseDataAppointmentSSPByIdAppointment()
          }
        } else {
          PostDataPatientEcounter(response.data.entry[0].resource.id, responseEncounter?.data?.entry[0].resource?.id);
        }
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  const handleSubmit = async () => {
    try {
      await PostDataPatient()
    } catch (error) {
      swal({
        title: 'Failed',
        text: 'Terjadi kesalahan saat mengirim data.',
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };

  useEffect(() => {
    if (getDataAppointmentSsp) {
      const appointmentExists = !!getDataAppointmentSsp.id_appointment_ssp;
      const hasAnyStatusZero =
        getDataAppointmentSsp.status_sistole_ssp === 0 ||
        getDataAppointmentSsp.status_distole_ssp === 0 ||
        getDataAppointmentSsp.status_diagnosis_ssp === 0 ||
        getDataAppointmentSsp.status_procedure_ssp === 0 ||
        getDataAppointmentSsp.status_observation_ssp === 0;


      setIsAppointmentExists(appointmentExists);
      if (!getDataAppointmentSsp) {
        if (!dataMedicalRecord) {
          setButtonText("Loading");
        } else {
          setButtonText("Kirim Encounter");
        }
      } else if (appointmentExists && hasAnyStatusZero) {
        setButtonText("Kirim Data Physical Check");
      } else {
        setButtonText("Loading");
      }
    }
  }, [getDataAppointmentSsp]);


  return (
    <Modal {...props} aria-labelledby="contained-modal-title-center" centered style={{ fontFamily: "sans-serif", border: "none" }}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>

        <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
          Satu Sehat || {form?.patient_name}
        </div>

        <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
          <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
        <Form className="mt-" >
          <Col xl='12' sm='12'>

            <div style={{ display: "flex", justifyContent: "center", padding: "0px 0px" }}>
              <img
                src={logo_kemenkes}
                style={{ minWidth: "320px", maxWidth: "320px", minHeight: "250px", maxHeight: "250px" }}
              />
            </div>

            <div className="" style={{ display: "flex", justifyContent: "center" }}>
              {/* <div>
                {
                  getDataAppointmentSsp?.id_appointment_ssp !== null &&
                    (
                      getDataAppointmentSsp?.status_sistole_ssp === 0 ||
                      getDataAppointmentSsp?.status_distole_ssp === 0 ||
                      getDataAppointmentSsp?.status_diagnosis_ssp === 0 ||
                      getDataAppointmentSsp?.status_procedure_ssp === 0 ||
                      getDataAppointmentSsp?.status_distole_ssp === 0 ||
                      getDataAppointmentSsp?.status_observation_ssp === 0
                    ) ?
                    <>
                      <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px" }}>
                        <FaTelegramPlane /> Kirim Data Physical Check
                      </Button>
                    </>
                    :
                    <>
                      <Button className="mt-2" color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px" }}>
                        <FaTelegramPlane /> Loading
                      </Button>
                    </>
                }

                {
                  getDataAppointmentSsp?.id_appointment_ssp === null &&
                    getDataAppointmentSsp?.status_sistole_ssp === 0 &&
                    getDataAppointmentSsp?.status_distole_ssp === 0 &&
                    getDataAppointmentSsp?.status_diagnosis_ssp === 0 &&
                    getDataAppointmentSsp?.status_procedure_ssp === 0 &&
                    getDataAppointmentSsp?.status_observation_ssp === 0 ?
                    <>
                      <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px" }}>
                        <FaTelegramPlane /> Kirim Encounter
                      </Button>
                    </>
                    :
                    <>
                      <Button className="mt-2" onClick={handleSubmit} color='primary' block style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px" }}>
                        <FaTelegramPlane /> Loading
                      </Button>
                    </>
                }
              </div> */}
              <Button
                className="mt-2"
                onClick={handleSubmit}
                color="primary"
                block
                style={{ padding: "8px 15px", fontSize: "12px", borderRadius: "5px" }}
              >
                <FaTelegramPlane /> {buttonText}
              </Button>
            </div>
          </Col>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
