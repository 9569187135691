/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Col, Button, Modal } from "react-bootstrap";
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import { FaAddressBook, FaCheckCircle, FaClock, FaDeviantart, FaEdit, FaEye, FaMedkit, FaPlus, FaRegArrowAltCircleLeft, FaRegStickyNote, FaSync, FaTrash } from 'react-icons/fa'
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";
import ReactPaginate from "react-paginate";
import ModalDataPatientRepresentative from "./ModalAddPatientRepresentative/ModalAdd"
import ModalPatientDetail from "../AdminPasien/ModalPatientDetail/ModalDetail";
import ModalAdminDetail from "./ModalAddPatientRepresentative/ModalDetail";
import { HiUser } from "react-icons/hi2";
import MyModal from "./ModalAddPatientRepresentative/ModalUpdateRelation";
// import ModalAdminDetail from "./ModalAddPatientRepresentative/ModalDetail";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [pagination, setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [search] = useState("")
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  // modal role add 
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);
  const [getMainPasien, setGetMainPasien] = useState("");
  const [modalUpdateDataPatient, setModalUpdateDataPatient] = useState(false);
  const [modalDetaiDataPatient, setModalDetailPatient] = useState(false);
  const [idDetailPatient, setIdDetailPatient] = useState();
  const [getDetailDataPatient, setGetDetailDataPatient] = useState([]);


  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };


  const GetResponseData = async () => {
    try {
      setLoading(true)
      const response = await API.get(`transaction-service/patients/representative/${id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      if (response?.status === 200) {
        setGetData(response.data.data);
        setGetMainPasien(response.data.meta.patient_main_name);
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false);
      } else {
        setLoading(false);
        // swal({
        //   title: 'No data found',
        //   text: 'Data not available for this request.',
        //   icon: 'warning',
        //   timer: 3000,
        //   buttons: false,
        // });
      }
    } catch (error) {
      setLoading(false)

      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  const GetResponseDetailPatient = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patients/${idDetailPatient}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDetailDataPatient(response?.data?.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.delete(`/transaction-service/patients/representative/${id}`, fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  useEffect(() => {
    if (idDetailPatient) {
      GetResponseDetailPatient();
    } else {
      GetResponseData()
    }
  }, [idDetailPatient, page])

  const modalMedisRoleAdd = () => {
    setModalUpdateDataPatient(true)
  }

  const buttonRefresh = () => {
    window.location.reload();
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };


  let modalPhysicalCheck = async (id) => {
    navigate('/phy-checks/' + id)
  };

  const odontogramPatient = (id, first_name, last_name) => {
    navigate('/odontograms/' + id + "/" + first_name + "/" + last_name);
  }

  const soapPatient = (id, first_name, last_name) => {
    navigate('/soap-patients/' + id + "/" + first_name + "/" + last_name);
  };

  let modalMedicalRecord = async (id, code) => {
    navigate('/medical-record/' + id + "/" + code)
  };

  let modalSatuSehat = async (id) => {
    navigate('/satu-sehat/' + id)
  };

  const updateUser = (id) => {
    navigate('/representative/profile/' + id)
  }


  const navigateBack = () => {
    navigate('/patients/');
  }

  const navigateHome = () => {
    navigate('/patients');
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const modalDetaiPatient = (id) => {
    setModalDetailPatient(true)
    setIdDetailPatient(id)
  }


  const [show, setShow] = useState(false);
  const [idPatientRelation, setIdPatientRelation] = useState(null);
  const [idRepresentativeFirstName, setRepresentativeFirstName] = useState(null);
  const [idRepresentativeLastName, setRepresentativeLastName] = useState(null);
  const [idRepresentativeGender, setRepresentativeGender] = useState(null);
  const [idRepresentativeDateBirth, setRepresentativeDateBirth] = useState(null);
  const [idRepresentativeResidenceAddress, setRepresentativeResidenceAddress] = useState(null);
  const [idRepresentativePhoneNumber, setRepresentativePhoneNumber] = useState(null);
  const [idRepresentativeOccupation, setRepresentativeOccupation] = useState(null);
  const [idRepresentativeNicNumber, setRepresentativeNicNumber] = useState(null);
  const [idRepresentativeMedicalRecordCode, setRepresentativeMedicalRecordCode] = useState(null)
  const [idRepresentativeNICAddress, setPatientNICAddress] = useState(null)
  const [idRelation, setRelation] = useState(null);

  const handleShow = (
    id, relation, first_name, last_name, gender, date_birth, residence_address,
    phone_number, occupation, nic_number, medical_record_code, nic_address
  ) => {
    setIdPatientRelation(id);
    setRelation(relation);
    setRepresentativeFirstName(first_name);
    setRepresentativeLastName(last_name);
    setRepresentativeGender(gender);
    setRepresentativeDateBirth(date_birth);
    setRepresentativeResidenceAddress(residence_address);
    setRepresentativePhoneNumber(phone_number);
    setRepresentativeOccupation(occupation);
    setRepresentativeNicNumber(nic_number);
    setRepresentativeMedicalRecordCode(medical_record_code);
    setPatientNICAddress(nic_address);
    setShow(true);
  };


  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalUpdateDataPatient && <ModalDataPatientRepresentative GetResponseData={GetResponseData} show={modalUpdateDataPatient} onHide={() => setModalUpdateDataPatient(false)} />}
      {modalDetaiDataPatient && <ModalAdminDetail GetResponseData={GetResponseDetailPatient} getDetailDataPatient={getDetailDataPatient} show={modalDetaiDataPatient} onHide={() => setModalDetailPatient(false)} />}
      {show && <MyModal
        show={show}
        setShow={setShow}
        GetResponseData={GetResponseData}
        id={idPatientRelation}
        first_name={idRepresentativeFirstName}
        last_name={idRepresentativeLastName}
        gender={idRepresentativeGender}
        date_birth={idRepresentativeDateBirth}
        residence_address={idRepresentativeResidenceAddress}
        phone_number={idRepresentativePhoneNumber}
        occupation={idRepresentativeOccupation}
        nic_number={idRepresentativeNicNumber}
        medical_record_code={idRepresentativeMedicalRecordCode}
        nic_address={idRepresentativeNICAddress}
      />}

      {loading && <LoaderHome />}
      {isTabletOrMobile ?
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{ fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", padding: "7px", color: "#001F8B", backgroundColor: "#E9EFFF" }}>
            <FaAddressBook style={{ marginRight: "5px" }} />List Pasien 
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent: "end", paddingRight: "5px" }}>
            <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 12px", borderRadius: "3px", cursor: "pointer", fontSize: "12px", cursor: "pointer" }}>
              <FaPlus />
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "0px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "100%" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "2px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </Col>
        </div>
        :

        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>

          <div style={{ flex: "50%", fontSize: "16px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#001F8B" }}>
            <div onClick={navigateHome} style={{ display: "flex", marginRight: "10px", fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius: "30px", borderBottomLeftRadius: "30px" }}>
              <div style={{ display: "flex", alignItems: "center", height: "100%", marginRight: "3px" }}>
                <FaRegArrowAltCircleLeft style={{ display: "flex", alignItems: "center", fontSize: "15px" }} />
              </div>
              <div style={{ display: "flex", alignItems: "center", height: "100%", fontSize: "12px" }}>
                Kembali
              </div>
            </div>
            List Relation Pasien
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
            <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 10px", borderRadius: "3px", cursor: "pointer", fontSize: "12px", cursor: "pointer" }}>
              <FaPlus />
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "10px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "5vh" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "3px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </div>
        </div>
      }
      <Col xl='12' sm='12'>
        <div>
          <div style={{ display: 'block', height: '100%', overflowY: 'auto', overflowX: 'auto', padding: "10px" }}>
            <table className="table is-bordered">
              <thead>
                <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>No</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Nama Lengkap</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Jenis Kelamin</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Tanggal Lahir</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Usia</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Alamat Domisili</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Hubungan</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Data Medis</th>
                  <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Setup</th>
                </tr>
              </thead>
              <tbody>
                {getData.map((user, index) => (
                  <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                    <td style={{ lineHeight: "2" }}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}</td>

                    <td style={{ lineHeight: "2" }}>
                      {user?.representative?.first_name} {user?.representative?.last_name}
                    </td>
                    <td style={{ lineHeight: "2" }}>{user?.representative?.gender === 'male' ? 'Laki-Laki' : user?.representative?.gender === 'female' ? 'Perempuan' : user.patient_gender}</td>
                    <td style={{ lineHeight: "2" }}>
                      {user?.patient?.date_birth.split('-')[2]}&nbsp;
                      {user?.patient?.date_birth.split('-')[1] === "01" ? "Januari" : "" ||
                        user?.patient?.date_birth.split('-')[1] === "02" ? "Februari" : "" ||
                          user?.patient?.date_birth.split('-')[1] === "03" ? "Maret" : "" ||
                            user?.patient?.date_birth.split('-')[1] === "04" ? "April" : "" ||
                              user?.patient?.date_birth.split('-')[1] === "05" ? "Mei" : "" ||
                                user?.patient?.date_birth.split('-')[1] === "06" ? "Juni" : "" ||
                                  user?.patient?.date_birth.split('-')[1] === "07" ? "Juli" : "" ||
                                    user?.patient?.date_birth.split('-')[1] === "08" ? "Agustus" : "" ||
                                      user?.patient?.date_birth.split('-')[1] === "09" ? "September" : "" ||
                                        user?.patient?.date_birth.split('-')[1] === "10" ? "Oktober" : "" ||
                                          user?.patient?.date_birth.split('-')[1] === "11" ? "November" : "" ||
                                            user?.patient?.date_birth.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                      {user?.patient?.date_birth.split('-')[0]}
                    </td>
                    <td style={{ lineHeight: "2" }}>{user?.age} tahun</td>
                    <td style={{ lineHeight: "2" }}>{user?.representative?.residence_address}</td>
                    <td style={{ lineHeight: "2" }}>
                      {user?.representative?.patient_relation !== null ?
                        <div onClick={() => handleShow(
                          user?.representative?.id,
                          user?.representative?.patient_relation?.id,
                          user?.representative?.first_name,
                          user?.representative?.last_name,
                          user?.representative?.gender,
                          user?.patient?.date_birth,
                          user?.representative?.residence_address,
                          user?.representative?.phone_number,
                          user?.representative?.occupation,
                          user?.representative?.nic_number,
                          user?.representative?.medical_record_code,
                          user?.patient?.nic_address,
                        )} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <div style={{
                            display: "flex",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                            borderRadius: "3px",
                            color: "white",
                            backgroundColor: user?.representative?.patient_relation?.color,
                            fontWeight: "bold",
                            width: "120px",
                            justifyContent: "center",
                            marginBottom: "5px",
                            height: "20px",
                            alignItems: "center",
                            textAlign: "center",
                          }}>
                            <HiUser style={{ marginRight: "5px" }} />
                            {user?.representative?.patient_relation?.name}
                          </div>
                        </div>
                        :
                        <div onClick={() => handleShow(
                          user?.representative?.id,
                          user?.representative?.patient_relation?.id,
                          user?.representative?.first_name,
                          user?.representative?.last_name,
                          user?.representative?.gender,
                          user?.patient?.date_birth,
                          user?.representative?.residence_address,
                          user?.representative?.phone_number,
                          user?.representative?.occupation,
                          user?.representative?.nic_number,
                          user?.representative?.medical_record_code,
                          user?.patient?.nic_address,
                        )} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                          <div style={{
                            display: "flex",
                            border: "none",
                            padding: "5px 10px",
                            cursor: "pointer",
                            borderRadius: "3px",
                            color: "white",
                            backgroundColor: user?.representative?.patient_relation?.state
                              ? user?.representative?.patient_relation?.color
                              : "gray",
                            fontWeight: "bold",
                            width: "120px",
                            justifyContent: "center",
                            marginBottom: "5px",
                            height: "20px",
                            alignItems: "center",
                            textAlign: "center",
                          }}>
                            <HiUser style={{ marginRight: "5px" }} />
                            Add relations 
                          </div>
                        </div>
                      }
                    </td>
                    <td style={{ lineHeight: "2" }}>
                      <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={() => modalPhysicalCheck(user?.patient?.id)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#3D64FF", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                          <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                            <FaMedkit style={{}} />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Physical Check
                          </div>
                        </div>
                      </div>
                      <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={() => modalMedicalRecord(user?.patient?.id, user?.patient_code)}
                          style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FF9090", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}
                        >
                          <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                            <FaMedkit style={{}} />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Medical Record
                          </div>
                        </div>
                      </div>
                      <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={() => soapPatient(user?.patient_detail_id, user?.patient_first_name, user?.patient_last_name)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FFE84D", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                          <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                            <FaRegStickyNote style={{}} />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Soap Patient
                          </div>
                        </div>
                      </div>
                      <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                        <div onClick={() => odontogramPatient(user?.patient?.id, user?.representative?.first_name, user?.representative?.last_name)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#00EB8D", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                          <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                            <FaDeviantart style={{}} />
                          </div>
                          <div style={{ display: "flex", alignItems: "center" }}>
                            Odontogram
                          </div>
                        </div>
                      </div>
                    </td>
                    <td style={{ lineHeight: "2" }}>
                      <div style={{ display: "flex", flexDirection: "column", gap: "3px", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                        <button onClick={() => { modalDetaiPatient(user?.patient?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#2c8efa", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                          <FaEye />
                        </button>
                        <button onClick={() => { updateUser(user?.patient?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                          <FaEdit />
                        </button>
                        <button onClick={() => { deleteById(user?.representative?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                          <FaTrash />
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div style={{ display: "flex", padding: "10px" }}>
            <div>
              <div style={{ fontSize: "12px" }}>
                Total Rows: {rows}
              </div>
              <div style={{ fontSize: "12px" }}>
                Page: {rows ? page : 0} of {pages}
              </div>
              <p className="has-text-centered has-text-danger">{msg}</p>
            </div>
            <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
              <nav
                style={{ fontSize: "12px" }}
                className="pagination is-centered"
                key={rows}
                role="navigation"
                aria-label="pagination"
              >
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  pageCount={Math.min(10, pages)}
                  onPageChange={changePage}
                  containerClassName={"pagination-list"}
                  pageLinkClassName={"pagination-link"}
                  previousLinkClassName={"pagination-previous"}
                  nextLinkClassName={"pagination-next"}
                  activeLinkClassName={"pagination-link is-current"}
                  disabledLinkClassName={"pagination-link is-disabled"}
                />
              </nav>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
}
