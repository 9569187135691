import {  useState } from "react";
// import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
// import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
// import { useMediaQuery } from 'react-responsive'
import { API } from "../../../config/api";
import { FaTimes, FaCamera, FaCheckCircle, FaUndo} from 'react-icons/fa'
// import ReactPaginate from "react-paginate";
// import logo_side from "../../../assets/signature/logo-side-detail.png"
// import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
// import logoCamera from "../../../assets/signature/logo-camera.png"
import { useMutation } from "react-query";
// import "bulma/css/bulma.css";
// import "../Styled.css"
import { Modal, Form, Button } from "react-bootstrap";
// import Swal from "sweetalert2";
import swal from "sweetalert";
// import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const [pages, setPages] = useState();
  // const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  // // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Foto KTP
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
    
        <div style={{display:"flex", justifyContent:"center",  padding:"30px 0px"}}>
          <>
            <img src={props?.getData?.nic_file} style={{minWidth:"320px",maxWidth:"320px", minHeight:"300px", maxHeight:"300px", }}/>
          </>
        </div>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
   
      
    </Modal>
    </div>
   
    );
}
  