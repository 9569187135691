import React, { useEffect, useState } from "react";
import { Button, Form, InputGroup, Modal, Row, Table } from "react-bootstrap";
import { FaTimes } from "react-icons/fa";
import { API } from "../../../../../config/api";
import swal from "sweetalert";

const ModalDataKey = ({ show, handleClose, pageName, setLoading }) => {
    const token = localStorage.getItem("token");
    let fetchParams = {
        headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
    };

    const [getData, setGetData] = useState([]);
    const [editMode, setEditMode] = useState(false);
    const [selectedKey, setSelectedKey] = useState(null);
    const [formData, setFormData] = useState({ key: "", value: "" });

    // Fetch Data dari API
    const GetResponseData = async () => {
        try {
            setLoading(true);
            const response = await API.get(`user-service/settings/card/key/${pageName}`, fetchParams);
            if (response?.status === 200) {
                setGetData(response.data.data);
            }
        } catch (error) {
            swal({
                title: "Failed",
                text: error.response?.data?.message || "Terjadi kesalahan",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (show) GetResponseData();
    }, [show]);

    // Handle Delete Key
    const handleDelete = async (id) => {
        const confirmDelete = await swal({
            title: "Apakah Anda yakin?",
            text: "Data yang sudah dihapus tidak bisa dikembalikan!",
            icon: "warning",
            buttons: ["Batal", "Hapus"],
            dangerMode: true,
        });

        if (!confirmDelete) return;

        setLoading(true);
        try {
            const response = await API.delete(`user-service/settings/card/key/${id}`, fetchParams);
            if (response.status === 200) {
                swal({
                    title: "Berhasil!",
                    text: "Data berhasil dihapus.",
                    icon: "success",
                    timer: 3000,
                    buttons: false
                });
                setGetData(getData.filter(item => item.id !== id));
            }
        } catch (error) {
            swal({
                title: "Gagal!",
                text: error.response?.data?.message || "Terjadi kesalahan saat menghapus data.",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            setLoading(false);
        }
    };

    // Handle Edit Button
    const handleEdit = (item) => {
        setSelectedKey(item.id);
        setFormData({ key: item.key, value: item.value });
        setEditMode(true);
    };

    // Handle Form Input Change
    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    // Handle Update Key
    const handleUpdate = async (e) => {
        e.preventDefault();
        setLoading(true);

        try {
            const response = await API.put(`user-service/settings/card/key/${selectedKey}`, formData, fetchParams);
            if (response.status === 200) {
                swal({
                    title: "Berhasil!",
                    text: "Data berhasil diperbarui.",
                    icon: "success",
                    timer: 3000,
                    buttons: false
                });

                setGetData(getData.map(item => (item.id === selectedKey ? { ...item, ...formData } : item)));
                setEditMode(false);
                setSelectedKey(null);
                setFormData({ key: "", value: "" });
            }
        } catch (error) {
            swal({
                title: "Gagal!",
                text: error.response?.data?.message || "Terjadi kesalahan saat memperbarui data.",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
                <div style={{ flex: "92%", fontSize: "20px", color: "white", fontWeight: "600", paddingLeft: "10px" }}>
                    Data Key
                </div>
                <div style={{ flex: "8%", fontSize: "30px", color: "white", textAlign: "center" }}>
                    <FaTimes onClick={handleClose} style={{ cursor: "pointer" }} />
                </div>
            </div>

            <Modal.Body>
                <Row className="d-flex justify-content-center">
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>No</th>
                                <th>Key</th>
                                <th>Value</th>
                                <th>Setup</th>
                            </tr>
                        </thead>
                        <tbody>
                            {getData.length > 0 ? (
                                getData.map((item, index) => (
                                    <tr key={item.id}>
                                        <td>{index + 1}</td>
                                        <td>{item.key}</td>
                                        <td>{item.value}</td>
                                        <td>
                                            <Button variant="warning" size="sm" className="me-2" onClick={() => handleEdit(item)}>
                                                Edit
                                            </Button>
                                            <Button variant="danger" size="sm" onClick={() => handleDelete(item.id)}>
                                                Hapus
                                            </Button>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="4" className="text-center">Belum ada data</td>
                                </tr>
                            )}
                        </tbody>
                    </Table>

                    {/* Form Update */}
                    {editMode && (
                        <Form onSubmit={handleUpdate} className="mt-3">
                            <Form.Group className="mb-3">
                                <Form.Label>Key</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="text"
                                        placeholder="Masukkan Key"
                                        name="key"
                                        value={formData.key}
                                        onChange={handleChange}
                                        required
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Form.Group className="mb-3">
                                <Form.Label>Value</Form.Label>
                                <InputGroup>
                                    <Form.Control
                                        type="number"
                                        placeholder="Masukkan Value"
                                        name="value"
                                        value={formData.value}
                                        onChange={handleChange}
                                        required
                                        min="1"
                                        max="12"
                                    />
                                </InputGroup>
                            </Form.Group>

                            <Button type="submit" variant="primary" className="float-end me-2">
                                Update
                            </Button>
                            <Button variant="secondary" className="float-end" onClick={() => setEditMode(false)}>
                                Batal
                            </Button>
                        </Form>
                    )}
                </Row>
            </Modal.Body>
        </Modal>
    );
};

export default ModalDataKey;
