import { useEffect, useState } from "react";
import { Row, Col, Form } from 'react-bootstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaSpinner, FaCheckCircle, FaTable, FaSignal, FaWeightHanging, FaWatchmanMonitoring, FaPager, FaFileInvoice, FaCamera } from 'react-icons/fa'
import "bulma/css/bulma.css";
import "../../index.css"
import LoaderAction from "../Loader/LoaderAction"
import Component from "./Components/Tabs/Component";
import Main from "./Components/Tabs/Main";
// import General from "./Components/Tabs/General";
// import Component from "./Components/Tabs/Component";

// 
export default function Login() {
  document.title = "SDC Apps";
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [ascending, setAscending] = useState(1);
  const [keyword, setKeyword] = useState("");
  const token = localStorage.getItem("token");
  // const user_data = localStorage.getItem("user_data");
  const [getDataProfile, setGetDataProfile] = useState([]);
  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);
  const [getDataProfileRole, setGetDataProfileRole] = useState([]);


  const [loading, setLoading] = useState(false);
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })


  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfile(response.data.data)
        setGetDataProfileDetail(response.data.data.user_detail)
        setGetDataProfileRole(response.data.data.roles)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }


  useEffect(() => {
    GetResponseDataProfile()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, limit, ascending, keyword])


  const RefreshBottom = () => {
    window.location.reload()
  }


  return (
    <>
      {loading && <LoaderAction />}

      <Row className='match-height' style={{ backgroundColor: "#F3FFFD", padding: isTabletOrMobile ? "0px 0px 20px 0px" : "0px 20px 30px 10px" }}>
        <div style={{ display: "flex", justifyContent: "center", backgroundColor: "white", boxShadow: "2px 2px 10px #BFBFBF" }}>
          <div style={{ padding: "10px", textAlign: "center" }}>
            <div style={{ fontSize: "20px", color: "#001F8B" }}>
              Hello, {getDataProfileDetail === null ? getDataProfile?.username : getDataProfileDetail?.firstname} {getDataProfileDetail === null ? "" : getDataProfileDetail?.lastname}
            </div>
            <div style={{ fontSize: "10px", color: "#848484" }}>
              Selamat Bertugas, Have A Nice Day
            </div>
          </div>
        </div>
        <Col xl='12' style={{ marginTop: "10px" }}>

          <div style={{ backgroundColor: "white", marginTop: "15px", height: "", boxShadow: "2px 2px 10px #BFBFBF" }}>

            {isTabletOrMobile ?
              <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
                <Col xl="6" style={{ fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", padding: "7px", color: "#001F8B", backgroundColor: "#E9EFFF" }}>
                  <FaAddressBook style={{ marginRight: "5px" }} />Update Profile
                </Col>
                <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent: "end", paddingRight: "5px" }}>
                  <div onClick={RefreshBottom} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
                    <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
                  </div>
                </Col>
              </div>
              :
              <>
              </>
              // <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
              //   <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
              //     <FaAddressBook style={{marginRight:"5px"}}/>Daftar Antrian Pasien
              //   </div>
              // </div>  
            }

            <Col xl='12' sm='12'>
              <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
                <Main
                  loading={loading}
                  setLoading={setLoading}
                  page={page} limit={limit}
                  ascending={ascending}
                  keyword={keyword}
                />
              </div>
            </Col>

          </div>
        </Col>
      </Row>
    </>
  );
}
