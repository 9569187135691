/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { Link } from "react-router-dom";
import { FaDotCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Navbar as NavbarComp } from 'react-bootstrap'
import Logonav from '../../assets/signature/signatureNewIcon.png'
import "./Sidebar.css"
import { setAuthToken } from '../../config/api';
import { useMediaQuery } from 'react-responsive'
import BackgroundBatik from "../../assets/signature/logo-sidebar-2.jpg"
import BackgroundBatik2 from "../../assets/signature/background-submenu.jpg"
// import BackgroundBatik from "../../assets/signature/logo-sidebar-gray.jpg"
// import BackgroundBatik from "../../assets/signature/logo-sidebar-deep-blue.jpg"
// import BackgroundBatik from "../../assets/signature/logo-sidebar-charcoal-black.jpg"
// import BackgroundBatik from "../../assets/signature/logo-sidebar-dark-green.jpg"
// import BackgroundBatik2 from "../../assets/signature/background-submenu-soft-green.jpg"
import { useLocation } from 'react-router-dom';
import { SettingGeneralContext, SettingGeneralProvider } from "../../context/settingGeneralContext";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}


export default function SidebarHome() {
  return (
    <SettingGeneralProvider>
      <SidebarContent />
    </SettingGeneralProvider>
  );
}


function SidebarContent() {
  const navigate = useNavigate();
  const { state } = useContext(SettingGeneralContext);

  const appName = state?.generalSettings?.app_name || "PT. Signature Anugerah Sentosa";
  const appBackgroundIMG = state?.generalSettings?.app_logo;
  const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
  const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
  const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
  const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
  const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;



  const storageItems = JSON.parse(localStorage.getItem('menus'));
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const urlColor = ""
  const location = useLocation("");
  const levelUser = localStorage.getItem('level');

  const logout = (e) => {
    e.preventDefault();
    localStorage.clear();
    navigate("/");
  };

  const backgroundImage = appBackgroundIMG || BackgroundBatik;
  const backgroundImage2 = appBackgroundIMG || BackgroundBatik2;
  const backgroundColor = appSidebarColor2 || "white";
  const backgroundColor2 = appSidebarColor3 || "#FFDC77";
  const boxShadow = appSidebarColor5 || " #9E9E9E";
  const SubmenuColor = appSidebarColor1 || "#666666"

  return (
    <Sidebar
      backgroundColor={`url${urlColor}`}
      className='sidebar'
      style={{
        width: "100%",
        height: "100%",
        position: "",
        border: "none",
        boxShadow: `1px 1px 10px ${boxShadow}`,
        // backgroundColor: 'red',
        backgroundImage: `url("${backgroundImage}")`,
      }}
    >
      <Menu style={{ marginTop: "2vh" }} closeOnClick>
        <div style={{ padding: "0px 40px" }}>
          <NavbarComp.Brand
            as={Link} to="/dashboard"
            style={{
              display: "flex",
              justifyContent: "center",
              textAlign: "center",
              backgroundColor: `${backgroundColor}`,
              borderRadius: "5px",
              boxShadow: `1px 1px 10px ${boxShadow}`,
              opacity: ".8",
              cursor: "pointer"
            }}>
            <img
              src={Logonav}
              style={{
                width: "100%",
                margin: "30px",
                padding: "5px"
              }}
            />
          </NavbarComp.Brand>
        </div>

        <div className='mt-3 mb-3'
          style={{
            opacity: ".7",
            display: "flex",
            justifyContent: "center",
            fontSize: "13px",
            backgroundColor: `${backgroundColor2}`,
            padding: "10px 0px",
            fontFamily: "'Poppins', sans-serif"
          }}
        >
          {appName} 
        </div>

        {storageItems.map((item, index) => (
          (item.url === "" || item.url == null) ? (
            (item.name === "User Previlege" && levelUser !== "developer") ? (
              <></>
            ) : (
              <SubMenu
                key={index}
                style={{
                  fontFamily: "sans-serif",
                  paddingLeft: "30px",
                  display: "flex",
                  alignItems: "center",
                  height: "5vh",
                  borderTop: "1px solid #D5D5D5",
                  borderBottom: "1px solid #D5D5D5",
                  fontWeight: "bold",
                  color: `${SubmenuColor}`,
                  fontSize: "14px"
                }}
                label={item.name}  //tulisan putih
                icon={
                  <i className={item.icon_name}
                    style={{
                      display: "flex",
                      height: "100%",
                      alignItems: "center",
                      paddingLeft: "10px",
                      marginLeft: "10px",
                      color: `${SubmenuColor}`,
                      fontSize: "20px"
                    }} />}>
                {item.menus.map((itemss) => (
                  <MenuItem
                    backgroundColor={`url${urlColor}`}
                    href={itemss.url}
                    style={{
                      borderTop: "1px solid #D5D5D5",
                      height: "5vh",
                      fontSize: "12px",
                      fontWeight: "",
                      borderBottom: "1px solid #D5D5D5",
                      fontFamily: "sans-serif",
                      paddingLeft: "55px",
                      backgroundColor: "gray",
                      backgroundImage: `url(${backgroundImage2})`
                    }}
                  >
                    <FaDotCircle
                      style={{
                        marginRight: "10px",
                        marginBottom: "3px",
                        fontSize: "10px",
                        color: "#666666"
                      }}
                    />
                    {itemss.name}
                  </MenuItem>
                ))}
              </SubMenu>
            )
          ) : (
            <MenuItem
              href={item.url}
              style={{
                fontFamily: "sans-serif",
                marginLeft: "0px"
              }} icon={
                <i className={item.icon_name}
                  style={{
                    marginLeft: "15px",
                    color: "#666666",
                    fontSize: "20px"
                  }}
                />}
            >
              {item.name}
            </MenuItem>
          )
        ))}

      </Menu>
    </Sidebar>
  )
}
