import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaSpinner, FaCheckCircle, FaTable, FaSignal, FaWeightHanging, FaWatchmanMonitoring, FaPager, FaFileInvoice, FaCamera} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import logo_side from "../../assets/signature/logo-side-detail.png"
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import "bulma/css/bulma.css";
import "../../index.css"
import { useMutation } from "react-query";
import ModalImageProfile from "./ModalAdmin/ModalUpload"

// 
export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);

  document.title = "SDC Apps";
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [getCountAppointment, setCountAppointment] = useState();
  const [getCountPatient, setCountPatient] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const level = localStorage.getItem('level');
  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  const filteredRoles = rolesData.filter(role => role.name === 'Doctor');
  const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');
  const filteredRolesANurse = rolesData.filter(role => role.name === 'Nurse');
  const levelUser = localStorage.getItem('level');
  // const user_data = localStorage.getItem("user_data");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search,setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataCountDokter, setGetDataCountDokter] = useState([]);
  const [getDataProfile, setGetDataProfile] = useState([]);
  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);
  const [getDataProfileRole, setGetDataProfileRole] = useState([]);
  const [getResponseDataPictureProfile, setGetDataPictureProfile] = useState([]);
  const [getDataPictureProfileUrl, setGetDataPictureProfileUrl] = useState([]);
  const [getDataPictureProfileId, setGetDataPictureProfileId] = useState([]);
  const [getDataPictureProfileUserId, setGetDataPictureProfileUserId] = useState([]);
  const [getDataPictureProfileUpdate, setGetDataPictureProfileUpdate] = useState(null);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  const [modalUpload, setModalUpload] = useState(false);

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };


  // const GetResponseData = async () => {
  //   try {
  //     // e.preventDefault();
  //     setLoading(true)
  //     const response = await API.get(`/transaction-service/transaction-service/dashboard/get-appointment/today?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
  //     // Checking process
  //     if (response?.status === 200) {
  //       setGetData(response.data.data)
  //       setPage(response.data.pagination.current_page);
  //       setPages(response.data.pagination.total_pages);
  //       setRows(response.data.pagination.total);
  //       setCountAppointment(response.data.countAppointment);
  //       setCountPatient(response.data.countPatient);
  //       setLoading(false)
  //     }
  //   } catch (error) {
  //     setLoading(false)
  //     swal({
  //       title: 'Failed',
  //       text: `${error.response.data.message}`,
  //       icon: 'error',
  //       timer: 3000,
  //       buttons: false
  //     });
  //   }
  // }

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfile(response.data.data)
        setGetDataProfileDetail(response.data.data.user_detail)
        setGetDataProfileRole(response.data.data.roles)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataPictureProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/pictures/profile?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataPictureProfile(response.data.data)
        setGetDataPictureProfileId(response.data.data?.id)
        setGetDataPictureProfileUserId(response.data.data?.id_user)
        setGetDataPictureProfileUrl(response.data.data?.url_image)
        setGetDataPictureProfileUpdate(response.data.data?.update_by)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }
  
  useEffect(() => {
    GetResponseDataProfile()
    GetResponseDataPictureProfile()
  }, [page, limit, ascending, keyword])

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true)

      const payload = {
        firstname: form?.firstname,
        lastname: form?.lastname,
        email : form?.email || getDataProfile?.email,
        username: form?.username || getDataProfile?.username,
        level : form?.level || getDataProfile?.level,
        unique_id : form?.unique_id || getDataProfile?.user_detail?.unique_id,
        birth_day : form?.birth_day || getDataProfile?.user_detail?.birth_day,
        birth_place : form?.birth_place || getDataProfile?.user_detail?.birth_place,
        address : form?.address || getDataProfile?.user_detail?.address,
        phone_number: form?.phone_number || getDataProfile?.user_detail?.phone_number,
        gender: form?.gender || getDataProfile?.user_detail?.gender,
      }
      const response = await API.post(`user-service/profiles/update`, payload ,fetchParams)

      if (response?.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 5000,
          buttons: false,
        });
  
        // Refresh data pengguna
        GetResponseDataProfile();
  
        // Reset state loading
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  })

  const handlePasswordSubmit = useMutation(async (e) => {
    e.preventDefault(); // Cegah form dari reload halaman
    
    const { password, confirmPassword, email} = form;
    // Validasi email
    if (email !== getDataProfile?.email) {
      swal({
        title: 'Failed',
        text: `Invalid email.`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });

      return;
    }
    // Validasi password
    if (password !== confirmPassword) {
      swal({
        title: 'Failed',
        text: `Passwords do not match!`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });

      return;
    }
  
    if (password.length < 6) {
      swal({
        title: 'Failed',
        text: `Password must be at least 6 characters.`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });

      return;
    }
    try {
      const payload = {
        password: form?.password,
        username: form?.username,
        email: form?.email,
        level: form?.level,
        unique_id: form?.unique_id,
        firstname: form?.firstname,
        lastname: form?.lastname,
        address: form?.address,
        phone_number: form?.phone_number,
        birth_day: form?.birth_day ,
        birth_place: form?.birth_place,
        gender: form?.gender,
      };
      const response = await API.post(`user-service/users/profile`, payload, fetchParams);
  
      if (response?.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 5000,
          buttons: false,
        });
  
        // Refresh data pengguna
        GetResponseDataProfile();
  
        // Reset state loading
        setLoading(false);
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  });
  
  const [form, setForm] = useState({
    firstname: "",
    lastname: "",
    email : "",
    username: "",
    level : "",
    unique_id: "",
    birth_day: "",
    birth_place: "",
    address : "",
    phone_number: "",
    gender: "",
    password: "",
    confirmPassword: "",
  });

  useEffect(() => {
    setForm({
      ...form,
      firstname: getDataProfile?.user_detail?.firstname || "",
      lastname: getDataProfile?.user_detail?.lastname || "",
      email: getDataProfile?.email || "",
      username: getDataProfile?.username || "",
      level: getDataProfile?.level || "",
      unique_id: getDataProfile?.user_detail?.unique_id || "",
      birth_day: getDataProfile?.user_detail?.birth_day || "",
      birth_place: getDataProfile?.user_detail?.birth_place || "",
      address: getDataProfile?.user_detail?.address || "",
      phone_number: getDataProfile?.user_detail?.phone_number || "",
      gender: getDataProfile?.user_detail?.gender || "",
    });
  }, [getDataProfile]); 
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm((prevForm) => ({
      ...prevForm,
      [name]: value,
    }));
  };

  const RefreshBottom = () => {
    window.location.reload()
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  const [activeTab, setActiveTab] = useState("Identity");
  const tabs = ["Identity", "Change Password"];

  const viewModalUpload = () => {
    setModalUpload(true)
  }

  return (
    <>
      {modalUpload && <ModalImageProfile GetResponseDataProfile={GetResponseDataProfile} GetResponseDataPictureProfile={GetResponseDataPictureProfile} pictureUrl={getDataPictureProfileUrl} updateBy={getDataPictureProfileUpdate} pictureId={getDataPictureProfileId} pictureUserId={getDataPictureProfileUserId} show={modalUpload}  onHide={() => setModalUpload(false)} />}
    
      <Row className='match-height' style={{ backgroundColor: "#F3FFFD", padding: isTabletOrMobile? "0px 0px 20px 0px":"0px 20px 30px 10px"}}>
        <Col xl='8' style={{  marginTop:"10px"}}>
          <div style={{display:"flex", justifyContent:"center", backgroundColor:"white",boxShadow: "2px 2px 10px #BFBFBF"}}>
            <div style={{padding:"10px", textAlign:"center"}}>
              <div style={{fontSize:"20px", color:"#001F8B"}}>
                Hello, {getDataProfileDetail === null?  getDataProfile?.username : getDataProfileDetail?.firstname} {getDataProfileDetail === null? "" : getDataProfileDetail?.lastname} 
              </div>
              <div style={{fontSize:"10px", color:"#848484"}}>
                Selamat Bertugas, Have A Nice Day
              </div>
            </div>
          </div>

          <div style={{backgroundColor:"white", marginTop:"15px",height:"",boxShadow: "2px 2px 10px #BFBFBF"}}>
            
          {isTabletOrMobile ? 
            <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
              <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
                <FaAddressBook style={{marginRight:"5px"}}/>Update Profile
              </Col>
              <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
                <div onClick={RefreshBottom} style={{height:"100%", marginRight:"5px", paddingTop:"0px",backgroundColor:"white",padding:"10px 10px", borderRadius:"2px", cursor:"pointer", border:"1px solid #DEDEDE"}}>
                  <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
                </div>
              </Col>
            </div>
              :
              <>
              </>
            // <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
            //   <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
            //     <FaAddressBook style={{marginRight:"5px"}}/>Daftar Antrian Pasien
            //   </div>
            // </div>  
          }
          
          <Col xl='12' sm='12'> 
            <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto"}}>
              <div className="tabs is-boxed">
                <ul>
                  {tabs.map((tab) => (
                    <li
                      key={tab}
                      className={activeTab === tab ? "is-active" : ""}
                      onClick={() => setActiveTab(tab)}
                    >
                      <a>{tab}</a>
                    </li>
                  ))}
                </ul>
              </div>
              {/* Tabs Content */}
              <div className="box">
                {activeTab === "Identity" && 
                  <>
                    <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
                      <div className="container">
                        <div class="columns">
                          <div class="column">
                            <div className="field">
                              <label className="label is-size-7">First Name</label>
                              <div className="control">
                                <input 
                                  className="input is-normal" 
                                  type="text"  
                                  name="firstname" 
                                  value={ form?.firstname || getDataProfile?.user_detail?.firstname} 
                                  onChange={handleChange} 
                                  placeholder="Enter..." 
                                />
                              </div>
                            </div>

                          </div>
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">Last Name</label>
                              <input 
                                class="input is-normal" 
                                type="text" 
                                name="lastname"
                                value={ form?.lastname || getDataProfile?.user_detail?.lastname} 
                                onChange={handleChange} 
                                placeholder="Enter..." />
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">NIK Number</label>
                              <input 
                                class="input is-normal" 
                                type="number"
                                name="unique_id"
                                value={ form?.unique_id || getDataProfile?.user_detail?.unique_id} 
                                onChange={handleChange} 
                                placeholder="Enter..."
                              />
                            </div>
                          </div>
                          <div class="column">
                            <div className="field">
                              <label className="label is-size-7">Gender</label>
                              <div className="radios">
                                <div className="columns">
                                  <div className="column">
                                    <label className="radio">
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="male"  
                                        checked={form.gender === "male"} 
                                        onChange={handleChange}
                                      />
                                      Male
                                    </label>
                                  </div>
                                  <div className="column">
                                    <label className="radio">
                                      <input
                                        type="radio"
                                        name="gender"
                                        value="female"  
                                        checked={form.gender === "female"}
                                        onChange={handleChange}
                                      />
                                      Female
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">Birth Place</label>
                              <input 
                                  class="input is-normal" 
                                  type="text"
                                  name="birth_place"
                                  value={ form?.birth_place || getDataProfile?.user_detail?.birth_place} 
                                  onChange={handleChange} 
                                  placeholder="Enter..." />
                            </div>
                          </div>
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">Birth Day</label>
                                <input 
                                  class="input is-normal" 
                                  type="date"
                                  name="birth_day"
                                  value={ form?.birth_day || getDataProfile?.user_detail?.birth_day} 
                                  onChange={handleChange} 
                                />
                            </div>
                          </div>
                        </div>
                        <div class="columns">
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">Phone Number</label>
                              <input 
                                  class="input is-normal" 
                                  type="number"
                                  name="phone_number"
                                  value={ form?.phone_number || getDataProfile?.user_detail?.phone_number} 
                                  onChange={handleChange} 
                                  placeholder="Enter..." />
                            </div>
                          </div>
                          <div class="column">
                            <div class="field">
                              <label class="label is-size-7">Address</label>
                              <input 
                                  class="input is-normal" 
                                  type="text"
                                  name="address"
                                  value={ form?.address || getDataProfile?.user_detail?.address} 
                                  onChange={handleChange} 
                                  placeholder="Enter..." />
                            </div>
                          </div>
                        </div>
      
                        <div class="is-flex is-justify-content-flex-end mt-1">
                          <button type="submit" class="button is-link is-small is-rounded">Update</button>
                        </div>
                        <div className="mt-4"></div>
                      </div>
                    </Form>
                  </>
                }
                {activeTab === "Change Password" && 
                  <>
                    <Form onSubmit={(e) => handlePasswordSubmit.mutate(e)} className="mt-3">
                      <div className="container">
                        <div className="field">
                          <label className="label is-size-7">Email</label>
                          <div className="control">
                            <input
                              className="input is-normal"
                              name="email"
                              onChange={(e) => setForm({ ...form, email: e.target.value })}
                              type="email"
                              placeholder="Enter your email"
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label is-size-7">New Password</label>
                          <div className="control">
                            <input
                              className="input is-normal"
                              name="password"
                              value={form.password}
                              onChange={(e) => setForm({ ...form, password: e.target.value })}
                              type="password"
                              placeholder="Enter new password"
                            />
                          </div>
                        </div>
                        <div className="field">
                          <label className="label is-size-7">Confirm Password</label>
                          <div className="control">
                            <input
                              className="input is-normal"
                              name="confirmPassword"
                              value={form.confirmPassword}
                              onChange={(e) => setForm({ ...form, confirmPassword: e.target.value })}
                              type="password"
                              placeholder="Confirm new password"
                            />
                          </div>
                        </div>

                        {/* Display error message if validation fails */}
                        {errorMessage && <p className="has-text-danger">{errorMessage}</p>}

                        <div className="is-flex is-justify-content-flex-end mt-1">
                          <button type="submit" className="button is-link is-small is-rounded">Update</button>
                        </div>
                        <div className="mt-4"></div>
                      </div>
                    </Form>
                  </>
                }
              </div>
            </div>
          </Col>      

          </div>
        </Col>
        
        <Col xl='4' sm='12' style={{  height: "100%", paddingBottom:"20px", marginTop: "10px", backgroundColor:"white",boxShadow: "2px 2px 10px #BFBFBF", marginRight:"0px", paddingBottom:"30px" }}>
          <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:isTabletOrMobile?"10px":"0px",marginRight:"10px", backgroundColor:"white", }}>
              <div style={{fontSize:"17px", fontFamily:"revert-layer",color:"#001F8B"}}>
                Profile
              </div>
          </div>


          {getResponseDataPictureProfile?.url_image === null || getResponseDataPictureProfile?.url_image === undefined || getResponseDataPictureProfile?.url_image === ""  ? 
            <div style={{display:"flex",justifyContent:"center", paddingTop:"0px",paddingBottom:"0px", marginRight:"0px", backgroundColor:"white" }}>
              <img onClick={() => viewModalUpload()} className="mt-3" src={'https://storageapps.signatureanugerah.co.id/public/images/no-profile.png'}  style={{width:"45%", borderRadius:"50%", border:"1px solid #C9C9C9", cursor:"pointer"}}/>
            </div>
              :
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "0px", paddingBottom: "0px", marginRight: "0px", backgroundColor: "white" }}>
              {/* <img  onClick={() => viewModalUpload()} src={getResponseDataPictureProfile?.url_image} style={{width:"45%", borderRadius:"50%", border:"1px solid #C9C9C9", cursor:"pointer"}}/> */}

              <figure class="image is-228x228">
                <img 
                  onClick={() => viewModalUpload()} 
                  src={getResponseDataPictureProfile?.url_image} 
                  style={{ cursor: "pointer", height:"295px" }} 
                />
              </figure>
            </div>
          }

          <div 
            className="mt-2" 
            style={{
              display: "flex", 
              justifyContent: "center", 
              padding: "5px 0", 
              marginRight: "0px", 
              backgroundColor: "#BAD2FF", 
              color: "#001F8B", 
              fontWeight: "600"
            }}
          >
            {/* Menampilkan Nama Lengkap */}
            {getDataProfileDetail ? (
              `${getDataProfileDetail.firstname || ""} ${getDataProfileDetail.lastname || ""}`
            ) : (
              "-"
            )}

            {/* Menampilkan Peran (Jika Level Bukan "Developer") */}
            {getDataProfile?.level !== "developer" && getDataProfileRole.length > 0 && (
              <span style={{ marginLeft: "5px" }}>
                {getDataProfileRole.map((user, index) => (
                  <span key={user?.id}>
                    {index > 0 && ", "} {/* Menambahkan koma jika lebih dari satu */}
                    - {user?.name}
                  </span>
                ))}
              </span>
            )}
          </div>


          <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white", marginTop:"10px" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Email
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaBookMedical style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%", paddingBottom:"0px"}}>
                  {getDataProfile?.email}
              </div>
            </div>
          </div>

          <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Tanggal Lahir
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaCalendarAlt style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{ color: "#CC6600", fontSize: "12px", marginLeft: "10px", display: "flex", alignItems: "center", height: "100%" }}>
                {getDataProfileDetail === null? "": `${getDataProfileDetail.birth_place}`},&nbsp;
              {getDataProfileDetail === null?  "-" : `${getDataProfileDetail.birth_day}`.split("-")[2]}&nbsp;
                { getDataProfileDetail === null?  "" : `${getDataProfileDetail.birth_day}`.split("-")[1] === "01" ? "Januari" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "02" ? "Februari" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "03" ? "Maret" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "04" ? "April" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "05" ? "Mei" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "06" ? "Juni" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "07" ? "Juli" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "08" ? "Agustus" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "09" ? "September" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "10" ? "Oktober" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "11" ? "November" : "" ||
                `${getDataProfileDetail.birth_day}`.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                {getDataProfileDetail === null?  "" : `${getDataProfileDetail.birth_day}`.split("-")[0]}
              </div>
            </div>
          </div>

          <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Jenis Kelamin
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaTransgender style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                  {getDataProfileDetail === null?  "-" : capitalizeFirst(`${getDataProfileDetail?.gender}`)}
              </div>
            </div>
          </div>

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Usia & Tanggal Lahir
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaCalendarCheck style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%", paddingTop:"2px"}}>
                  24 Tahun, 22 April 1999
              </div>
            </div>
          </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Phone Number
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaMobileAlt style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                  {getDataProfileDetail === null?  "-" : getDataProfileDetail?.phone_number}
              </div>
            </div>
          </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Status
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaWpforms style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                  Belum Menikah
              </div>
            </div>
          </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
            <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                Alamat
            </div>
            <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
              <FaAddressBook style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
              <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
              {getDataProfileDetail === null?  "-" : getDataProfileDetail?.address}

              </div>
            </div>
          </div> */}
        </Col>
      </Row>
    </>
  );
}
