import { createContext, useReducer, useEffect } from "react";
import { API } from "../config/api";

export const SettingGeneralContext = createContext();

const initialState = {
  generalSettings: JSON.parse(localStorage.getItem("generalSetting")) || null,
  showTheme: JSON.parse(localStorage.getItem("selectedTheme")) || null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_GENERAL_SETTINGS":
      localStorage.setItem("generalSetting", JSON.stringify(action.payload));
      return { ...state, generalSettings: action.payload };
    case "SET_SHOW_THEME":
      localStorage.setItem("selectedTheme", JSON.stringify(action.payload));
      return { ...state, showTheme: action.payload }
    default:
      return state;
  }
};

export const SettingGeneralProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    const storedTheme = JSON.parse(localStorage.getItem("selectedTheme"));
    const storedGeneralSetting = JSON.parse(localStorage.getItem("generalSetting"));
    if (storedTheme) {
      dispatch({ type: "SET_SHOW_THEME", payload: storedTheme });
    }
    if (storedGeneralSetting) {
      dispatch({ type: "SET_GENERAL_SETTINGS", payload: storedGeneralSetting });
    }
  }, []);

  const GetDataSettingGeneral = async () => {
    try {
      const token = localStorage.getItem("token");
      let fetchParams = {
        headers: { Authorization: `${token}`, "Content-Type": "application/json" },
      };

      const response = await API.get("user-service/settings/general", fetchParams);

      if (response?.status === 200 && response.data?.data?.length > 0) {
        const newSettings = response.data.data[0];

        if (JSON.stringify(state.generalSettings) !== JSON.stringify(newSettings)) {
          dispatch({ type: "SET_GENERAL_SETTINGS", payload: newSettings });
        }
      }
    } catch (error) {
      console.error("Gagal mengambil general settings:", error);
    }
  };


  useEffect(() => {
    // GetDataSettingGeneral();
  }, []);

  return (
    <SettingGeneralContext.Provider value={{ state, dispatch }}>
      {children}
    </SettingGeneralContext.Provider>
  );
};
