import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes, FaCamera, FaCheckCircle, FaReply, FaRegPlayCircle, FaUndo} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import logoCamera from "../../../assets/signature/logo-camera.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const idUser = localStorage.getItem("id_admin");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getDataPayment, setGetDataPayment] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const [getDataAppointmentById, setGetDataAppointmentById] = useState([]);
  const [getData, setGetData] = useState([]);
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  
  const [uniq, setUniq] = useState([])
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
  
    image_name : imageName,
    image_content: imageContent,
 
  });

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    });

    if (e.target.type === "file") {
      const file = e.target.files[0]
      
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64)
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
    
      const pictures = [{
        filename: imageName,
        content: imageContent, 
        is_signature: false,   
        set_as_pp: true        
      }];
      
      const  response = await API.post(`user-service/pictures/profile`, { pictures }, fetchParams);
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
    
        props.GetResponseDataProfile();
        props.onHide();
        window.location.reload();
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false); 
    }
  });

  

  const resetUpload = () => {
    setImageName([])
    setImageContent([])
    setPreview(null)
  }

  

  console.log(props?.pictureUserId)
  if (props?.pictureUserId === undefined) {
    console.log("tidak tersedia")
  }else if(props?.pictureUserId !== undefined){
    console.log("tersedia")
  }

  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Upload your profile photo
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="" >
    
        <div style={{display:"flex", justifyContent:"center",  padding:"30px 0px"}}>
          {preview?
          <>
            <img src={preview} style={{minWidth:"320px",maxWidth:"320px", minHeight:"300px", maxHeight:"300px", }}/>
          </>
            :
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input type="file" id="image_name" name="image_name" onChange={handleChange} accept="image/*" hidden/>
            <label for="image_name" style={{border:"none", backgroundColor:"#99CCFF", color:"white", padding:"50px 100px", cursor:"pointer", borderRadius:"5px"}}>
                <FaCamera style={{fontSize:"200px"}}/>
            </label>  
          </div>
          }
        </div>
        {preview ?
          <div style={{ padding: "0px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
            <div style={{ marginRight:"10px" }}>
              <Button onClick={resetUpload} color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", backgroundColor:"#FF4E4E", color:"white"  }}>
                <FaUndo/> Reset
              </Button>
            </div>
            <div>
              <Button className="" type='submit'  block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", backgroundColor:"#00DA0F", border:"none" }}>
                Upload <FaCheckCircle/>
              </Button>
            </div>
          </div>
          :
          <div></div>
        }
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
   
      
    </Modal>
    </div>
   
    );
}
  