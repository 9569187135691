import {useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import {FaUser,FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes} from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"
import DatePicker from "react-datepicker";
import Select from 'react-select';

export default function ModalAdminUpdate(props) {
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [keyword, setKeyword] = useState("");
  document.title = "SDC Apps";
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  const [getCode, setGetCode] = useState([]);
  const { getCode2 } = useState("");
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  const [preview, setPreview] = useState(null);
  const [idRoleDokter, setIdRoleDokter] = useState("");
  const [nameRoleDoctor, setNameRoleDoktor] = useState("Doctor");
  const [getDataDokter, setGetDataDokter] = useState([]);
  const [getDataPasien, setGetDataPasien] = useState([]);
  const [getDataDokterschedule, setGetDataDokterSchedule] = useState([]);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(1000);
  const [ascending, setAscending] = useState(0);
  const idAdmin = localStorage.getItem('id_admin');

  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [startDate, setStartDate] = useState(null); 
  // console.log(startDate)
  const inputDate = new Date(startDate);
  
  // Dapatkan tahun (4 digit)
  const year = inputDate.getFullYear().toString();
  
  // Dapatkan bulan (01-12)
  const month = (inputDate.getMonth() + 1).toString().padStart(2, '0');
  
  // Dapatkan hari (01-31)
  const day = inputDate.getDate().toString().padStart(2, '0');
  
  // Format tanggal dalam format "yyyy/mm/dd"
  const formattedDate = `${year}/${month}/${day}`;

  const [allowedDates, setAllowedDates] = useState([]); // State untuk menyimpan tanggal-tanggal yang diperbolehkan
  const [allowedTimePeriods, setAllowedTimePeriods] = useState([]);

  // Untuk mendapatkan starttimenya
  const [startDateTime, setStartDateTime] = useState(null); 

  const [form, setForm] = useState({
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_dokter_scedule: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    id_patient: "",
    id_admin : ""
  });

  const initialValues = {
    // store appoinment
    anamnesa: "",
    generate_code: `${getCode2}`,
    date_dokter_scedule: "",
    hours_appoinment: "",
    minute_appoinment: "",
    id_dokter: "",
    id_patient: ""
  };

  const {
      // store appoinments,
      anamnesa,
  } = form;

  const GetResponseDatapasien = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/patients?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataPasien(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`user-service/patient-users?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokter(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataScedules = async () => {
    try {
      // e.preventDefault();
      // setLoading(true)
      const response = await API.get(`user-service/doctor-schedules/${form?.id_dokter}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&id_role=${idRoleDokter}&name_role=${nameRoleDoctor}`,fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetDataDokterSchedule(response.data.data)
        // setLoading(false)
      }
    } catch (error) {
      // setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    if (form?.id_patient) {
      generateCode()
    }
  }, [form?.id_patient])

  useEffect(() => {
    if (form?.id_dokter) {
      GetResponseDataScedules()
    }
  }, [form?.id_dokter])

  useEffect(() => {
    // GetResponseData()
    GetResponseDatapasien()
  }, [])

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.type === "date" ? e.target.value : e.target.value,
    });

   
    // Create image url for preview
    if (e.target.type === "file") {
      const file = e.target.files[0]
      // console.log(file)
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64.split(',')[1])
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const postUser = async (e) => {
    e.preventDefault()
    try {
      setLoading(true)
    // Data body
    // const body = JSON.stringify(form);
    const responseAppoinment = await API.post(`transaction-service/patient-appointments/store`,
    {
      id_patient: `${form?.id_patient}`,
      id_doctor: `${form?.id_dokter}`,
      id_admin: `${idAdmin}`,
      code: `${getCode?.number}`,
      date: formattedDate,
      time: startDateTime,
      anamnesa: `${form?.anamnesa}`,
    },fetchParams)

    if (responseAppoinment?.status === 200) {
      const idAppointment = responseAppoinment.data?.id; 
      swal({
        title: 'Success',
        text: "Berhasil Membuat Appoinment Pasien",
        icon: 'success',
        timer: 3000,
        buttons: false
      });
      props.GetResponseData()
      props.onHide()
      setLoading(false)
    }
    setLoading(false)
  } catch (error) {
    swal({
      title: 'Failed',
      text: `${error.response.data.message}`,
      icon: 'error',
      buttons: false
    });
    setLoading(false)
  }
  }

  const generateCode = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.post(`user-service/patient-master-codes/generate`,
        {
          type: "appointment",
          id_patient : form?.id_patient
        },
      fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetCode(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }
  
  useEffect(() => {
  // Ambil semua tanggal dari getDataDokterSchedule
  const allDates = getDataDokterschedule.reduce((dates, item) => {
    dates.push(item.ci_date, item.co_date);
    return dates;
  }, []);

  // Menghilangkan tanggal yang duplikat
  const uniqueDates = [...new Set(allDates)];

  // Format tanggal ke struktur data yang sesuai dengan komponen DatePicker
  const formattedDates = uniqueDates.map((date) => {
    return {
      value: date,
      label: date,
    };
  });

  // Set state dengan tanggal yang diizinkan
  setAllowedDates(formattedDates);
  }, [getDataDokterschedule]);

  useEffect(() => {
    if (startDate) {
      const selectedDate = new Date(startDate);
      selectedDate.setDate(selectedDate.getDate() + 1); // Mengurangkan satu hari
  
      const formattedDate = selectedDate.toISOString().slice(0, 10); // Format kembali menjadi string
  
      const timePeriodsForSelectedDate = getDataDokterschedule
        .filter((item) => item.ci_date === formattedDate)
        .map((item) => item.time_period)
        .flat();
  
      // Set state dengan time_period yang diizinkan
      setAllowedTimePeriods(timePeriodsForSelectedDate);
    }
  }, [getDataDokterschedule, startDate]);

  const optionStyles = (isNew) => ({
    color: isNew ? '#FF5E5E' : 'black'
});

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}

      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Buat Appointment
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
        <form onSubmit={(e) =>postUser}>
          {/* <div style={{paddingTop:"20px",paddingBottom:"20px", backgroundColor: "white",borderTopLeftRadius:"5px", borderTopRightRadius:"5px",borderBottom:"5px solid #1B86FF",}}>
            <div style={{fontSize:"30px",display: "flex",justifyContent:"center", alignItems: "center", fontWeight: "600", color:"#1B86FF" }}>
                Buat Appoinment
            </div>  
          </div> */}
          <div style={{ backgroundColor: "white"}}>
            <div style={{ padding: "0px 10px" }}>
            <div className="mt-1" style={{ display: "flex" }}>
              <div style={{ width: "100%" }}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Keluhan Pasien</div>
                    <input type="text" placeholder='Masukan Keluhan Pasien' value={anamnesa} name="anamnesa" onChange={handleChange}  style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px"}}/>
                  {/* <div style={{fontSize:"10px", color:"#898989", fontStyle:"italic", fontWeight:"bold"}}>Anamnesa</div> */}
                </div>
              </div>    

              <div className="mt-3" style={{ display: ""}}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Pasien</div>
                <select className="form-select" aria-label="Default select example" onChange={handleChange} name="id_patient" style={{ textAlign: "", cursor: "pointer" }}>
                    <option value="">- Pilih pasien -</option>
                    {getDataPasien.map((user, index) => (
                      <>
                      <option key={index} value={user?.id} style={optionStyles(user?.total_rekam_medis === 0)}>
                          {user?.first_name} {user?.last_name} 
                          {user?.total_rekam_medis === 0 ? " (pasien baru)" : ""}
                      </option>
                      </>
                    ))}         
                </select>
            </div>

            {/* <div className="mt-3" style={{ display: ""}}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Pasien</div>
              <Select
                // key={selectKey}
                name="id_patient"
                // className="form-select"
                // aria-label="Default select example"
                onChange={handleInputChange2}
                options={getDataPasien.map(user => ({ value: user?.id, label: user?.first_name +" "+ user?.last_name }))}
                // value={form?.id_treatment}
                placeholder="Pilih Pasien"
                style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px", cursor:"pointer"}}
                />
            </div> */}

            {/* <div className="mt-3" style={{ display: ""}}>
              <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Dokter</div>
              <Select
                // key={selectKey}
                name="id_dokter"
                // className="form-select"
                // aria-label="Default select example"
                onChange={handleInputChange3}
                options={getDataDokter.map(user => ({ value: user?.id, label: user?.firstname +" "+ user?.lastname }))}
                // value={form?.id_treatment}
                placeholder="Pilih Dokter"
                style={{width:"100%", border:"1px solid #C9C9C9", outline:"none", height:"35px", borderRadius:"5px", paddingLeft:"5px", cursor:"pointer"}}
                />
            </div> */}
                      
            <div className="mt-3" style={{ display: ""}}>
                <div style={{fontSize:"12px", color:"", fontStyle:"", fontWeight:"bold"}}>Pilih Dokter</div>
                <select className="form-select" aria-label="Default select example" onMouseEnter={getDataDokter.length === 0? GetResponseData : null}  onChange={handleChange}  name="id_dokter" style={{ textAlign:"", cursor:"pointer"}}>
                  <option value="" hidden>Pilih Dokter</option>
                  {getDataDokter.map((user,index) => (
                    <option value={user?.id} style={{textAlign:""}}>{user?.firstname} {user?.lastname}</option>
                    ))}         
                </select>
            </div>

            {form?.id_dokter && (
            <div className="mt-4" style={{ display: "flex"}}>
              <div style={{display:"flex"}}>
            
              <div style={{ display: "flex", width:"100%" }}>
                <div style={{marginRight:"10px"}}>
                  <DatePicker
                  className="datePicker"
                  placeholderText="Tanggal"
                  selected={startDate}
                  onChange={(date) => setStartDate(date)}
                  filterDate={(date) =>
                    allowedDates.some(
                      (allowedDate) => {
                        // Mengurangkan satu hari dari tanggal yang sedang diuji
                        const previousDay = new Date(date);
                        previousDay.setDate(date.getDate() + 1);
                  
                        return allowedDate.value === previousDay.toISOString().slice(0, 10);
                      }
                    )
                  }
                  />
              </div>
              <div style={{ width: "" }}>
                <select
                  onChange={(e) => setStartDateTime(e.target.value)}
                  class="selectPicker"         
                  name="hours_appointment"
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textAlign: "center",
                    cursor: "pointer",
                    height:"40px",
                    // height: "10px",
                  borderRadius:"4px",
                    outline: "none",
                    border:"1px solid rgb(198, 198, 198)",
                    // borderRadius: "none",
                    marginRight: "0px",
                    appearance: "none",
                    width: "200px",
                    color:"#494949"
                  }}
                  // value={selectedTimePeriod}
                >
                  <option value="" hidden >Waktu</option>
                  {allowedTimePeriods.map((timePeriod) => (
                    <option key={timePeriod} value={timePeriod}>
                      {timePeriod.split(":")[0]}:{timePeriod.split(":")[1]}
                    </option>
                  ))}
                </select>
              </div>                  
            
              </div>              
                          
              </div>
            </div> 
            )}
                        
            </div>
          </div>
        </form>

        <div style={{display:"flex", justifyContent:"center",}}>
      <div style={{display:"flex", justifyContent:"center", marginRight:"10px"}}>
        <div onClick={postUser} className="mt-4" type='' color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", width:"150px", display:"flex", justifyContent:"center", backgroundColor:"#005EEC", color:"white", cursor:"pointer"}}>
          <div>
            Tambah
          </div>
        </div>
      </div>
      </div>
      </Modal.Body>
    </Modal>
    );
}
  