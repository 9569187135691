import React, { useContext, useEffect, useState } from "react";
import { SettingGeneralContext, SettingGeneralProvider } from "../../../context/settingGeneralContext";
import { FaAddressBook, FaCamera, FaCheckCircle, FaEdit, FaEye, FaFileInvoice, FaStreetView, FaSync, FaTable, FaTrash, FaUserMd, FaWatchmanMonitoring } from "react-icons/fa";
import ReactPaginate from "react-paginate";
import { Col } from "reactstrap";
import TableReusable from "../../Global/Table";
import HeaderContentReusable from "../../Global/HeaderContent";


export default function Content({
    RefreshBottom,
    searchData,
    query,
    setQuery,
    filteredRolesANurse,
    filteredRolesAdmin,
    filteredRoles,
    levelUser,
    getData,
    rows,
    page,
    pages,
    changePage,
    msg,
}) {
    return (
        <SettingGeneralProvider>
            <ContentDashboard
                getData={getData}
                RefreshBottom={RefreshBottom}
                searchData={searchData}
                query={query}
                setQuery={setQuery}
                filteredRolesANurse={filteredRolesANurse}
                filteredRolesAdmin={filteredRolesAdmin}
                filteredRoles={filteredRoles}
                levelUser={levelUser}
                rows={rows}
                page={page}
                pages={pages}
                changePage={changePage}
                msg={msg}
            />
        </SettingGeneralProvider>
    );
}

function formatDate(dateString) {
    const months = [
        "Januari", "Februari", "Maret", "April", "Mei", "Juni",
        "Juli", "Agustus", "September", "Oktober", "November", "Desember"
    ];

    const [year, month, day] = dateString.split('-');
    return `${day} ${months[parseInt(month, 10) - 1]} ${year}`;
}

function formatTime(timeString) {
    const [hour, minute] = timeString.split(':');
    return `${hour} : ${minute}`;
}

function ContentDashboard({
    RefreshBottom,
    searchData,
    query,
    setQuery,
    levelUser,
    getData,
    rows,
    page,
    pages,
    changePage,
    msg,

}) {

    const { state } = useContext(SettingGeneralContext);


    const appName = state?.generalSettings?.app_name;
    const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
    const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
    const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
    const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
    const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;



    return (
        <>
            <HeaderContentReusable
                title="Daftar Antrian Pasien"
                icon={FaAddressBook}
                RefreshBottom={RefreshBottom}
                searchData={searchData}
                query={query}
                setQuery={setQuery}
                appSidebarColor1={appSidebarColor1}
                appSidebarColor2={appSidebarColor2}
            />
            
            <Col xl='12' sm='12'>
                <div>
                    <div >
                        <div >
                            <TableReusable
                                levelUser={levelUser}
                                columns={[
                                    { key: "no", label: "No", render: (_, index) => (page - 1) * 10 + index + 1 },
                                    { key: "anamnesa", label: "Anamnesa", },
                                    { key: "patient_name", label: "Pasien" },
                                    { key: "doctor_name", label: "Dokter" },
                                    { key: "tanggal", label: "Tanggal", render: (row) => formatDate(row.date) },
                                    { key: "waktu", label: "Waktu", render: (row) => formatTime(row.time) },
                                    {
                                        key: "action", label: "Action", render: (row) =>
                                            <div className="d-flex gap-2">
                                                <button
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "white",
                                                        backgroundColor: "#ff9933",
                                                        padding: "5px 5px",
                                                        borderRadius: "3px",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        display: "flex"
                                                    }}>
                                                    <FaEye />
                                                </button>
                                                <button
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "white",
                                                        backgroundColor: "#ff9933",
                                                        padding: "5px 5px",
                                                        borderRadius: "3px",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        display: "flex"
                                                    }}>
                                                    <FaEdit />
                                                </button>
                                                <button
                                                    style={{
                                                        fontSize: "12px",
                                                        color: "white",
                                                        backgroundColor: "#ff9933",
                                                        padding: "5px 5px",
                                                        borderRadius: "3px",
                                                        cursor: "pointer",
                                                        border: "none",
                                                        display: "flex"
                                                    }}>
                                                    <FaTrash />
                                                </button>
                                            </div>
                                    }
                                ]}
                                data={getData}
                            />
                        </div>
                    </div>
                </div>
            </Col>
        </>
    )
}