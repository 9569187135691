import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import ModalAddReusable from "../../Global/ModalAdd";

export default function ModalRoleAdd(props) {
  const [loading, setLoading] = useState(false);
  const token = localStorage.getItem("token");

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    name: ""
  });

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = useMutation(async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await API.post("transaction-service/floors/store", {
        name: form.name
      }, fetchParams);

      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
        props.GetResponseData();
        props.onHide();
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: error.response?.data?.message || "Terjadi kesalahan",
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  });

  return (
    <div>
      {loading && <LoaderAction />}
      <ModalAddReusable
        isOpen={props.show}
        isClose={props.onHide}
        handleSubmit={handleSubmit.mutate}
        handleChange={handleChange}
        title="Tambah Lantai"
        fields={[
          { label: "Nama Lantai", name: "name", value: form.name },
        ]}
      />
    </div>
  );
}