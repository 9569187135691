/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Table, Col, Row, Card, Badge } from "react-bootstrap";
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import {FaAddressBook, FaCheckCircle, FaClock, FaDeviantart, FaEye, FaMedkit, FaPlus, FaRegArrowAltCircleLeft, FaRegStickyNote, FaSync, FaTrash} from 'react-icons/fa'
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../Loader/LoaderHome"
import ModalUpload from "./ModalAddPatientRepresentative/ModalUpload";
import ModalImageKTP from "./ModalAddPatientRepresentative/ModalImageKTP";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [pagination,setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [search ] = useState("")
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({query: '(min-width: 1224px)'})
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)'})
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)'})
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [modalUpload, setModalUpload] = useState(false);
  const [modalImageKTP, setModalImageKTP] = useState(false);
  


  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };
  const { id } = useParams()
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState({ 
    first_name: "", 
    last_name: "",
    gender: "",
    date_birth: "",
    nic_number: "",
    nic_file: "",
    email: "",
    password: "",
    phone_number: "",
    residence_address: ""
  });

  
  const handleChange = (e) => {
    setGetData({ ...getData, [e.target.name]: e.target.value });
  };

  const updateUser = async (e) => {
    try {
      e.preventDefault(); 
      setLoading(true)
  
      const body = JSON.stringify(getData);
      const response = await API.put(`transaction-service/patients/${id}`,body, fetchParams)
  
      if (response?.status === 200 ) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });

        GetResponseData();
        setLoading(false);
      } 
    }catch (error){
      setLoading(false)

      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  };


  const GetResponseData = async () => {
    try {
      setLoading(true)
      const response = await API.get(`transaction-service/patients/${id}`,fetchParams)
      if (response?.status === 200 ) {
        setGetData(response.data.data);
        setLoading(false);
      } else {
        setLoading(false);
        swal({
          title: 'No data found',
          text: 'Data not available for this request.',
          icon: 'warning',
          timer: 3000,
          buttons: false,
        });
      } 
    } catch (error) {
      setLoading(false)

      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
      GetResponseData()
  }, [page])


  const buttonRefresh = () => {
    window.location.reload();
  };
  
  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const viewModalUpload = () => {
    setModalUpload(true)
  }

  const viewModalImageKTP = () => {
    setModalImageKTP(true)
  }

   

  const navigateRepresentative = () => {
    navigate('/representative/' + getData?.patient_create_by);
  }

  const navigatePatients = () => {
    navigate('/patients');
  }

  const [activeTab, setActiveTab] = useState("Home");
  const tabs = ["Home","Informasi Pribadi", "Informasi Akun", "Informasi Profesional & Pendidikan"];
  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {loading && <LoaderHome />}
      {modalUpload && <ModalUpload  GetResponseData={GetResponseData}  getData={getData} show={modalUpload}  onHide={() => setModalUpload(false)}/>}
      {modalImageKTP && <ModalImageKTP  getData={getData} show={modalImageKTP}  onHide={() => setModalImageKTP(false)}/>}
      
      {isTabletOrMobile ? 
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{fontSize:"16px",display:"flex", justifyContent:"center", alignItems:"center", padding:"7px", color:"#001F8B", backgroundColor:"#E9EFFF"}}>
              <FaAddressBook style={{marginRight:"5px"}}/>List Pasien
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent:"end", paddingRight:"5px" }}>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"0px"}}>
                <div style={{marginRight:"5px",borderRadius:"3px"}}>
                  <input value={query}
                    onChange={(e) => setQuery(e.target.value)}
                    className="focused"
                    style={{backgroundColor:"#E9E9E9", border:"none",height:"100%"}}
                    type="text"
                    placeholder=""
                  />
              </div>
                <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"2px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                  Search
                </button>
            </form>
          </Col>
        </div>
          :
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
          <div style={{flex:"50%",fontSize:"16px",display:"flex", alignItems:"center", paddingLeft:"10px", color:"#001F8B"}}>
            <div 
            onClick={() =>
              getData?.patient_create_by ? navigateRepresentative() : navigatePatients()
            }
             style={{display:"flex" , marginRight:"10px",fontSize: "11px", color: "white", backgroundColor: "#ABC1FF", padding: "5px 10px", borderRadius: "3px", cursor: "pointer", border: "none", borderTopLeftRadius:"30px", borderBottomLeftRadius:"30px"}}>
              <div style={{display:"flex",alignItems:"center", height:"100%", marginRight:"3px"}}>
              <FaRegArrowAltCircleLeft style={{display:"flex",alignItems:"center", fontSize:"15px"}}/>
            </div>
            <div style={{display:"flex", alignItems:"center", height:"100%", fontSize:"12px"}}>
              Kembali
            </div>
          </div>
            Pasien {getData?.first_name} {getData?.last_name}
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent:"end" }}>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height:"100%", color:"#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{display:"flex", paddingRight:"10px"}}>
              <div style={{marginRight:"5px",borderRadius:"3px"}}>
                  <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{backgroundColor:"#E9E9E9", border:"none",height:"5vh"}}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit"  style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius:"3px", fontWeight:"600", fontSize:"12px",alignItems:"center", padding:"0px 10px" }}>
                Search
              </button>
            </form>
          </div>
        </div>  
      }
       <Row className='match-height' style={{ display:"flex", gap:"30px", justifyContent:"center", backgroundColor: "white", padding: isTabletOrMobile? "0px 0px 20px 0px":"0px 20px 30px 10px"}}>
          <Col xl='12' sm='12' style={{  height: "100%", paddingBottom:"20px", marginTop: "10px", marginRight:"0px", paddingBottom:"30px" }}>
            <div className="p-2">
              <Form onSubmit={updateUser}>
                <Row>
                  <div className="tabs is-boxed">
                    <ul>
                      {tabs.map((tab) => (
                        <li
                          key={tab}
                          className={activeTab === tab ? "is-active" : ""}
                          onClick={() => setActiveTab(tab)}
                        >
                          <a>{tab}</a>
                        </li>
                      ))}
                    </ul>
                  </div>
                  {activeTab === "Home" && 
                      <Row>
                        <Col xl='3' sm='12' >
                          <div style={{display:"flex",justifyContent:"center", paddingTop:"-20px",paddingBottom:"0px", marginRight:"0px", backgroundColor:"white" }}>
                            <img  src={'https://storageapps.signatureanugerah.co.id/public/images/no-profile.png'} style={{width:"90%", borderRadius:"10%", marginTop:"4px",border:"1px solid #C9C9C9"}}/>
                          </div>
                          
                        </Col>
                        <Col xl='9' sm='12' >
                          <div  style={{ textAlign: "left", fontSize: "14px", lineHeight: "1.6" }}>
                          <Row className="mt-2">
                            {/* Informasi Pribadi */}
                            <Col xl="8" sm="12">
                              <Card body className="shadow-sm">
                                <h5><strong>Informasi Pribadi</strong></h5>
                                <hr/>
                                <Row>
                                  <Col xl="6" sm="12">
                                    <p><strong>Nama Lengkap:</strong> {getData?.first_name} {getData?.last_name}</p>
                                    <p><strong>Jenis Kelamin:</strong> {getData?.gender}</p>
                                    <p><strong>Tanggal Lahir:</strong> {getData?.date_birth_id}</p>
                                    <p><strong>Usia:</strong> {getData?.age} Tahun</p>
                                    <p><strong>Kewarganegaraan:</strong> {getData?.nationality}</p>
                                    <p><strong>NIK:</strong> {getData?.nic_number}</p>
                                    <p><strong>Status:</strong> {getData?.status}</p>

                                  </Col>
                                  <Col xl="6" sm="12">
                                    <p><strong>Foto KTP:</strong>
                                    
                                    {getData?.nic_file !== null &&
                                      <>
                                       <Badge onClick={() =>  viewModalImageKTP()} style={{ cursor: "pointer" }}>
                                        Lihat foto KTP
                                        </Badge>
                                      </>
                                    }
                                     
                                     </p>
                                    <p><strong>Nomor HP:</strong> {getData?.phone_number}</p>
                                    <p><strong>Alamat:</strong> {getData?.residence_address}</p>
                                    <p><strong>Alamat (KTP):</strong> {getData?.sub_district}, {getData?.district}, {getData?.regency}, {getData?.province}</p>
                                    <p><strong>Pekerjaan:</strong> {getData?.occupation}</p>
                                    <p><strong>Pendidikan:</strong> {getData?.education}</p>
                                  </Col>
                                </Row>
                              </Card>
                            </Col>

                          
                            {/* Informasi Kesehatan */}
                            <Col xl="4" sm="12">
                              <Card body className="shadow-sm">
                                <h5><strong>Informasi Kesehatan</strong></h5>
                                <hr/>
                                <p><strong>Kode Pasien:</strong> {getData?.code}</p>
                                <p><strong>No. Rekam Medis:</strong> {getData?.medical_record_code}</p>
                              </Card>
                              {/* Informasi Akun */}
                              <Card body className="shadow-sm mt-2">
                                <h5><strong>Informasi Akun</strong></h5>
                                <hr/>
                                <p><strong>Email:</strong> {getData?.email}</p>
                                {getData?.patient_create_by !== null &&
                                <>
                                  <p><strong>Akun didaftar oleh:</strong> 
                                    {getData?.patient_create_by}
                                  </p>
                                </>
                                }
                              </Card>
                            </Col>
                          </Row>
                          </div>
                        </Col>
                      </Row>
                    }
                    {/* Informasi Pribadi */}
                    <Col xl='6' sm='12' >
                      {activeTab === "Informasi Pribadi" &&  
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">First Name</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="text" 
                              name="first_name"
                              value={getData?.first_name}
                              onChange={handleChange}
                              placeholder="Enter First_name" 
                            />
                          </Form.Group>
                          <Form.Group className="mb-3">
                            <Form.Label className="small-text">Gender</Form.Label>
                            <div style={{ display: "flex", gap:"4px"}}>
                              <Form.Check
                                type="radio"
                                label="Laki-Laki"
                                name="gender"
                                value="male"
                                checked={getData?.gender === "male"}
                                onChange={handleChange}
                                id="gender-male"
                              />
                              <Form.Check
                                type="radio"
                                label="Perempuan"
                                name="gender"
                                value="female"
                                checked={getData?.gender === "female"}
                                onChange={handleChange}
                                id="gender-female"
                              />
                            </div>
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Nomor Induk Kependudukan</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="text" 
                              name="nic_number"
                              value={getData?.nic_number}
                              onChange={handleChange}
                              placeholder="Enter nic_number" 
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Nomor Handphone</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="number" 
                              name="phone_number"
                              value={getData?.phone_number}
                              onChange={handleChange}
                              placeholder="Enter Phone Number ..." 
                            />
                          </Form.Group>
                        </>
                      }
                      {activeTab === "Informasi Akun" &&
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Email </Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="email" 
                              name="email"
                              value={getData?.email}
                              onChange={handleChange}
                              placeholder="Enter email" 
                            />
                          </Form.Group>
                        </>
                      }
                      {activeTab === "Informasi Profesional & Pendidikan" &&
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Pekerjaan</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="text" 
                              name="occupation"
                              value={getData?.occupation}
                              onChange={handleChange}
                              placeholder="Enter Pekerjaan ..." 
                            />
                          </Form.Group>
                        </>
                      }
                    </Col>
                    <Col xl='6' sm='12' >
                      {activeTab === "Informasi Pribadi" &&   
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Last Name</Form.Label>
                            <Form.Control 
                              size="sm" type="tex" 
                              placeholder="Enter last name..." 
                              name="last_name"
                              value={getData?.last_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicDate">
                            <Form.Label className="small-text">Date of Birth</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="date" 
                              name="date_birth"
                              value={getData?.date_birth}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Foto KTP</Form.Label>
                              <Badge 
                                onClick={() => viewModalUpload()} 
                              
                              >Upload Foto KTP</Badge>
                            </Form.Group>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Alamat</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="text" 
                              name="residence_address"
                              value={getData?.residence_address}
                              onChange={handleChange}
                              placeholder="Enter Residence Address..." 
                            />
                          </Form.Group>
                        </>
                      }
                       {activeTab === "Informasi Akun" &&
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Password</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="password" 
                              name="password"
                              value={getData?.password}
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </>
                      }
                      {activeTab === "Informasi Profesional & Pendidikan" &&
                        <>
                          <Form.Group className="mb-3" controlId="formBasicEmail">
                            <Form.Label className="small-text">Pendidikan</Form.Label>
                            <Form.Control 
                              size="sm" 
                              type="text" 
                              name="education"
                              value={getData?.education}
                              onChange={handleChange}
                              placeholder="Enter Pendidikan ..." 
                            />
                          </Form.Group>
                        </>
                      }
                    </Col>
                </Row>
                {activeTab !== "Home" && 
                  <Button  className="float-end" variant="primary" type="submit">
                    Submit
                  </Button>
                }
              </Form>
            </div>
          </Col>
      </Row>
    </div>
  );
}
