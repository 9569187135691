import React, { useContext } from 'react';
import { FaAddressBook, FaBuilding, FaPills, FaPlus, FaSync } from 'react-icons/fa';
import HeaderReusable from '../../Global/HeaderContent';
import { SettingGeneralContext, SettingGeneralProvider } from '../../../context/settingGeneralContext';



export default function HeaderMedicalItem({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh
}) {
  return (
    <SettingGeneralProvider>
      <HeaderMedicalItemContent
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        viewModalAdd={viewModalAdd}
        buttonRefresh={buttonRefresh}
      />
    </SettingGeneralProvider>
  )
}


function HeaderMedicalItemContent({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh
}) {
  return (
    <>
      <HeaderReusable
        title="List Obat"
        icon={FaPills}
        isTabletOrMobile={false}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        onAdd={viewModalAdd}
        onRefresh={buttonRefresh}
        showAddButton={true}
        showRefreshButton={true}
      />
    </>
  )
}


