/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import { Button, Col, Form, InputGroup, Row, Table } from "react-bootstrap";
import { API } from "../../../../config/api";
import ModalDataKey from "./Modal/Keys";
import swal from "sweetalert";
import { FaEye } from "react-icons/fa";
import Component from "./Component";
import General from "./General";
import Preferences from "./Preferences"
import { SettingGeneralProvider } from "../../../../context/settingGeneralContext";


const Main = (props) => {
    const [activeTab, setActiveTab] = useState("General");
    return (
        <>
            <div className="tabs is-boxed">
                <ul>
                    {["General", "Preferences", "Component"].map((tab) => (
                        <li
                            key={tab}
                            className={activeTab === tab ? "is-active" : ""}
                            onClick={() => setActiveTab(tab)}
                        >
                            <a>{tab}</a>
                        </li>
                    ))}
                </ul>
            </div>

            {/* Tabs Content */}
            <div className="box">
                {activeTab === "General" && <>
                    <SettingGeneralProvider>
                        <General
                            loading={props?.loading}
                            setLoading={props?.setLoading}
                            page={props?.page} limit={props?.limit}
                            ascending={props?.ascending}
                            keyword={props?.keyword}
                        />
                    </SettingGeneralProvider>
                </>}

                {activeTab === "Preferences" && (
                    <>
                        <SettingGeneralProvider>
                            <Preferences
                                loading={props?.loading}
                                setLoading={props?.setLoading}
                                page={props?.page} limit={props?.limit}
                                ascending={props?.ascending}
                                keyword={props?.keyword}
                            />
                        </SettingGeneralProvider>

                    </>
                )}

                {activeTab === "Component" && (
                    <>
                        <Component
                            loading={props?.loading}
                            setLoading={props?.setLoading}
                            page={props?.page} limit={props?.limit}
                            ascending={props?.ascending}
                            keyword={props?.keyword}
                        />
                    </>
                )}
            </div>
        </>
    );
};

export default Main;
