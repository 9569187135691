import React, { useEffect, useState } from "react";
import { useMutation } from "react-query";
import swal from "sweetalert";
import { API } from "../../../config/api";
import ModalUpdateReusable from "../../Global/ModalUpdate";
import LoaderAction from "../../Loader/LoaderAction"

export default function ModalRoleUpdate({ show, onHide, id, nameUpdate, GetResponseData }) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    name: ""
  });

  useEffect(() => {
    if (show) {
      setForm({ name: nameUpdate || "" });
    }
  }, [show, nameUpdate]);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value
    });
  };

  const mutation = useMutation(async (formData) => {
    setLoading(true);
    try {
      const response = await API.put(
        `transaction-service/floors/${id}`,
        formData,
        fetchParams
      );

      if (response?.status === 200) {
        swal({
          title: "Success",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          buttons: false,
        });
        GetResponseData();
        onHide();
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: error.response?.data?.message || "Something went wrong",
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    } finally {
      setLoading(false)
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(form);
  };


  return (
    <>
      {loading && <LoaderAction />}

      <ModalUpdateReusable
        isOpen={show}
        isClose={onHide}
        form={form}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={[
          { label: "Nama Lantai", name: "name", value: form.name }
        ]}
        title="Update Lantai"
      />
    </>
  );
}
