import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import { useMutation } from "react-query";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import ModalAddMedis from "./ModalAdmin/ModalAdd"
import ModalUpdateMedis from "./ModalAdmin/ModalUpdate"
import { ScaleLoader } from "react-spinners";
import LoaderHome from "../Loader/LoaderHome"
import WellcomeCard from "../Admin/Components/Wellcome";
import HeaderMedical from "./Components/HeaderMedicalItem";
import HeaderMedicalItem from "./Components/HeaderMedicalItem";
import TableMedicalItem from "./Components/TableMedicalItem";

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [pagination, setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  // modal role add 
  const [id, setId] = useState();
  const [nameUpdate, setNameUpdate] = useState();
  const [codeUpdate, setCodeUpdate] = useState();
  const [typeUpdate, setTypeUpdate] = useState();
  const [priceUpdate, setPriceUpdate] = useState();
  const [descriptionUpdate, setDescriptionUpdate] = useState();
  const [urlUpdate, setUrlUpdate] = useState();
  const [iconUpdate, setIconUpdate] = useState();
  const [modalAdd, setModalAdd] = useState(false);
  const [modalUpdate, setModalUpdate] = useState(false);
  const [loading, setLoading] = useState(false);

  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);


  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`emr-service/medical-items?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      if (error.response.status === 401) {
        navigate('/dashboard')
        swal({
          title: 'Failed',
          text: `Akses dibatasi Tidak Dapat Mengakses Halaman Ini`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      } else {
        swal({
          title: 'Failed',
          text: `${error.response.data.message}`,
          icon: 'error',
          timer: 3000,
          buttons: false
        });
      }
    }
  }

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfileDetail(response.data.data.user_detail)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    GetResponseDataProfile()
  }, [page, keyword, limit, ascending])

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.delete(`emr-service/medical-items/${id}`, fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const buttonRefresh = () => {
    window.location.reload();
  }

  const viewModalAdd = () => {
    setModalAdd(true)
  }

  const viewModalUpdate = (id, name, code, type, price, description) => {
    setModalUpdate(true)
    setId(id)
    setNameUpdate(name)
    setCodeUpdate(code)
    setTypeUpdate(type)
    setPriceUpdate(price)
    setDescriptionUpdate(description)
  }

  function formatToRupiah(number) {
    return new Intl.NumberFormat('id-ID', {
      style: 'currency',
      currency: 'IDR',
      minimumFractionDigits: 0, // Set minimum fraction digits to 0
      maximumFractionDigits: 0, // Set maximum fraction digits to 0
    }).format(number);
  }
  

  return (
    <>
      <Row>
        <WellcomeCard
          getDataProfileNull={getDataProfileDetail === null}
          getDataProfileFirstName={getDataProfileDetail?.firstname}
          getDataProfileLastName={getDataProfileDetail?.lastname}
        />
      </Row>
      <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
        {modalAdd && <ModalAddMedis GetResponseData={GetResponseData} show={modalAdd} onHide={() => setModalAdd(false)} />}
        {modalUpdate && <ModalUpdateMedis GetResponseData={GetResponseData} id={id} nameUpdate={nameUpdate} codeUpdate={codeUpdate} typeUpdate={typeUpdate} priceUpdate={priceUpdate} descriptionUpdate={descriptionUpdate} show={modalUpdate} onHide={() => setModalUpdate(false)} />}
        {loading && <LoaderHome />}

        {isTabletOrMobile ?
          <>
            <HeaderMedicalItem
              isTabletOrMobile={isTabletOrMobile}
              query={query}
              setQuery={setQuery}
              searchData={searchData}
              viewModalAdd={viewModalAdd}
              buttonRefresh={buttonRefresh}
            />
            <Col xl='12' sm='12'>
              <div>
                {/* start table */}
                <TableMedicalItem
                  getData={getData}
                  rows={rows}
                  page={page}
                  pages={pages}
                  changePage={changePage}
                  msg={msg}
                  viewModalUpdate={viewModalUpdate}
                  deleteById={deleteById}
                  isTabletOrMobile={isTabletOrMobile}
                  formatToRupiah={formatToRupiah}
                />
                {/* end table */}
              </div>
            </Col>
          </>
          :
          <>
            {/* start header */}
            <HeaderMedicalItem
              isTabletOrMobile={isTabletOrMobile}
              query={query}
              setQuery={setQuery}
              searchData={searchData}
              viewModalAdd={viewModalAdd}
              buttonRefresh={buttonRefresh}
            />
            {/* end header */}
            <Col xl='12' sm='12'>
              <div>
                {/* start table */}
                <TableMedicalItem
                  getData={getData}
                  rows={rows}
                  page={page}
                  pages={pages}
                  changePage={changePage}
                  msg={msg}
                  viewModalUpdate={viewModalUpdate}
                  deleteById={deleteById}
                  isTabletOrMobile={isTabletOrMobile}
                  formatToRupiah={formatToRupiah}
                />
                {/* end table */}
              </div>
            </Col>
          </>
        }

      </div>
    </>
  );
}
