/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Col } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API } from "../../config/api";
import { FaCheckCircle, FaClock, FaRegArrowAltCircleLeft } from 'react-icons/fa'
import "bulma/css/bulma.css";
import "../../index.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
// Modal Role
import LoaderHome from "../Loader/LoaderHome"
import axios from "axios";

export default function Login() {
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [page] = useState(1);
  const [limit] = useState(10);
  const [ascending] = useState(0);
  const [keyword] = useState("");
  const token = localStorage.getItem("token");
  const [search] = useState("")
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  // modal role add 
  const { id } = useParams()
  const [getDataAppointmentPatient, setGetDataAppointmentPatient] = useState([]);
  const [loading, setLoading] = useState(false);
  const [getData, setGetData] = useState([]);

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };


  const GetResponseData = async () => {
    try {
      setLoading(true)
      const response = await API.get(`transaction-service/appointments/ssp/patient/${id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}`, fetchParams)
      if (
        response.data.data.length !== 0 ||
        response?.status === 200
      ) {
        setGetData(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataAppointment = async () => {
    try {
      setLoading(true)
      const response = await API.get(`transaction-service/appointments/ssp/appointment/${id}?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${search}`, fetchParams);
      if (response?.status === 200) {
        if (response.data.data.length !== 0) {
          setGetDataAppointmentPatient(response.data.data);
        }
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetResponseData()
    GetResponseDataAppointment()
  }, [page])

  const navigateHome = () => {
    navigate('/patients');
  }



  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {loading && <LoaderHome />}
      <div style={{ paddingLeft: '0px', width: '100%', borderBottom: '5px solid #EEEEEE', display: 'flex', padding: '10px 0px' }}>
        <div style={{ flex: '50%', fontSize: '16px', display: 'flex', alignItems: 'center', paddingLeft: '10px', color: '#001F8B' }}>
          <div
            onClick={navigateHome}
            style={{
              display: 'flex',
              marginRight: '10px',
              fontSize: '11px',
              color: 'white',
              backgroundColor: '#C3C3C3',
              padding: '5px 10px',
              borderRadius: '3px',
              cursor: 'pointer',
              border: 'none',
            }}
          >
            <div style={{ marginRight: '3px', display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
                <FaRegArrowAltCircleLeft style={{ display: 'flex', alignItems: 'center', fontSize: '15px' }} />
              </div>
            </div>
            <div style={{ display: 'flex' }}>
              <div style={{ display: 'flex', alignItems: 'center', height: '100%', fontSize: '15px' }}>Kembali</div>
            </div>
          </div>
          <div style={{ color: 'black', marginLeft: '5px', fontFamily: 'revert', }}>
            Satu Sehat
          </div>
        </div>
        <div style={{ flex: '50%', display: 'flex', justifyContent: 'end' }}></div>
      </div>

      <Col xl='12' sm='12'>
        <div>
          <div style={{ display: 'block', height: '100%', overflowY: 'auto', overflowX: 'auto', padding: "10px" }}>
            <div className="container mt-2">
              <div className="columns">
                {/* Card 1 (4 bagian) */}
                <div className="column is-4">
                  <div className="card" style={{ maxWidth: '600px', margin: '0 auto', height: 'auto' }}>
                    <div className="card-content" style={{ padding: '10px' }}>
                      <div className="columns is-multiline is-mobile" style={{ margin: '0' }}>
                        <div className="column is-full" style={{ padding: '5px' }}>
                          <p className="title is-size-6">
                            {getData?.patient?.first_name} {getData?.patient?.last_name} <span style={{ color: "green" }}>(Verified)</span>
                          </p>
                          <p className="subtitle is-size-7">{getData?.patient?.medical_record_code}
                            <b> {getData?.id_patient_ssp} </b>
                            <p className="subtitle is-size-7"></p>

                          </p>
                          {/* <button
                              className={`button is-small is-rounded ${isColumnVisible ? 'is-danger' : 'is-info'} is-size-7`}
                              onClick={toggleColumnVisibility}
                            >
                              {isColumnVisible ? 'Close Appointment' : 'Show Appointment'}
                            </button> */}
                        </div>

                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">NIK:</strong>
                          <p className="is-size-7">{getData?.patient?.nic_number}</p>
                        </div>

                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Gender:</strong>
                          <p className="is-size-7">{getData?.patient?.gender}</p>
                        </div>

                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Phone:</strong>
                          <p className="is-size-7">{getData?.patient?.phone_number}</p>
                        </div>

                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Date of Birth:</strong>
                          <p className="is-size-7">{getData?.patient?.date_birth}</p>
                        </div>

                        <div className="column is-full" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Email:</strong>
                          <p className="is-size-7">{getData?.patient?.email || 'Not provided'}</p>
                        </div>


                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Province:</strong>
                          <p className="is-size-7">{getData?.province?.name}</p>
                        </div>
                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Regency:</strong>
                          <p className="is-size-7">{getData?.city?.name}</p>
                        </div>

                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">District:</strong>
                          <p className="is-size-7">{getData?.regency?.name}</p>
                        </div>
                        <div className="column is-half" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Village:</strong>
                          <p className="is-size-7">{getData?.district?.name}</p>
                        </div>

                        <div className="column is-full" style={{ padding: '5px' }}>
                          <strong className="is-size-7">Address:</strong>
                          <p className="is-size-7">{getData?.patient?.residence_address}, {getData?.patient?.nic_address}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                {/* {isColumnVisible && ( */}
                <div className="column is-8">
                  <div className="card" style={{ display: 'flex', flexDirection: 'row', margin: '20px auto' }}>
                    <div className="card-content" style={{ flex: 1, padding: '20px' }}>
                      <table className="table is-fullwidth is-size-7">
                        <thead>
                          <tr>
                            <th className="has-text-centered">Appointment Code</th>
                            <th className="has-text-centered">Anamnesa</th>
                            <th className="has-text-centered">Doctor Name</th>
                            <th className="has-text-centered">Admin Name</th>
                            <th className="has-text-centered">Date</th>
                            <th className="has-text-centered">Time</th>
                            {/* <th className="has-text-centered">Waiting Time</th>
                                  <th className="has-text-centered">Waiting End</th> */}
                          </tr>
                        </thead>
                        <tbody>
                          {
                            getDataAppointmentPatient?.map((appointment, index) => (
                              <tr key={index}>
                                <td style={{ textAlign: "center" }}>{appointment.code}</td>
                                <td style={{ textAlign: "center" }}>{appointment.anamnesa}</td>
                                <td style={{ textAlign: "center" }}>{appointment.doctor_data?.user_detail?.firstname} {appointment.doctor_data?.user_detail?.lastname}</td>
                                <td style={{ textAlign: "center" }}>{appointment.admin_name?.user_detail?.firstname} {appointment.admin_name?.user_detail?.lastname}</td>
                                <td style={{ textAlign: "center" }}>{appointment.date}</td>
                                <td style={{ textAlign: "center" }}>{appointment.time}</td>
                                {/* <td>{appointment.waiting_data?.time_start}</td> */}
                                {/* <td>
                                        {appointment.waiting_data?.time_finish ? appointment.waiting_data?.time_finish : 
                                          <>
                                            <div
                                              className="d-flex align-items-center justify-content-center rounded bg-warning text-light"
                                              style={{ padding: "0 7px", width: "120px", height: "20px" }}
                                            >
                                              <div className="d-flex align-items-center me-2">
                                                <FaClock />
                                              </div>
                                              <div className="d-flex align-items-center">
                                                Not finished yet
                                              </div>
                                            </div>
                                          </>
                                        }
                                      </td> */}
                              </tr>
                            ))
                          }
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      </Col>
    </div>
  );
}
