import React, { useContext } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form } from "reactstrap";
import { FaTimes } from "react-icons/fa";
import InputField from "./InputField";
import { SettingGeneralContext, SettingGeneralProvider } from "../../context/settingGeneralContext";
import SelectInputField from "./SelectInputField";


export default function ModalAddReusable({
    isOpen,
    isClose,
    handleSubmit,
    handleChange,
    fields = [],
    title
}) {
    return (
        <SettingGeneralProvider>
            <ModalAddReusableContent
                isOpen={isOpen}
                isClose={isClose}
                handleSubmit={handleSubmit}
                handleChange={handleChange}
                fields={fields}
                title={title}
            />
        </SettingGeneralProvider>
    );
};

function ModalAddReusableContent({
    isOpen,
    isClose,
    handleSubmit,
    handleChange,
    fields,
    title
}) {
    const { state } = useContext(SettingGeneralContext);
    const appName = state?.generalSettings?.app_name;
    const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
    const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
    const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
    const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
    const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;

    const backgroundColor = appSidebarColor1 ?? "#29B8FF";
    const buttonColor = appSidebarColor2 ?? "#3d64ff";

    return (
        <>
            <Modal show={isOpen} onHide={isClose} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
                <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: backgroundColor }}>
                    <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
                        {title}
                    </div>
                    <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
                        <FaTimes onClick={isClose} style={{ cursor: "pointer" }} />
                    </div>
                </div>
                <Modal.Body style={{ borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
                    <Form onSubmit={handleSubmit} className="mt-3">
                        {fields.map((field, index) => (
                            field.type === "select" ? (
                                <SelectInputField
                                    key={index}
                                    label={field.label}
                                    name={field.name}
                                    value={field.value}
                                    onChange={handleChange}
                                    options={field.options || []}
                                />
                            ) : (
                                <InputField
                                    key={index}
                                    label={field.label}
                                    name={field.name}
                                    value={field.value}
                                    onChange={handleChange}
                                    type={field.type || "text"}
                                />
                            )
                        ))}

                        <div style={{ display: "flex", justifyContent: "end" }}>
                            <Button className="mt-4" type="submit" style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", backgroundColor: buttonColor }}>
                                Tambahkan
                            </Button>
                        </div>
                    </Form>
                </Modal.Body >
            </Modal>
        </>
    );
}