import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import ModalUpdateReusable from "../../Global/ModalUpdate";

export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getData, setGetData] = useState([]);
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  // console.log(props)
  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    code_skri: "",
    name_str: "",
    name_sab: "",
  });

  useEffect(() => {
    setForm({
      ...form,
      code_skri: `${props?.codeSkri}`,
      name_str: `${props?.nameStr}`,
      name_sab: `${props?.nameSab}`,
    });
  }, [props])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const mutation = useMutation(async (formData) => {
    setLoading(true)
    try {
      // Insert data for login process
      const response = await API.put(`/user-service/diagnosa-patients/${props.id}`,
        formData,
        fetchParams
      );

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.GetResponseData()
        props.onHide()
        setLoading(false)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false)
    }
  });


  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(form);
  };

  return (
    <div>
      {loading && <LoaderAction />}
      {/* <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
        <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
          <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
            Update Persentase Assurance
          </div>
          <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
            <FaTimes onClick={() => setProopsData(props.onHide)} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
          <Form onSubmit={(e) => handleSubmit.mutate(e)} className="mt-3" >
            <div className="mt-2" style={{ display: "flex" }}>
              <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                Code SKRI
              </div>
              <div style={{ flex: "60%", display: "flex" }}>
                <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                  <input autoFofcus placeholder="Masukan code skri" type='text' value={form?.code_skri} name="code_skri" onChange={handleChange} style={{ width: "100%", height: "100%", paddingLeft: "0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "1px solid #B6B6B6", outline: "none" }} />
                </div>
              </div>
            </div>

            <div className="mt-2" style={{ display: "flex" }}>
              <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                Nama Diagnosa
              </div>
              <div style={{ flex: "60%", display: "flex" }}>
                <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                  <input autoFofcus placeholder="Masukan nama diagnosa" type='text' value={form?.name_str} name="name_str" onChange={handleChange} style={{ width: "100%", height: "100%", paddingLeft: "0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "1px solid #B6B6B6", outline: "none" }} />
                </div>
              </div>
            </div>

            <div className="mt-2" style={{ display: "flex" }}>
              <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>
                Nama SAB
              </div>
              <div style={{ flex: "60%", display: "flex" }}>
                <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                  <input autoFofcus placeholder="Masukan nama sab" type='text' value={form?.name_sab} name="name_sab" onChange={handleChange} style={{ width: "100%", height: "100%", paddingLeft: "0px", borderTop: "none", borderRight: "none", borderLeft: "none", borderBottom: "1px solid #B6B6B6", outline: "none" }} />
                </div>
              </div>
            </div>

            <div style={{ padding: "0px 0px", marginTop: "0px", display: "flex", justifyContent: "end" }}>
              <div>
                <Button className="mt-4" type='submit' color='primary' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px" }}>
                  Update
                </Button>
              </div>
            </div>
          </Form>
        </Modal.Body>
      </Modal> */}
      <ModalUpdateReusable
        isOpen={props?.show}
        isClose={props?.onHide}
        form={form}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={[
          { label: "Kode SKRI", name: "code_skri", value: form.code_skri },
          { label: "Nama Diagnosa", name: "name_str", value: form.name_str },
          { label: "Nama SAB", name: "name_sab", value: form.name_sab },
        ]}
        title="Update Ruangan"
      />
    </div>
  );
}
