import React, { useContext } from "react";
import { FaSync, FaPlus } from "react-icons/fa";
import { SettingGeneralContext } from "../../context/settingGeneralContext";

const HeaderReusable = ({
  title = "Judul Header",
  icon: Icon,
  isTabletOrMobile = false,
  query,
  setQuery,
  searchData,
  onAdd,
  onRefresh,
  showAddButton = true,
  showRefreshButton = true,
}) => {

  const context = useContext(SettingGeneralContext);
  const state = context?.state || {};
  const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
  const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
  const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
  const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
  const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;


  const commonButtonStyle = {
    fontSize: "12px",
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    borderRadius: "3px",
    padding: "5px 10px",
  };

  const commonInputStyle = {
    backgroundColor: "#E9E9E9",
    border: "none",
    height: "100%",
    padding: "0px 10px",
  };

  return (
    <div
      style={{
        width: "100%",
        borderBottom: "5px solid #EEEEEE",
        display: "flex",
        padding: isTabletOrMobile ? "0px 0px 10px 0px" : "10px 0px",
      }}
    >
      {/* Kolom Judul */}
      <div
        style={{
          flex: isTabletOrMobile ? "none" : "50%",
          fontSize: "16px",
          display: "flex",
          alignItems: "center",
          paddingLeft: isTabletOrMobile ? "0" : "10px",
          color: appSidebarColor4 ?? "#001F8B",
          justifyContent: isTabletOrMobile ? "center" : "flex-start",
          backgroundColor: isTabletOrMobile ? "#E9EFFF" : "transparent",
        }}
      >
        {Icon && <Icon style={{ marginRight: "5px" }} />}
        {title}
      </div>

      {/* Kolom Tombol dan Form Pencarian */}
      <div style={{ flex: "50%", display: "flex", justifyContent: "end", paddingRight: "10px" }}>
        {showAddButton && (
          <div
            onClick={onAdd}
            style={{
              ...commonButtonStyle,
              backgroundColor: appSidebarColor2 ?? "#3D64FF",
              color: "white",
              marginRight: "5px",
            }}
          >
            <FaPlus />
          </div>
        )}
        {showRefreshButton && (
          <div
            onClick={onRefresh}
            style={{
              ...commonButtonStyle,
              backgroundColor: "white",
              border: `1px solid ${appSidebarColor1}` ??  `1px solid #DEDEDE`,
              color: "#3D64FF",
              marginRight: "5px",
            }}
          >
            <FaSync style={{ fontSize: "15px", color: `${appSidebarColor2}` }} />
          </div>
        )}

        {/* Form Pencarian */}
        {searchData && (
          <form onSubmit={searchData} style={{ display: "flex", paddingRight: isTabletOrMobile ? "0px" : "10px" }}>
            <input
              value={query}
              onChange={(e) => setQuery(e.target.value)}
              className="focused"
              style={commonInputStyle}
              type="text"
              placeholder="Cari..."
            />
            <button
              type="submit"
              style={{
                ...commonButtonStyle,
                backgroundColor: appSidebarColor3 ?? "#3D64FF",
                color: "white",
                fontWeight: "600",
                padding: "0px 10px",
              }}
            >
              Search
            </button>
          </form>
        )}
      </div>
    </div>
  );
};

export default HeaderReusable;
