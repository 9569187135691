// import React from 'react';
// import { Col } from 'reactstrap';
// import { FaAddressBook, FaPlus, FaSync } from 'react-icons/fa';
// import { useMediaQuery } from 'react-responsive';

// const HeaderRoom = ({ viewModalAdd, buttonRefresh, searchData, query, setQuery }) => {
//   const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' });

//   return (
//     <div
//       style={{
//         paddingLeft: '0px',
//         width: '100%',
//         borderBottom: '5px solid #EEEEEE',
//         display: '',
//         padding: isTabletOrMobile ? '0px 0px 10px 0px' : '10px 0px',
//       }}
//     >
//       {isTabletOrMobile ? (
//         <div style={{ paddingLeft: '0px', width: '100%', borderBottom: '5px solid #EEEEEE', display: '', padding: '0px 0px 10px 0px' }}>
//           <Col
//             xl="6"
//             style={{
//               fontSize: '16px',
//               display: 'flex',
//               justifyContent: 'center',
//               alignItems: 'center',
//               padding: '7px',
//               color: '#001F8B',
//               backgroundColor: '#E9EFFF',
//             }}
//           >
//             <FaAddressBook style={{ marginRight: '5px' }} />
//             List Room
//           </Col>
//           <Col className="mt-2" xl="6" style={{ display: 'flex', justifyContent: 'end', paddingRight: '5px' }}>
//             <div
//               onClick={viewModalAdd}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '5px',
//                 backgroundColor: '#3D64FF',
//                 color: 'white',
//                 padding: '0px 12px',
//                 borderRadius: '3px',
//                 cursor: 'pointer',
//                 fontSize: '12px',
//               }}
//             >
//               <FaPlus />
//             </div>
//             <div
//               onClick={buttonRefresh}
//               style={{
//                 height: '100%',
//                 marginRight: '5px',
//                 paddingTop: '0px',
//                 backgroundColor: 'white',
//                 padding: '10px 10px',
//                 borderRadius: '2px',
//                 cursor: 'pointer',
//                 border: '1px solid #DEDEDE',
//               }}
//             >
//               <FaSync style={{ fontSize: '15px', marginRight: '0px', marginTop: '0px', display: 'flex', alignItems: 'center', height: '100%', color: '#3D64FF' }} />
//             </div>
//             <form onSubmit={searchData} style={{ display: 'flex', paddingRight: '0px' }}>
//               <div style={{ marginRight: '5px', borderRadius: '3px' }}>
//                 <input
//                   value={query}
//                   onChange={(e) => setQuery(e.target.value)}
//                   className="focused"
//                   style={{ backgroundColor: '#E9E9E9', border: 'none', height: '100%' }}
//                   type="text"
//                   placeholder="Cari ruangan ..."
//                 />
//               </div>
//               <button
//                 type="submit"
//                 style={{
//                   border: 'none',
//                   backgroundColor: '#3D64FF',
//                   color: 'white',
//                   display: 'flex',
//                   borderRadius: '2px',
//                   fontWeight: '600',
//                   fontSize: '12px',
//                   alignItems: 'center',
//                   padding: '0px 10px',
//                 }}
//               >
//                 Search
//               </button>
//             </form>
//           </Col>
//         </div>
//       ) : (
//         <div style={{ paddingLeft: '0px', width: '100%', borderBottom: '5px solid #EEEEEE', display: 'flex', padding: '10px 0px' }}>
//           <div
//             style={{
//               flex: '50%',
//               fontSize: '16px',
//               display: 'flex',
//               alignItems: 'center',
//               paddingLeft: '10px',
//               color: '#001F8B',
//             }}
//           >
//             <FaAddressBook style={{ marginRight: '5px' }} />
//             List Room
//           </div>
//           <div style={{ flex: '50%', display: 'flex', justifyContent: 'end' }}>
//             <div
//               onClick={viewModalAdd}
//               style={{
//                 display: 'flex',
//                 alignItems: 'center',
//                 marginRight: '5px',
//                 backgroundColor: '#3D64FF',
//                 color: 'white',
//                 padding: '0px 10px',
//                 borderRadius: '3px',
//                 cursor: 'pointer',
//                 fontSize: '12px',
//               }}
//             >
//               <FaPlus />
//             </div>
//             <div
//               onClick={buttonRefresh}
//               style={{
//                 height: '100%',
//                 marginRight: '5px',
//                 paddingTop: '0px',
//                 backgroundColor: 'white',
//                 padding: '0px 10px',
//                 border: '1px solid #DEDEDE',
//                 borderRadius: '3px',
//                 cursor: 'pointer',
//               }}
//             >
//               <FaSync style={{ fontSize: '15px', marginRight: '0px', marginTop: '0px', display: 'flex', alignItems: 'center', height: '100%', color: '#3D64FF' }} />
//             </div>
//             <form onSubmit={searchData} style={{ display: 'flex', paddingRight: '10px' }}>
//               <div style={{ marginRight: '5px', borderRadius: '3px' }}>
//                 <input
//                   value={query}
//                   onChange={(e) => setQuery(e.target.value)}
//                   className="focused"
//                   style={{ backgroundColor: '#E9E9E9', border: 'none', height: '5vh' }}
//                   type="text"
//                   placeholder="Cari ruangan ..."
//                 />
//               </div>
//               <button
//                 type="submit"
//                 style={{
//                   border: 'none',
//                   backgroundColor: '#3D64FF',
//                   color: 'white',
//                   display: 'flex',
//                   borderRadius: '3px',
//                   fontWeight: '600',
//                   fontSize: '12px',
//                   alignItems: 'center',
//                   padding: '0px 10px',
//                 }}
//               >
//                 Search
//               </button>
//             </form>
//           </div>
//         </div>
//       )}
//     </div>
//   );
// };

// export default HeaderRoom;

// // Clear

import React, { useContext } from 'react';
import { FaAddressBook, FaBuilding, FaPills, FaPlus, FaSync } from 'react-icons/fa';
import HeaderReusable from '../../Global/HeaderContent';
import { MdDoorSliding } from "react-icons/md";
import { SettingGeneralContext, SettingGeneralProvider } from '../../../context/settingGeneralContext';


export default function HeaderRoom({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh
}) {
  return (
    <SettingGeneralProvider>
      <HeaderRoomContent
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        viewModalAdd={viewModalAdd}
        buttonRefresh={buttonRefresh}
      />
    </SettingGeneralProvider>
  )
}


function HeaderRoomContent({
  isTabletOrMobile,
  query,
  setQuery,
  searchData,
  viewModalAdd,
  buttonRefresh
}) {
  return (
    <>
      <HeaderReusable
        title="List Ruangan"
        icon={MdDoorSliding}
        isTabletOrMobile={isTabletOrMobile}
        query={query}
        setQuery={setQuery}
        searchData={searchData}
        onAdd={viewModalAdd}
        onRefresh={buttonRefresh}
        showAddButton={true}
        showRefreshButton={true}
      />
    </>
  )
}



