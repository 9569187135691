import {  useState } from "react";
// import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
// import {Row,Col,Form,Input,Label,Button,CardText,CardTitle,FormFeedback,UncontrolledTooltip} from 'reactstrap'
// import { useMediaQuery } from 'react-responsive'
import { API } from "../../../config/api";
import { FaTimes, FaCamera, FaCheckCircle, FaUndo} from 'react-icons/fa'
// import ReactPaginate from "react-paginate";
// import logo_side from "../../../assets/signature/logo-side-detail.png"
// import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
// import logoCamera from "../../../assets/signature/logo-camera.png"
import { useMutation } from "react-query";
// import "bulma/css/bulma.css";
// import "../Styled.css"
import { Modal, Form, Button } from "react-bootstrap";
// import Swal from "sweetalert2";
import swal from "sweetalert";
// import { FadeLoader, HashLoader, ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"

export default function ModalRoleAdd(props) {
  const navigate = useNavigate()
  // const [page, setPage] = useState(0);
  // const [limit, setLimit] = useState(10);
  // const [pages, setPages] = useState();
  // const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  // // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [preview, setPreview] = useState(null);
  const [loading, setLoading] = useState(false);
  const [imageName, setImageName] = useState([])
  const [imageContent, setImageContent] = useState([])
  
  let fetchParams = {
    headers : {"Authorization" : `${token}`,"Content-Type" : "application/json"}
  };

  const [form, setForm] = useState({
    image_name : imageName,
    image_content: imageContent,
 
  });

  const handleChange = async (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === "file" ? e.target.files : e.target.value,
    });

    if (e.target.type === "file") {
      const file = e.target.files[0]
      
      const base64 = await convertBase64(file)
      setPreview(base64)
      setImageName(e.target.files[0].name)
      setImageContent(base64)
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleSubmit = useMutation(async (e) => {
    try {
      e.preventDefault();
      setLoading(true);
      const response = await API.put(`transaction-service/patients/${props?.getData?.id}`, 
        {
          content: imageContent,
          filename: imageName,
          first_name: props?.getData?.first_name,
          last_name: props?.getData?.last_name,
          gender: props?.getData?.gender,
          date_birth: props?.getData?.date_birth,
          nic_number: props?.getData?.nic_number,
          phone_number: props?.getData?.phone_number,
        }, 
      fetchParams
    );

    
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });
    
        props.GetResponseData();
        props.onHide();
        // window.location.reload();
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response?.data?.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false); 
    }
  });

  

  const resetUpload = () => {
    setImageName([])
    setImageContent([])
    setPreview(null)
  }


  return (
  <div>
    {loading && <LoaderAction/>}
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>  
      <div style={{width: "100%",display:"flex",padding:"10px 0px", backgroundColor:"#29B8FF"}}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Upload your profile photo
        </div> 
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none",backgroundImage:"transparent" }}>
      <Form onSubmit={(e) => handleSubmit.mutate(e)} className="" >
    
        <div style={{display:"flex", justifyContent:"center",  padding:"30px 0px"}}>
          {preview?
          <>
            <img src={preview} style={{minWidth:"320px",maxWidth:"320px", minHeight:"300px", maxHeight:"300px", }}/>
          </>
            :
          <div style={{ display: "flex", justifyContent: "center" }}>
            <input type="file" id="nic_file" name="nic_file" onChange={handleChange} accept="image/*" hidden/>
            <label for="nic_file" style={{border:"none", backgroundColor:"#99CCFF", color:"white", padding:"50px 100px", cursor:"pointer", borderRadius:"5px"}}>
                <FaCamera style={{fontSize:"200px"}}/>
            </label>  
          </div>
          }
        </div>
        {preview ?
          <div style={{ padding: "0px 0px", marginTop: "0px", display: "flex", justifyContent: "center" }}>
            <div style={{ marginRight:"10px" }}>
              <Button 
              onClick={resetUpload} 
              color='' block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", backgroundColor:"#FF4E4E", color:"white"  }}>
                <FaUndo/> Reset
              </Button>
            </div>
            <div>
              <Button className="" type='submit'  block style={{ padding: "8px 10px", fontSize: "12px", borderRadius: "5px", backgroundColor:"#00DA0F", border:"none" }}>
                Upload <FaCheckCircle/>
              </Button>
            </div>
          </div>
          :
          <div></div>
        }
        </Form>
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
   
      
    </Modal>
    </div>
   
    );
}
  