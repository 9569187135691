import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import ModalUpdateReusable from "../../Global/ModalUpdate";

export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getData, setGetData] = useState([]);
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  
  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    name: "",
    code: "",
    type: "",
    price: "",
    description: ""
  });


  const GetResponseData = async () => {
    try {
      setLoading(true);
      const response = await API.get(`emr-service/medical-item-types?page=${page}&limit=${limit}&ascending=${ascending}`, fetchParams)

      if (response.status === 200) {
        setLoading(false)
        setGetData(response.data.data)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  }

  useEffect(() => {
    GetResponseData()
  }, [])


  useEffect(() => {
    if (props.show) {
      setForm({
        name: props.nameUpdate || "",
        code: props.codeUpdate || "",
        type: props.typeUpdate || "",
        price: props.priceUpdate || "",
        description: props.description || ""
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.show,
    props.nameUpdate,
    props.codeUpdate,
    props.typeUpdate,
    props.priceUpdate,
    props.descriptionUpdate
  ]);



  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };


  const mutation = useMutation(async (formData) => {
    setLoading(true);
    try {
      const response = await API.put(
        `emr-service/medical-items/${props.id}`,
        formData,
        fetchParams
      );

      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.GetResponseData();
        props.onHide();
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: error.response?.data?.message || "An error occurred",
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(form);
  };


  return (
    <>
      {loading && <LoaderAction />}
      <ModalUpdateReusable
        isOpen={props?.show}
        isClose={props?.onHide}
        form={form}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={[
          { label: "Nama Obat", name: "name", value: form.name },
          { label: "Kode Obat", name: "code", value: form.code },
          {
            label: "Tipe Obat",
            name: "type",
            value: form.type,
            type: "select",
            options: getData.map((item) => ({
              value: item.id,
              label: item.name
            }))
          },
          { label: "Harga Obat", name: "price", value: form.price },
          { label: "Deskripsi Obat", name: "description", value: form.description },
        ]}
        title="Update Obat"
      />

    </>
  );
}
