import React, { useContext } from "react";
import { SettingGeneralContext } from "../../context/settingGeneralContext";
import ReactPaginate from "react-paginate";

export default function TableReusable({
    columns,
    data,
    levelUser = null,
    rows,
    page,
    pages,
    changePage,
    msg
}) {

    const context = useContext(SettingGeneralContext);
    const state = context?.state || {};


    const appName = state?.generalSettings?.app_name;
    const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
    const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
    const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
    const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
    const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;

    // Definisi Style di atas
    const styles = {
        // Start Table
        tableContainer: {
            display: "block",
            height: "100%",
            overflowY: "auto",
            overflowX: "auto",
        },
        tableHeader: {
            backgroundColor: appSidebarColor1 || "#E9EFFF",
        },
        tableHeaderCell: {
            fontFamily: "revert",
            fontSize: "12px",
            textAlign: "center",
            color: "#525252",
            border: "none",
        },
        tableRow: {
            fontFamily: "sans-serif",
            fontSize: "11px",
            textAlign: "center",
        },
        statusButton: {
            display: "flex",
            border: "none",
            padding: "0px 7px",
            borderRadius: "3px",
            color: "white",
            fontWeight: "bold",
            width: "120px",
            justifyContent: "center",
        },
        newAppointment: { backgroundColor: "#F0A800" },
        arrangement: { backgroundColor: "#3D64FF" },
        waitingList: { backgroundColor: "#FF6661" },
        treatmentPlan: { backgroundColor: "#00d4cd" },
        createInvoice: { backgroundColor: "#A9A9A9" },
        uploadPayment: { backgroundColor: "#AB00D5" },
        paid: { backgroundColor: "#96FF86" },


        statusContainer: {
            display: "flex",
            justifyContent: "center",
        },

        waiting: { backgroundColor: "#3D64FF" },
        completed: { backgroundColor: "#96FF86" },
        icon: {
            display: "flex",
            alignItems: "center",
            marginRight: "5px",
        },
        // End Table

        // Start Pagination
        paginationLink: {
            backgroundColor: "red",
            color: "white",
            borderRadius: "5px",
            padding: "3px 6px",
            fontSize: "10px",
            width: "25px",
            height: "25px",
            margin: "2px",
            textAlign: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
        }
        // End Pagination
    };


    return (
        <>
            <table className="table is-bordered">
                <thead>
                    <tr style={styles.tableHeader}>
                        {columns.map((col, index) => (
                            (col.key !== "action" || levelUser !== "developer") && (
                                <th key={index} style={styles.tableHeaderCell}>{col.label}</th>
                            )
                        ))}
                    </tr>
                </thead>
                <tbody>
                    {data?.map((row, index) => (
                        <tr key={index} style={styles.tableRow}>
                            {columns.map((col, idx) => (
                                (col.key !== "action" || levelUser !== "developer") && (
                                    <td key={idx}>
                                        {col.render ? col.render(row, index) : row[col.key]}
                                    </td>
                                )
                            ))}
                        </tr>
                    ))}
                </tbody>
            </table>
            <div style={{ display: "flex", padding: "10px" }}>
                <div>
                    <div style={{ fontSize: "12px" }}>
                        Total Rows: {rows}
                    </div>
                    <div style={{ fontSize: "12px" }}>
                        Page: {rows ? page : 0} of {pages}
                    </div>
                    <p className="has-text-centered has-text-danger">{msg}</p>
                </div>
                <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
                    <nav
                        style={{ fontSize: "12px" }}
                        className="pagination is-centered"
                        key={rows}
                        role="navigation"
                        aria-label="pagination"
                    >
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            pageCount={Math.min(10, pages)}
                            onPageChange={changePage}
                            containerClassName={"pagination-list"}
                            pageLinkClassName={"pagination-link"}
                            previousLinkClassName={"pagination-previous"}
                            nextLinkClassName={"pagination-next"}
                            activeLinkClassName={"pagination-link is-current"}
                            disabledLinkClassName={"pagination-link is-disabled"}
                        />
                    </nav>
                </div>
            </div>
        </>

    );
}
