import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTimes } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ScaleLoader } from "react-spinners";
import LoaderAction from "../../Loader/LoaderAction"
import ModalUpdateReusable from "../../Global/ModalUpdate";

export default function ModalRoleUpdate(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getData, setGetData] = useState([]);
  const [getDataFloor, setGetDataFloor] = useState([]);

  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const [form, setForm] = useState({
    name: "",
    id_floor: "",
    idFloor: ""
  });

  const GetResponseDataFloors = async () => {
    try {
      setLoading(true);
      const response = await API.get(`transaction-service/floors?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)

      if (response.status === 200) {
        setLoading(false)
        setGetDataFloor(response.data.data)
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  }

  useEffect(() => {
    GetResponseDataFloors()
  }, [])



  useEffect(() => {
    if (props.show) {
      setForm({
        name: props?.nameUpdate || "",
        id_floor: props?.id || "",
        idFloor: props?.idFloorUpdate
      });
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    props.show,
    props?.nameUpdate,
    props?.floorUpdate,
    props?.idFloorUpdate
  ])

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };


  const mutation = useMutation(async (formData) => {
    setLoading(true)
    try {
      // Insert data for login process
      const response = await API.put(`transaction-service/rooms/${props.id}`,
        formData,
        fetchParams
      );

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 3000,
          buttons: false
        });
        props.GetResponseData()
        props.onHide()
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false)
    }
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    mutation.mutate(form);
  };

  return (
    <div>
      {loading && <LoaderAction />}
      <ModalUpdateReusable
        isOpen={props?.show}
        isClose={props?.onHide}
        form={form}
        handleSubmit={handleSubmit}
        handleChange={handleChange}
        fields={[
          { label: "Nama Ruangan", name: "name", value: form.name },
          {
            label: "Lantai gedung",
            name: "id_floor",
            value: form?.idFloor,
            type: "select",
            options: getDataFloor?.map((item) => ({
              value: item.id,
              label: item.name
            }))
          },
        ]}
        title="Update Ruangan"
      />
    </div>
  );
}
