import React, { useState, useEffect } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import swal from "sweetalert";

const ModalCustomizeSidebar = ({
    show,
    handleClose,
    handleSubmit,
    formData,
    setFormData,
    loading,
}) => {
    const [sidebarColors, setSidebarColors] = useState({
        name_theme: "",
        app_sidebar_color_1: "#000000",
        app_sidebar_color_2: "#000000",
        app_sidebar_color_3: "#000000",
        app_sidebar_color_4: "#000000",
        app_sidebar_color_5: "#000000",
    });

    useEffect(() => {
        if (formData) {
            setSidebarColors({
                name_theme: formData.name_theme || "",
                app_sidebar_color_1: formData.app_sidebar_color_1 || "#000000",
                app_sidebar_color_2: formData.app_sidebar_color_2 || "#000000",
                app_sidebar_color_3: formData.app_sidebar_color_3 || "#000000",
                app_sidebar_color_4: formData.app_sidebar_color_4 || "#000000",
                app_sidebar_color_5: formData.app_sidebar_color_5 || "#000000",
            });
        }
    }, [formData]);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setSidebarColors((prev) => ({
            ...prev,
            [name]: value,
        }));
        setFormData((prev) => ({
            ...prev,
            [name]: value,
        }));
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await handleSubmit(); 
            if (response?.status === 200) {
                swal({
                    title: "Berhasil",
                    text: "Data berhasil disimpan!",
                    icon: "success",
                    timer: 3000,
                    buttons: false,
                });
                handleClose();
            }
        } catch (error) {
            swal({
                title: "Gagal",
                text: error.response?.data?.message || "Terjadi kesalahan saat menyimpan data",
                icon: "error",
                timer: 3000,
                buttons: false,
            });
        }
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg" centered>
            <Modal.Header closeButton>
                <Modal.Title>Customize Color Sidebar</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleFormSubmit}>
                    {Object.keys(sidebarColors).map((key) => (
                        <Form.Group className="mb-3" key={key}>
                            <Form.Label>{key.replace(/_/g, " ")}</Form.Label>
                            <Form.Control
                                type={key === "name_theme" ? "text" : "color"}
                                name={key}
                                value={sidebarColors[key]}
                                onChange={handleChange}
                            />
                        </Form.Group>
                    ))}
                    <Button type="submit" variant="primary" className="float-end" disabled={loading}>
                        {loading ? "Menyimpan..." : "Simpan"}
                    </Button>
                </Form>
            </Modal.Body>
        </Modal>
    );
};

export default ModalCustomizeSidebar;
