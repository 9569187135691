import React from "react";


const InputField = ({ label, name, value, onChange, type = "text", autoFocus = false }) => {
    return (
        <div className="mt-2" style={{ display: "flex" }}>
            <div style={{ flex: "5%", display: "flex", alignItems: "center" }}>{label}</div>
            <div style={{ flex: "60%", display: "flex" }}>
                <p style={{ marginRight: "10px", display: "flex", alignItems: "center", height: "100%" }}>:</p>
                <div style={{ width: "100%", outline: "none", padding: "10px 0px" }}>
                    <input
                        autoFocus={autoFocus}
                        type={type}
                        value={value}
                        name={name}
                        onChange={onChange}
                        style={{
                            width: "100%",
                            height: "100%",
                            paddingLeft: "0px",
                            borderTop: "none",
                            borderRight: "none",
                            borderLeft: "none",
                            borderBottom: "1px solid #B6B6B6",
                            outline: "none",
                        }}
                    />
                </div>
            </div>
        </div>
    );
};

export default InputField;
