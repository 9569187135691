import React, { useContext } from "react";
import { FaEdit, FaTrash } from "react-icons/fa";
import TableReusable from "../../Global/Table";
import { SettingGeneralContext, SettingGeneralProvider } from "../../../context/settingGeneralContext";


export default function TableMedicalItem({
  getData,
  formatToRupiah,
  rows,
  page,
  pages,
  changePage,
  msg,
  viewModalUpdate,
  deleteById
}) {
  return (
    <SettingGeneralProvider>
      <TableMedicalItemContent
        getData={getData}
        formatToRupiah={formatToRupiah}
        rows={rows}
        page={page}
        pages={pages}
        changePage={changePage}
        msg={msg}
        viewModalUpdate={viewModalUpdate}
        deleteById={deleteById}
      />
    </SettingGeneralProvider>
  )
}

function TableMedicalItemContent({
  getData,
  formatToRupiah,
  rows,
  page,
  pages,
  changePage,
  msg,
  viewModalUpdate,
  deleteById
}) {
  const getRowNumber = (index) => (page - 1) * 10 + index + 1;
  return (
    <>
      <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
        <TableReusable
          columns={[
            { key: "no", label: "No", render: (_, index) => getRowNumber(index) },
            { key: "name", label: "Nama Obat" },
            { key: "type_name", label: "Satuan Obat" },
            { key: "price", label: "Harga Obat", render: (price) => formatToRupiah(price?.price) },
            { key: "description", label: "Deskripsi Obat" },
            {
              key: "action",
              label: "Setup",
              render: (user) => (
                <div style={{ display: "flex", justifyContent: "center", alignItems: "center", gap: "5px" }}>
                  <button
                    onClick={() => viewModalUpdate(user?.id, user?.name, user?.code, user?.type, user?.price, user?.description)}
                    style={{
                      fontSize: "12px",
                      color: "white",
                      backgroundColor: "#ff9933",
                      padding: "5px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                      border: "none",
                      display: "flex"
                    }}>
                    <FaEdit />
                  </button>
                  <button
                    onClick={() => deleteById(user?.id)}
                    style={{
                      fontSize: "12px",
                      color: "white",
                      backgroundColor: "#B60000",
                      padding: "5px 5px",
                      borderRadius: "3px",
                      cursor: "pointer",
                      border: "none",
                      display: "flex"
                    }}>
                    <FaTrash />
                  </button>
                </div>
              ),
            },
          ]}
          data={getData}
          rows={rows}
          page={page}
          pages={pages}
          changePage={changePage}
          msg={msg}
        />

      </div>
    </>
  )

}

