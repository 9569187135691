import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../context/userContext";
import { useNavigate, useParams } from "react-router-dom";
import { Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaClosedCaptioning, FaTimes } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import Modal from 'react-bootstrap/Modal';
import logo_side from "../../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../../assets/signature/signatureNewIcon.png"
import Logo_Batik from "../../../assets/signature/logo-batik.png"
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import "../Styled.css"
import Swal from "sweetalert2";
import LoaderHome from "../../Loader/LoaderHome"
import swal from "sweetalert";
import PatientRelationTable from "./Components/TableRelation";
import React, { useRef } from 'react';
import axios from "axios";
import Select from 'react-select';

export default function ModalPatientRelation(props) {
  const navigate = useNavigate()
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [msg, setMsg] = useState("");
  const [ascending, setAscending] = useState(0);
  const token = localStorage.getItem("token");
  const [getDataDokter, setGetDataDokter] = useState([]);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [loading, setLoading] = useState(false);
  const [getOpenAdd, setGetOpenAdd] = useState(false);
  const [getOpenEdit, setGetOpenEdit] = useState(false);
  const [getDataId, setGetDataId] = useState();
  const [getData, setGetData] = useState([]);
  const [getDataById, setGetDataById] = useState();

  const [rows, setRows] = useState(1);

  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const firstNameInputRef = useRef(null);
  const [search, setSearch] = useState("")
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };


  const [form, setForm] = useState({
    name: "",
    color: ""
  });

  const [formData, setFormData] = useState({
    name: "",
    color: "",
  });


  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };


  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/transaction-service/patients/relation/index?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseEditData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/transaction-service/patients/relation/${getDataId}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataById(response.data.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()
    if (getDataId) {
      GetResponseEditData()
    }
  }, [getDataId, page, limit, ascending, keyword])

  useEffect(() => {
    if (getDataById) {
      setFormData({
        name: getDataById.name || "",
        color: getDataById.color || "#563d7c",
      });
    }
  }, [getDataById]);


  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)

      // Insert data for login process
      const response = await API.post("transaction-service/patients/relation/store", {
        name: form?.name,
        color: form?.color,
      }, fetchParams);

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        setForm({
          ...form,
          name: "",
          color: "",
        });
        GetResponseData()
        setGetOpenAdd(false)
        setGetOpenEdit(false)
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  };


  const handleUpdate = async (e) => {
    e.preventDefault();
    try {
      setLoading(true);

      const response = await API.put(`transaction-service/patients/relation/${getDataId}`, {
        name: formData?.name,
        color: formData?.color,
      }, fetchParams);

      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        });

        setFormData({ name: "", color: "" });
        setGetOpenEdit(false);
        setGetDataId(null);
        props?.GetResponseData()
      }
    } catch (error) {
      swal({
        title: 'Failed',
        text: error.response?.data?.message || "Terjadi kesalahan",
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteById = async (id, event) => {
    if (event) event.preventDefault();
  
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const response = await API.delete(`transaction-service/patients/relation/${id}`, fetchParams);
  
          if (response.data.status === 200) {
            Swal.fire({ 
              title: 'Success',
              text: "Your data has been successfully deleted",
              icon: 'success',
              timer: 3000,
              showConfirmButton: false
            });
            GetResponseData()
          } else {
            Swal.fire({
              title: 'Error',
              text: "Gagal menghapus data",
              icon: 'error'
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: "Terjadi kesalahan saat menghapus data",
            icon: 'error'
          });
          console.error("Delete Error:", error);
        }
      }
    });
  };
  

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const openAddField = () => {
    setGetOpenAdd(true)
    setGetOpenEdit(false)
  }

  const openEditField = (id) => {
    setGetOpenEdit(true)
    setGetOpenAdd(false)
    setGetDataId(id)
  }

  const cancelAddField = () => {
    setGetOpenAdd(false)
  }

  return (
    <Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
      {loading && <LoaderHome />}

      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
          Hubungan Pasien
        </div>
        <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
          <FaTimes onClick={props.onHide} style={{ cursor: "pointer" }} />
        </div>
      </div>

      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none" }}>
        <Form className="mt-" >
          <Col xl='12' sm='12'>
            <div>
              <div style={{ display: "block", height: "100%", overflowY: "auto", overflowX: "auto" }}>
                <div >
                  <PatientRelationTable
                    getData={getData}
                    page={page}
                    getOpenEdit={getOpenEdit}
                    getDataId={getDataId}
                    openEditField={openEditField}
                    deleteById={deleteById}
                    getOpenAdd={getOpenAdd}
                    form={form}
                    handleChange={handleChange}
                    handleSubmit={handleSubmit}
                    handleUpdate={handleUpdate}
                    cancelAddField={cancelAddField}
                    formData={formData}
                    setGetOpenEdit={setGetOpenEdit}
                    setGetDataId={setGetDataId}
                    openAddField={openAddField}
                    rows={rows}
                    pages={pages}
                    msg={msg}
                    changePage={changePage}
                  />
                </div>
              </div>
            </div>
          </Col>
        </Form>

      </Modal.Body>
    </Modal>
  );
}
