/* eslint-disable react-hooks/exhaustive-deps */
import {  useEffect, useState } from "react";
import { Form,Button } from 'reactstrap'
import { API } from "../../../config/api";
import {FaTimes} from 'react-icons/fa'
import Modal from 'react-bootstrap/Modal';
import { useMutation } from "react-query";
import "bulma/css/bulma.css";
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../../Loader/LoaderHome"
import axios from "axios";
import { useParams } from "react-router-dom";
// generate s

export default function ModalAdminDetail(props) {
  const token = localStorage.getItem("token");
  const [loading, setLoading] = useState(false);
  // Untuk Close Proops Data
  const [propsData, setProopsData] = useState()
  const [getDataCode, setGetCode] = useState()
  const [page, setPage] = useState(1);
  const [limit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending] = useState(0);
  const [getProvince, setGetProvince] = useState([])
  const [getRegency, setGetRegency] = useState([])
  const [getDistrict, setGetDistrict] = useState([])
  const [getSubDistrict, setGetSubDistrict] = useState([])
  const [provinceLoaded, setProvinceLoaded] = useState(false);
  const [cityLoaded, setCityLoaded] = useState(false);
  const [districtLoaded, setDistrictLoaded] = useState(false);
  const [getDataCoba, setGetDataCoba] = useState([])
  const [getDataTokenSatuSehat , setGetDataTokenSatuSehat] = useState([]);
  const {id} = useParams();
  const [errors, setErrors] = useState({});

     
  return (
    <Modal {...props} size="md" aria-labelledby="contained-modal-title-vcenter" centered style={{fontFamily:"sans-serif",border:"none"}}>
      {loading && <LoaderHome />}
      <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
        <div style={{flex:"92%", fontSize:"20px",display:"flex",alignItems:"center", paddingLeft:"10px", color:"white", fontWeight:"600"}}>
          Profile Pasien 
        </div>   
        <div  style={{flex:"8%",fontSize:"30px",display:"flex",alignItems:"center",justifyContent:"center", color:"white"}}>
          <FaTimes onClick={() => setProopsData(props.onHide)} style={{cursor:"pointer"}}/>
        </div> 
      </div>
      <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px",border:"none" }}>
      <div>
          <div>
            <div
              className="card p-4"
              style={{
                maxWidth: "400px",
                margin: "auto",
                borderRadius: "10px",
                boxShadow: "0 4px 8px rgba(0,0,0,0.1)",
                textAlign: "center",
              }}
            >
              {/* Foto Profil */}
              <div className="card-image">
                <figure className="image is-128x128" style={{ margin: "auto" }}>
                  <img
                    src={
                      "https://storageapps.signatureanugerah.co.id/public/images/no-profile.png"
                    }
                    alt="Foto Pasien"
                    style={{ borderRadius: "50%", border: "2px solid #C9C9C9" }}
                  />
                </figure>
              </div>

              {/* Informasi Pasien */}
              <div className="card-content">
                <div className="content">
                  <h3 className="title is-6" style={{ marginBottom: "5px", fontWeight: "bold" }}>
                    <span style={{display: "flex", justifyContent: "center", gap:"3px"}}>
                      {props?.getDetailDataPatient?.first_name} {props?.getDetailDataPatient?.last_name}
                    </span>
                    <p className="subtitle is-7 mt-1" style={{ color: "#666", marginBottom: "10px" }}>
                      Kode Pasien: <strong>{props?.getDetailDataPatient?.code || "N/A"}</strong>
                    </p>
                  </h3>
                  <hr/>
                  <div  style={{ textAlign: "left", fontSize: "14px", lineHeight: "1.6" }}>
                    <p><strong>Jenis Kelamin :</strong> {props?.getDetailDataPatient?.gender === 'male' ? 'Laki-Laki' : 'Perempuan'  || " - "}</p>
                    <p><strong>Tanggal Lahir :</strong> {props?.getDetailDataPatient?.date_birth_id || " - "}</p>
                    <p><strong>Usia :</strong> {props?.getDetailDataPatient?.age || " - "} tahun</p>
                    <p>
                      <strong>Alamat :</strong> 
                      {props?.getDetailDataPatient?.residence_address || " - "}
                    </p>
                    <p><strong>Alamat (KTP) :</strong> {props?.getDetailDataPatient?.nic_address || " - "}</p>
                    <p><strong>Nomor HP :</strong> {props?.getDetailDataPatient?.phone_number || " - "}</p>
                    <p><strong>NIK :</strong> {props?.getDetailDataPatient?.nic_number || " - "}</p>
                    <p><strong>Pekerjaan :</strong> {props?.getDetailDataPatient?.occupation || " - "}</p>
                    <p><strong>No. Rekam Medis :</strong> {props?.getDetailDataPatient?.medical_record_code || " - "}</p>
                    <p><strong>Email :</strong> {props?.getDetailDataPatient?.email || " - "}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
      
        
      </Modal.Body>
      {/* <Modal.Footer>
        <div style={{ display: "flex" }}>
          <Button onClick={props.onHide} style={{marginRight:"8px"}}>Close</Button>
        </div>
      </Modal.Footer> */}
    </Modal>
    );
}
  