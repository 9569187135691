import React from "react";
import { Table, Form, Row, Col, Button } from "react-bootstrap";
import { FaEdit, FaTrash, FaPlus, FaTimes, FaSave } from "react-icons/fa";
import ReactPaginate from "react-paginate";

export default function TableRelation({
    getData,
    page,
    getOpenEdit,
    getDataId,
    openEditField,
    deleteById,
    getOpenAdd,
    form,
    handleChange,
    handleSubmit,
    handleUpdate,
    cancelAddField,
    formData,
    setGetOpenEdit,
    setGetDataId,
    openAddField,
    rows,
    pages,
    msg,
    changePage
}) {
    return (
        <>
            <Table striped bordered hover responsive size="sm" style={{ fontSize: "12px" }}>
                <thead>
                    <tr style={{ backgroundColor: "#E9EFFF", textAlign: "center" }}>
                        <th style={{ width: "5%", textAlign: "center" }}>No</th>
                        <th style={{ width: "40%", textAlign: "center" }}>Hubungan</th>
                        <th style={{ width: "20%", textAlign: "center" }}>Warna</th>
                        <th style={{ width: "20%", textAlign: "center" }}>Setup</th>
                    </tr>
                </thead>
                <tbody>
                    {getData
                        .filter((user) => !getOpenEdit || getDataId === user?.id)
                        .map((user, index) => (
                            <tr key={index} style={{ textAlign: "center" }}>
                                <td>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}</td>
                                <td>{user?.name}</td>
                                <td>
                                    <div
                                        style={{
                                            width: "15px",
                                            height: "15px",
                                            backgroundColor: user?.color,
                                            border: "1px solid #000",
                                            borderRadius: "4px",
                                            margin: "auto",
                                        }}
                                    ></div>
                                </td>
                                <td>
                                    <div style={{ display: "flex", gap: "2px", justifyContent: "center" }}>
                                        {!getOpenEdit && (
                                            <button
                                                onClick={() => openEditField(user?.id)}
                                                className="btn btn-warning btn-xs"
                                                style={{ padding: "2px 5px", fontSize: "10px" }}
                                            >
                                                <FaEdit />
                                            </button>
                                        )}
                                        <button
                                            onClick={(e) => deleteById(user?.id, e)}
                                            className="btn btn-danger btn-xs"
                                            style={{ padding: "2px 5px", fontSize: "10px" }}
                                        >
                                            <FaTrash />
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        ))}
                </tbody>

            </Table>
            {!getOpenEdit &&
                <div style={{ display: "flex", padding: "5px", alignItems: "center" }}>
                    <div>
                        <div style={{ fontSize: "10px" }}>Total Rows: {rows}</div>
                        <div style={{ fontSize: "10px" }}>Page: {rows ? page : 0} of {pages}</div>
                        <p className="has-text-centered has-text-danger" style={{ fontSize: "10px" }}>{msg}</p>
                    </div>
                    <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
                        <nav
                            style={{ fontSize: "10px" }}
                            className="pagination is-centered"
                            key={rows}
                            role="navigation"
                            aria-label="pagination"
                        >
                            <ReactPaginate
                                previousLabel={<span style={{ fontSize: "10px" }}>{"<"}</span>}
                                nextLabel={<span style={{ fontSize: "10px" }}>{">"}</span>}
                                pageCount={Math.min(10, pages)}
                                onPageChange={changePage}
                                containerClassName={"pagination-list"}
                                pageLinkClassName={"pagination-link"}
                                previousLinkClassName={"pagination-previous"}
                                nextLinkClassName={"pagination-next"}
                                activeLinkClassName={"pagination-link is-current"}
                                disabledLinkClassName={"pagination-link is-disabled"}
                            />
                        </nav>
                    </div>
                </div>

            }
            {!getOpenAdd && !getOpenEdit &&
                <div style={{ display: "flex" }}>
                    <div
                        onClick={openAddField}
                        style={{
                            cursor: "pointer",
                            border: "none",
                            backgroundColor: "#667BFF",
                            color: "white",
                            borderRadius: "3px",
                            fontSize: "12px",
                            padding: "5px 10px",
                        }}
                    >
                        <FaPlus style={{ marginRight: "5px", fontSize: "10px" }} />Add
                    </div>
                </div>
            }
            {/* Form Tambah */}
            {getOpenAdd && (
                <div className="mb-3 p-3 border rounded" style={{ backgroundColor: "#f8f9fa" }}>
                    <Form>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "12px" }}>Nama Relasi</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={form.name}
                                        onChange={handleChange}
                                        placeholder="Masukkan nama relasi"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "12px" }}>Warna</Form.Label>
                                    <Form.Control
                                        type="color"
                                        name="color"
                                        value={form.color || "#563d7c"}
                                        onChange={handleChange}
                                        style={{ height: "30px", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                {getOpenAdd === true ? (
                                    <div style={{ display: "flex" }} className="mt-4">
                                        <div
                                            onClick={cancelAddField}
                                            className="mr-2"
                                            style={{
                                                border: "none",
                                                backgroundColor: "#BF0000",
                                                color: "white",
                                                borderRadius: "3px",
                                                fontSize: "12px",
                                                padding: "5px 10px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FaTimes style={{ marginRight: "4px", fontSize: "10px" }} />Cancel
                                        </div>
                                        <div
                                            onClick={handleSubmit}
                                            style={{
                                                border: "none",
                                                backgroundColor: "#19BF00",
                                                color: "white",
                                                borderRadius: "3px",
                                                fontSize: "12px",
                                                padding: "5px 10px",
                                                cursor: "pointer",
                                            }}
                                        >
                                            <FaSave style={{ marginRight: "4px", fontSize: "10px" }} />Simpan
                                        </div>
                                    </div>
                                ) : !getOpenEdit ? (
                                    <div style={{ display: "flex" }}>
                                        <div
                                            onClick={openAddField}
                                            style={{
                                                cursor: "pointer",
                                                border: "none",
                                                backgroundColor: "#667BFF",
                                                color: "white",
                                                borderRadius: "3px",
                                                fontSize: "12px",
                                                padding: "5px 10px",
                                            }}
                                        >
                                            <FaPlus style={{ marginRight: "5px", fontSize: "10px" }} />Add
                                        </div>
                                    </div>
                                ) : null}
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}

            {/* Form Edit */}
            {getOpenEdit && (
                <div className="mb-3 p-3 border rounded" style={{ backgroundColor: "#f8f9fa" }}>
                    <Form>
                        <Row className="align-items-center">
                            <Col md={6}>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "12px" }}>Nama Relasi</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="name"
                                        value={formData.name}
                                        onChange={handleChange}
                                        placeholder="Edit nama relasi"
                                        size="sm"
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                <Form.Group>
                                    <Form.Label style={{ fontSize: "12px" }}>Warna</Form.Label>
                                    <Form.Control
                                        type="color"
                                        name="color"
                                        value={formData.color}
                                        onChange={handleChange}
                                        style={{ height: "30px", width: "100%" }}
                                    />
                                </Form.Group>
                            </Col>
                            <Col md={3}>
                                {/* <Button variant="success" size="sm" className="mt-4">
                                    Simpan
                                </Button> */}
                                {getOpenEdit === true &&
                                    <div style={{ display: "flex" }} className="mt-4">
                                        <div
                                            onClick={() => { setGetOpenEdit(false); setGetDataId(null); }}
                                            className="mr-2" style={{ border: "none", backgroundColor: "#BF0000", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px", cursor: "pointer" }}>
                                            <FaTimes style={{ marginRight: "4px", fontSize: "10px" }} />Cancel
                                        </div>
                                        <div
                                            onClick={handleUpdate}
                                            style={{ border: "none", backgroundColor: "#19BF00", color: "white", borderRadius: "3px", fontSize: "12px", padding: "5px 10px", cursor: "pointer" }}>
                                            <FaSave style={{ marginRight: "4px", fontSize: "10px" }} />Update
                                        </div>
                                    </div>
                                }
                            </Col>
                        </Row>
                    </Form>
                </div>
            )}

        </>
    );
}
