import React, { useContext } from "react";
import { SettingGeneralContext, SettingGeneralProvider } from "../../../context/settingGeneralContext";
import { FaPager, FaUser } from "react-icons/fa";


export default function Announcement({
    getCountPatient,
    getCountAppointment,
}) {
    return (
        <SettingGeneralProvider>
            <Header
                getCountPatient={getCountPatient}
                getCountAppointment={getCountAppointment}
            />
        </SettingGeneralProvider>
    );
}

function Header({ getCountPatient, getCountAppointment }) {
    const { state } = useContext(SettingGeneralContext);
    const appName = state?.generalSettings?.app_name;
    const appSidebarColor1 = state?.showTheme?.app_sidebar_color_1;
    const appSidebarColor2 = state?.showTheme?.app_sidebar_color_2;
    const appSidebarColor3 = state?.showTheme?.app_sidebar_color_3;
    const appSidebarColor4 = state?.showTheme?.app_sidebar_color_4;
    const appSidebarColor5 = state?.showTheme?.app_sidebar_color_5;


    const countColor = appSidebarColor1 || "#3D64FF";
    const countColorIcon = appSidebarColor3 || "white";
    const countColorText = appSidebarColor1 || "#001F8B";

    return (
        <div style={{
            display: "flex",
            backgroundColor: "",
            marginTop: "15px"
        }}
        >
            <div
                style={{
                    flex: "50%",
                    paddingTop: "10px",
                    paddingBottom: "10px",
                    paddingLeft: "15px",
                    marginRight: "10px",
                    backgroundColor: "white",
                    display: "flex",
                    boxShadow: "2px 2px 10px #BFBFBF"
                }}>
                <div
                    style={{
                        display: "flex",
                        alignItems: "center",
                        marginRight: "10px",
                        padding: "0px 8px",
                        backgroundColor: `${countColor}`, borderRadius: "3px"
                    }}>
                    <FaUser
                        style={{
                            fontSize: "30px",
                            color: `${countColorIcon}`
                        }} />
                </div>
                <div>
                    <div style={{
                        fontSize: "20px",
                        color: `${countColorText}`
                    }}>
                        {getCountPatient ? getCountPatient : "0"}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                        Jumlah pasien hari ini
                    </div>
                </div>
            </div>

            <div style={{
                flex: "50%",
                paddingTop: "10px",
                paddingBottom: "10px",
                paddingLeft: "15px",
                marginRight: "0px",
                backgroundColor: "white",
                display: "flex",
                boxShadow: "2px 2px 10px #BFBFBF"
            }}>

                <div style={{
                    display: "flex",
                    alignItems: "center",
                    marginRight: "10px",
                    padding: "0px 8px",
                    backgroundColor: `${countColor}`,
                    borderRadius: "3px"
                }}>
                    <FaPager style={{
                        fontSize: "30px",
                        color: `${countColorIcon}`
                    }} />
                </div>
                <div>
                    <div style={{
                        fontSize: "20px",
                        color: `${countColorText}`
                    }}>
                        {getCountAppointment ? getCountAppointment : "0"}
                    </div>
                    <div style={{ fontSize: "10px" }}>
                        Jumlah appointment hari ini
                    </div>
                </div>
            </div>
        </div>
    );
};

