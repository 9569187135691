
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { ListGroup, Table } from "react-bootstrap";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaUserPlus, FaTag, FaRegEdit, FaUserEdit, FaTable, FaMedkit, FaDeviantart, FaRegStickyNote, FaTelegramPlane } from 'react-icons/fa'
import { AiOutlineUsergroupAdd } from "react-icons/ai";
import ReactPaginate from "react-paginate";
import { FaUsersBetweenLines } from "react-icons/fa6";

import { HiUser, HiUserGroup } from "react-icons/hi2";

import Modal from 'react-bootstrap/Modal';
import logo_side from "../../assets/signature/logo-side-detail.png"
import Logo_Signature from "../../assets/signature/signature logo.png"
import Logo_Batik from "../../assets/signature/logo-batik.png"
import "bulma/css/bulma.css";
import "./Styled.css"
import Swal from "sweetalert2";
import swal from "sweetalert";
import LoaderHome from "../Loader/LoaderHome"
import ModalDataPatient from "./ModalPatientAdd/ModalAdd"
import ModalDetailPatient from "./ModalPatientDetail/ModalDetail"
import ModalPatientRelation from "./ModalRelation/Modal";

export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []);
  document.title = "SDC Apps";
  const navigate = useNavigate()
  const [getData, setGetData] = useState([]);
  const [getDetailDataPatient, setGetDetailDataPatient] = useState([]);
  const [getAppointmentSSP, setGetAppointmentSSP] = useState([]);
  const [getDataPatientSsp, setGetDataPatientSsp] = useState([]);
  const [pagination, setPagination] = useState([])
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");

  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const colors = ["#A9B5DF", "#3D64FF", "#00EFB7", "#FF9090", "#FFE84D", "#00EB8D"];
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  // Responsive to mobile or dekstop
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })
  const [formUpdate, setFormUpdate] = useState(false);
  const [id, setId] = useState();
  const [modalUpdateDataPatient, setModalUpdateDataPatient] = useState(false);
  const [modalDetaiDataPatient, setModalDetailPatient] = useState(false);
  const [modalDataPatientRelation, setModalPatientRelation] = useState(false);
  const [idUpdatePatient, setIdUpdatePatient] = useState(false);
  const [idDetailPatient, setIdDetailPatient] = useState();

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patients/representative/index?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}&search=${keyword}`, fetchParams)

      // Checking process
      if (response?.status === 200) {
        setGetData(response?.data?.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDetailPatient = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`transaction-service/patients/${idDetailPatient}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDetailDataPatient(response?.data?.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataAppointmentSSP = async () => {
    try {
      setLoading(true);
      let allData = [];
      let currentPage = 1;
      let totalPages;

      do {
        const response = await API.get(
          `user-service/appointment-ssp?page=${currentPage}&limit=${limit}&ascending=${ascending}&search=${query}`,
          fetchParams
        );

        if (response?.status === 200) {
          const { data, total_pages } = response.data;
          allData = [...allData, ...data];
          totalPages = total_pages;
        }
        currentPage++;
      } while (currentPage <= totalPages);
      const ids = allData?.map((data) => data.id_patient);
      setGetAppointmentSSP(ids);
    } catch (error) {
      swal({
        title: "Failed",
        text: `${error.response?.data?.message || "Terjadi kesalahan"}`,
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    } finally {
      setLoading(false);
    }
  };

  const deleteById = async (id) => {
    Swal.fire({
      title: 'Apakah Kamu Yakin?',
      text: `Menghapus data ini`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Ya, Hapus'
    }).then(async (result) => {
      if (result.isConfirmed) {
        const response = await API.delete(`transaction-service/patients/${id}`, fetchParams);
        if (response.data.error === false) {
          GetResponseData()
          swal({
            title: 'Success',
            text: "Your data has been successfully deleted",
            icon: 'success',
            timer: 3000,
            buttons: false
          });
        }
      }
    })
  };

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };


  useEffect(() => {
    if (idDetailPatient) {
      GetResponseDetailPatient();
    } else {
      GetResponseData()
      GetResponseDataAppointmentSSP();
    }
  }, [idDetailPatient, page, keyword, ascending])

  const buttonRefresh = () => {
    window.location.reload();
  };

  const odontogramPatient = (id, first_name, last_name) => {
    navigate('/odontograms/' + id + "/" + first_name + "/" + last_name);
  };

  const soapPatient = (id, first_name, last_name) => {
    navigate('/soap-patients/' + id + "/" + first_name + "/" + last_name);
  };

  let modalPhysicalCheck = async (id) => {
    navigate('/phy-checks/' + id)
  };

  let modalRepresentative = async (id) => {
    navigate('/representative/' + id)
  };

  let modalMedicalRecord = async (id, code) => {
    navigate('/medical-record/' + id + "/" + code)
  };

  let modalSatuSehat = async (id) => {
    navigate('/satu-sehat/' + id)
  };

  const updateUser = (id) => {
    navigate('/representative/profile/' + id)
  }

  const modalMedisRoleAdd = (id_patient) => {
    setModalUpdateDataPatient(true)
    setIdUpdatePatient(id_patient)
  }

  const modalDetaiPatient = (id) => {
    setModalDetailPatient(true)
    setIdDetailPatient(id)
  }

  const modalPatientRelation = () => {
    setModalPatientRelation(true)
  }

  return (
    <div style={{ backgroundColor: "white", marginTop: "15px", marginLeft: "10px", marginRight: "10px", boxShadow: "2px 2px 10px #BFBFBF" }}>
      {modalUpdateDataPatient && <ModalDataPatient GetResponseData={GetResponseData} idUpdatePatient={idUpdatePatient} show={modalUpdateDataPatient} onHide={() => setModalUpdateDataPatient(false)} />}
      {modalDetaiDataPatient && <ModalDetailPatient GetResponseData={GetResponseDetailPatient} getDetailDataPatient={getDetailDataPatient} idDetailPatient={idDetailPatient} show={modalDetaiDataPatient} onHide={() => setModalDetailPatient(false)} />}
      {modalDataPatientRelation && <ModalPatientRelation GetResponseData={GetResponseData} show={modalDataPatientRelation} onHide={() => setModalPatientRelation(false)} />}

      {loading && <LoaderHome />}

      {isTabletOrMobile ?
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
          <Col xl="6" style={{ fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", padding: "7px", color: "#001F8B", backgroundColor: "#E9EFFF" }}>
            <FaAddressBook style={{ marginRight: "5px" }} />List Pasien
          </Col>
          <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent: "end", paddingRight: "5px" }}>
            <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 12px", borderRadius: "3px", cursor: "pointer", fontSize: "12px" }}>
              <FaPlus />
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "0px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "100%" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "2px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </Col>
        </div>
        :
        <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "flex", padding: "10px 0px" }}>
          <div style={{ flex: "50%", fontSize: "16px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "#001F8B" }}>
            <FaAddressBook style={{ marginRight: "5px" }} />List Pasien
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
            <div onClick={modalMedisRoleAdd} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#3D64FF", color: "white", padding: "0px 10px", borderRadius: "3px", cursor: "pointer", fontSize: "12px" }}>
              <FaPlus />
            </div>
            <div onClick={modalPatientRelation} style={{ display: "flex", alignItems: "center", marginRight: "5px", backgroundColor: "#A9B5DF", color: "white", padding: "0px 12px", borderRadius: "3px", fontSize: "15px", cursor: "pointer" }}>
              <FaUsersBetweenLines />
            </div>
            <div onClick={buttonRefresh} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "0px 10px", border: "1px solid #DEDEDE", borderRadius: "3px", cursor: "pointer" }}>
              <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
            </div>
            <form onSubmit={searchData} style={{ display: "flex", paddingRight: "10px" }}>
              <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                <input value={query}
                  onChange={(e) => setQuery(e.target.value)}
                  className="focused"
                  style={{ backgroundColor: "#E9E9E9", border: "none", height: "5vh" }}
                  type="text"
                  placeholder=""
                />
              </div>
              <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "3px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                Search
              </button>
            </form>
          </div>
        </div>
      }

      <Col xl='12' sm='12'>
        <div>
          <Table bordered responsive>
            <thead style={{ whiteSpace: "nowrap" }}>
              <tr style={{ backgroundColor: isTabletOrMobile ? "white" : "#E9EFFF" }}>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>No</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Nama Lengkap</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Jenis Kelamin</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Tanggal Lahir</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Usia</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Alamat Domisili</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Perwakilan Pasien</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Data Medis</th>
                <th style={{ fontFamily: "revert", fontSize: "12px", textAlign: "center", color: "#525252", border: "none" }}>Setup</th>
              </tr>
            </thead>
            <tbody>
              {getData.map((user, index) => (
                <tr key={index} style={{ fontFamily: "sans-serif", fontSize: "11px", textAlign: "center" }}>
                  <td style={{ lineHeight: "2" }}>{page === 1 ? index + 1 : page === 2 ? (index + 1) + 10 : (index + 1) + (page * 10)}</td>
                  <td style={{ lineHeight: "2" }}>{user.first_name}&nbsp;{user.last_name}</td>
                  <td style={{ lineHeight: "2" }}>{user.gender === 'male' ? 'Laki-Laki' : user.gender === 'female' ? 'Perempuan' : user.gender}</td>
                  <td style={{ lineHeight: "2" }}>
                    {user.date_birth.split('-')[2]}&nbsp;
                    {user.date_birth.split('-')[1] === "01" ? "Januari" : "" ||
                      user.date_birth.split('-')[1] === "02" ? "Februari" : "" ||
                        user.date_birth.split('-')[1] === "03" ? "Maret" : "" ||
                          user.date_birth.split('-')[1] === "04" ? "April" : "" ||
                            user.date_birth.split('-')[1] === "05" ? "Mei" : "" ||
                              user.date_birth.split('-')[1] === "06" ? "Juni" : "" ||
                                user.date_birth.split('-')[1] === "07" ? "Juli" : "" ||
                                  user.date_birth.split('-')[1] === "08" ? "Agustus" : "" ||
                                    user.date_birth.split('-')[1] === "09" ? "September" : "" ||
                                      user.date_birth.split('-')[1] === "10" ? "Oktober" : "" ||
                                        user.date_birth.split('-')[1] === "11" ? "November" : "" ||
                                          user.date_birth.split('-')[1] === "12" ? "Desember" : ""}&nbsp;
                    {user.date_birth.split('-')[0]}
                  </td>
                  <td style={{ lineHeight: "2", whiteSpace: "nowrap" }}>{user.age} Tahun</td>
                  <td style={{ lineHeight: "2" }}>{user.residence_address} </td>
                  <td style={{ lineHeight: "2", whiteSpace: "nowrap" }}>
                    {user.representatives.length > 0 && (
                      <div style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                        {user.representatives?.map((rep, repIndex) => (
                          <>
                            <div onClick={() => { modalDetaiPatient(rep?.patient?.id) }} key={repIndex} style={{
                              display: "flex",
                              border: "none",
                              padding: "5px 10px",
                              cursor: "pointer",
                              borderRadius: "3px",
                              color: "white",
                              backgroundColor: rep?.representative?.patient_relation
                                ? rep?.representative?.patient_relation?.color
                                : "gray",
                              fontWeight: "bold",
                              width: "120px",
                              justifyContent: "center",
                              marginBottom: "5px",
                              height: "20px",
                              alignItems: "center",
                              textAlign: "center",
                            }}>
                              <HiUser style={{ marginRight: "5px" }} />
                              {rep?.representative?.first_name} {rep?.representative?.last_name}
                            </div>
                          </>
                        ))}
                      </div>
                    )}
                  </td>

                  <td style={{ lineHeight: "2" }}>

                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => modalRepresentative(user?.id)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#A9B5DF", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <HiUserGroup style={{}} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Representative
                        </div>
                      </div>
                    </div>
                    <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => modalPhysicalCheck(user?.id)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#3D64FF", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <FaMedkit style={{}} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Physical Check
                        </div>
                      </div>
                    </div>
                    <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => modalMedicalRecord(user?.id, user?.code)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FF9090", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <FaMedkit style={{}} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Medical Record
                        </div>
                      </div>
                    </div>

                    <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => soapPatient(user?.id, user?.first_name, user?.last_name)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#FFE84D", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <FaRegStickyNote style={{}} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Soap Patient
                        </div>
                      </div>
                    </div>

                    <div className="mt-1" style={{ display: "flex", justifyContent: "center" }}>
                      <div onClick={() => odontogramPatient(user?.id, user?.first_name, user?.last_name)} style={{ display: "flex", border: "none", padding: "0px 7px", cursor: "pointer", borderRadius: "3px", backgroundColor: "#00EB8D", color: "white", fontWeight: "bold", width: "120px", justifyContent: "center" }}>
                        <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                          <FaDeviantart style={{}} />
                        </div>
                        <div style={{ display: "flex", alignItems: "center" }}>
                          Odontogram
                        </div>
                      </div>
                    </div>
                    {getAppointmentSSP.includes(user?.id) &&
                      <>
                        <div className="mt-1" style={{ display: "flex", justifyContent: "center", marginBottom: "5px" }} key={index}>
                          <div
                            onClick={() => modalSatuSehat(user?.id)} 
                            style={{
                              display: "flex",
                              border: "none",
                              padding: "0px 7px",
                              cursor: "pointer",
                              borderRadius: "3px",
                              backgroundColor: "#98DED9",
                              color: "white",
                              fontWeight: "bold",
                              width: "120px",
                              justifyContent: "center",
                            }}
                          >
                            <div style={{ display: "flex", alignItems: "center", marginRight: "5px" }}>
                              <FaTelegramPlane />
                            </div>
                            <div style={{ display: "flex", alignItems: "center" }}>Satu Sehat</div>
                          </div>
                        </div>
                      </>
                    }
                  </td>
                  <td style={{ lineHeight: "2" }}>
                    <div style={{ display: "flex", flexDirection: "column", gap: "3px", justifyContent: "center", alignItems: "center", alignContent: "center" }}>
                      <button onClick={() => { modalDetaiPatient(user?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#2c8efa", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                        <FaEye />
                      </button>
                      <button onClick={() => { updateUser(user?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#ff9933", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                        <FaEdit />
                      </button>
                      <button onClick={() => { deleteById(user?.id) }} style={{ fontSize: "12px", color: "white", backgroundColor: "#B60000", padding: "5px 5px", borderRadius: "3px", cursor: "pointer", border: "none", display: "flex" }}>
                        <FaTrash />
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>

        <div style={{ display: "flex", padding: "10px" }}>
          <div>
            <div style={{ fontSize: "12px" }}>
              Total Rows: {rows}
            </div>
            <div style={{ fontSize: "12px" }}>
              Page: {rows ? page : 0} of {pages}
            </div>
            <p className="has-text-centered has-text-danger">{msg}</p>
          </div>
          <div style={{ flex: "50%", display: "flex", justifyContent: "end" }}>
            <nav
              style={{ fontSize: "12px" }}
              className="pagination is-centered"
              key={rows}
              role="navigation"
              aria-label="pagination"
            >
              <ReactPaginate
                previousLabel={"<"}
                nextLabel={">"}
                pageCount={Math.min(10, pages)}
                onPageChange={changePage}
                containerClassName={"pagination-list"}
                pageLinkClassName={"pagination-link"}
                previousLinkClassName={"pagination-previous"}
                nextLinkClassName={"pagination-next"}
                activeLinkClassName={"pagination-link is-current"}
                disabledLinkClassName={"pagination-link is-disabled"}
              />
            </nav>
          </div>
        </div>
      </Col>

    </div>
  );
}
