import React, { useState, useEffect, useContext } from "react";
import { Col, Form, Image, Row } from "react-bootstrap";
import { API } from "../../../../config/api";
import swal from "sweetalert";
// import Grass from "../../../../assets/theme/grass.png";
import { SettingGeneralContext } from "../../../../context/settingGeneralContext";

const Preferences = (props) => {
    const token = localStorage.getItem("token");
    const { state, dispatch } = useContext(SettingGeneralContext);
    const [getData, setGetData] = useState([]);
    const [getDataShowTime, setGetDataShowTime] = useState({});
    const [selectedTheme, setSelectedTheme] = useState(null);

    const fetchParams = {
        headers: { Authorization: `${token}`, "Content-Type": "application/json" }
    };

    const GetResponseDataTheme = async () => {
        try {
            props?.setLoading(true);
            const response = await API.get(`user-service/settings/preferences/themes`, fetchParams);
            if (response?.status === 200) {
                setGetData(response.data.data);
            }
        } catch (error) {
            swal({
                title: "Failed",
                text: error.response?.data?.message || "Gagal mengambil data tema.",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            props?.setLoading(false);
        }
    };

    const GetResponseDataShowTheme = async (themeId) => {
        if (!themeId) return;

        try {
            props?.setLoading(true);
            const response = await API.get(`user-service/settings/preferences/themes/${themeId}`, fetchParams);
            if (response?.status === 200) {
                const themeData = response.data.data;

                localStorage.setItem("selectedTheme", JSON.stringify(themeData));
                dispatch({ type: "SET_SHOW_THEME", payload: themeData }); 

                setGetDataShowTime(themeData);
            }
        } catch (error) {
            swal({
                title: "Failed",
                text: "Gagal mengambil tema yang dipilih.",
                icon: "error",
                timer: 3000,
                buttons: false
            });
        } finally {
            props?.setLoading(false);
        }
    };



    useEffect(() => {
        const savedTheme = localStorage.getItem("selectedTheme");
        if (savedTheme) {
            try {
                const parsedTheme = JSON.parse(savedTheme);
                if (parsedTheme?.id) {
                    setSelectedTheme(parsedTheme.id);
                    dispatch({ type: "SET_SHOW_THEME", payload: parsedTheme });
                }
            } catch (error) {
                console.error("Error parsing savedTheme:", error);
            }
        }

        if (selectedTheme) {
            GetResponseDataShowTheme(selectedTheme);
        }

        GetResponseDataTheme();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedTheme]);



    const handleThemeChange = (themeId) => {
        setSelectedTheme(themeId);
        GetResponseDataShowTheme(themeId);
    };

    return (
        <Row>
            {getData.map((theme) => (
                <Col key={theme.id} xl="4" sm="4" className="text-center">
                    <Image src={theme.image || ""} fluid />
                    <Form.Check
                        type="radio"
                        name="themeSelection"
                        aria-label={`radio ${theme.id}`}
                        id={`theme-${theme.id}`}
                        value={theme.id}
                        checked={selectedTheme === theme.id}
                        onChange={() => handleThemeChange(theme.id)}
                    />
                </Col>
            ))}
        </Row>
    );
};

export default Preferences;
