/* eslint-disable jsx-a11y/alt-text */
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../../context/userContext";
import { useNavigate } from "react-router-dom";
import { Row, Col, Form, Input, Label, Button, CardText, CardTitle, FormFeedback, UncontrolledTooltip } from 'reactstrap'
import swal from "sweetalert";
import { useMediaQuery } from 'react-responsive'
import { API, setAuthToken } from "../../config/api";
import { FaUser, FaUserMd, FaGenderless, FaMailBulk, FaTransgender, FaPhone, FaAddressBook, FaAddressCard, FaEdit, FaTrash, FaSave, FaPlus, FaSearch, FaUserAlt, FaAccessibleIcon, FaCriticalRole, FaUserClock, FaUserCheck, FaAccusoft, FaArchive, FaBackspace, FaBackward, FaArrowCircleLeft, FaRegArrowAltCircleLeft, FaEyeDropper, FaRegEye, FaMehRollingEyes, FaBullseye, FaStreetView, FaEye, FaPerbyte, FaAngleUp, FaAdjust, FaMizuni, FaCreativeCommonsZero, FaCreativeCommons, FaAward, FaStethoscope, FaListOl, FaDAndD, FaDailymotion, FaGasPump, FaDesktop, FaFileMedical, FaBookMedical, FaCalendar, FaCalendarDay, FaCalendarAlt, FaTransgenderAlt, FaBirthdayCake, FaCalendarCheck, FaPhoenixFramework, FaPhoneAlt, FaPhoneSlash, FaMobile, FaMobileAlt, FaMarsStroke, FaWpforms, FaSync, FaSpinner, FaCheckCircle, FaTable, FaSignal, FaWeightHanging, FaWatchmanMonitoring, FaPager, FaFileInvoice, FaCamera } from 'react-icons/fa'
import ReactPaginate from "react-paginate";
import logo_side from "../../assets/signature/logo-side-detail.png"
import Swal from "sweetalert2";
import { useParams } from 'react-router-dom';
import "bulma/css/bulma.css";
import "../../index.css"
import WellcomeCard from "./Components/Wellcome";
import Header from "./Components/Header";
import Content from "./Components/Content";
import LoaderHome from "../Loader/LoaderHome"


export default function Login() {
  // let navigate = useNavigate();
  // useEffect(() => {
  //   if (localStorage.getItem("token") == null || undefined) {
  //       navigate("/");
  //     }
  // }, []); tes

  document.title = "SDC Apps";

  const [pagination, setPagination] = useState([])
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(1);
  const [rows, setRows] = useState(1);
  const [getCountAppointment, setCountAppointment] = useState();
  const [getCountPatient, setCountPatient] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const token = localStorage.getItem("token");
  const level = localStorage.getItem('level');
  const rolesData = JSON.parse(localStorage.getItem('roles')) || [];
  const filteredRoles = rolesData.filter(role => role.name === 'Doctor');
  const filteredRolesAdmin = rolesData.filter(role => role.name === 'Admin');
  const filteredRolesANurse = rolesData.filter(role => role.name === 'Nurse');
  const levelUser = localStorage.getItem('level');
  // const user_data = localStorage.getItem("user_data");
  const [idDelete, setIdDelete] = useState(null);
  const [confirmDelete, setConfirmDelete] = useState(null);
  const [show, setShow] = useState(false);
  const [search, setSearch] = useState("")
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [dataMap, setDataMap] = useState("");
  const [dataSearch, setDataSearch] = useState("");
  const [modalShow, setModalShow] = useState(false);
  const { code, id } = useParams();
  const [getData, setGetData] = useState([]);
  const [getDataCountDokter, setGetDataCountDokter] = useState([]);
  const [getDataProfile, setGetDataProfile] = useState([]);
  const [getDataProfileDetail, setGetDataProfileDetail] = useState([]);
  const [getDataTreatment, setGetDataTreatment] = useState([]);
  const [getDataAgreement, setGetDataAgreement] = useState([]);
  const [getCode, setGetCode] = useState([]);
  const { getCode2, setGetCode2 } = useState("");
  const [loading, setLoading] = useState(false);
  const style = { position: "fixed", top: "50%", left: "50%", transform: "translate(-50%, -50%)" };
  const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' })
  const isBigScreen = useMediaQuery({ query: '(min-width: 1824px)' })
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 980px)' })
  const isPortrait = useMediaQuery({ query: '(orientation: portrait)' })
  const isRetina = useMediaQuery({ query: '(min-resolution: 2dppx)' })

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };

  const GetResponseDataCountDoctor = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/dashboard-doctor/count?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataCountDokter(response.data)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseData = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`/transaction-service/transaction-service/dashboard/get-appointment/today?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetData(response.data.data)
        setPage(response.data.pagination.current_page);
        setPages(response.data.pagination.total_pages);
        setRows(response.data.pagination.total);
        setCountAppointment(response.data.countAppointment);
        setCountPatient(response.data.countPatient);
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  const GetResponseDataProfile = async () => {
    try {
      // e.preventDefault();
      setLoading(true)
      const response = await API.get(`user-service/auth/profile`, fetchParams)
      // Checking process
      if (response?.status === 200) {
        setGetDataProfile(response.data.data)
        setGetDataProfileDetail(response.data.data.user_detail)
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
    }
  }

  useEffect(() => {
    GetResponseData()

    GetResponseDataProfile()
    GetResponseDataCountDoctor()
  }, [page])

  const changePage = ({ selected }) => {
    setPage(selected + 1);
    if (selected === 10) {
      setMsg(
        ""
      );
    } else {
      setMsg("");
    }
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setMsg("");
    setKeyword(query);
  };

  const RefreshBottom = () => {
    window.location.reload()
  }

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };


  return (
    <div >
      {loading && <LoaderHome />}
      <Row className='match-height' style={{ backgroundColor: "#F3FFFD", padding: isTabletOrMobile ? "0px 0px 20px 0px" : "0px 20px 30px 10px" }}>
        <Col xl='8' style={{ marginTop: "10px" }}>
          <WellcomeCard
            getDataProfileNull={getDataProfileDetail === null}
            getDataProfileFirstName={getDataProfileDetail?.firstname}
            getDataProfileLastName={getDataProfileDetail?.lastname}
          />
          <Header getCountPatient={getCountPatient} getCountAppointment={getCountAppointment} />
          <div style={{ backgroundColor: "white", marginTop: "15px", height: "", boxShadow: "2px 2px 10px #BFBFBF" }}>
            {isTabletOrMobile ?
              <div style={{ paddingLeft: "0px", width: "100%", borderBottom: "5px solid #EEEEEE", display: "", padding: "0px 0px 10px 0px" }}>
                <Col xl="6" style={{ fontSize: "16px", display: "flex", justifyContent: "center", alignItems: "center", padding: "7px", color: "#001F8B", backgroundColor: "#E9EFFF" }}>
                  <FaAddressBook style={{ marginRight: "5px" }} />Daftar Antrian Pasien
                </Col>
                <Col className="mt-2" xl="6" style={{ display: "flex", justifyContent: "end", paddingRight: "5px" }}>
                  <div onClick={RefreshBottom} style={{ height: "100%", marginRight: "5px", paddingTop: "0px", backgroundColor: "white", padding: "10px 10px", borderRadius: "2px", cursor: "pointer", border: "1px solid #DEDEDE" }}>
                    <FaSync style={{ fontSize: "15px", marginRight: "0px", marginTop: "0px", display: "flex", alignItems: "center", height: "100%", color: "#3D64FF" }} />
                  </div>
                  <form onSubmit={searchData} style={{ display: "flex", paddingRight: "0px" }}>
                    <div style={{ marginRight: "5px", borderRadius: "3px" }}>
                      <input value={query}
                        onChange={(e) => setQuery(e.target.value)}
                        className="focused"
                        style={{ backgroundColor: "#E9E9E9", border: "none", height: "100%" }}
                        type="text"
                        placeholder=""
                      />
                    </div>
                    <button type="submit" style={{ border: "none", backgroundColor: "#3D64FF", color: "white", display: "flex", borderRadius: "2px", fontWeight: "600", fontSize: "12px", alignItems: "center", padding: "0px 10px" }}>
                      Search
                    </button>
                  </form>
                </Col>
              </div>
              :
              <Content
                RefreshBottom={RefreshBottom}
                searchData={searchData}
                query={query}
                setQuery={setQuery}
                filteredRolesANurse={filteredRolesANurse}
                filteredRolesAdmin={filteredRolesAdmin}
                filteredRoles={filteredRoles}
                levelUser={levelUser}
                getData={getData}
                rows={rows}
                page={page}
                pages={pages}
                changePage={changePage}
                msg={msg}
              />

            }
          </div>
        </Col>

        <Col xl='4' sm='12' style={{ height: "100%", paddingBottom: "20px", marginTop: "10px", backgroundColor: "white", boxShadow: "2px 2px 10px #BFBFBF", marginRight: "0px", paddingBottom: "30px" }}>
          <div style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: isTabletOrMobile ? "10px" : "0px", marginRight: "10px", backgroundColor: "white", }}>
            <div style={{ fontSize: "17px", fontFamily: "revert-layer", color: "#001F8B" }}>
              Profile
            </div>
          </div>


          {getDataProfile?.photo_profile === null ?
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "0px", paddingBottom: "0px", marginRight: "0px", backgroundColor: "white" }}>
              <img src={'https://storageapps.signatureanugerah.co.id/public/images/no-profile.png'} style={{ width: "45%", borderRadius: "50%", border: "1px solid #C9C9C9" }} />
            </div>
            :
            <div style={{ display: "flex", justifyContent: "center", paddingTop: "0px", paddingBottom: "0px", marginRight: "0px", backgroundColor: "white" }}>
              {/* <img  src={getDataProfile?.photo_profile} style={{width:"45%", borderRadius:"50%", border:"1px solid #C9C9C9"}}/> */}

              <figure class="image is-228x228">
                <img
                  src={getDataProfile?.photo_profile}
                  style={{ height: "295px" }}
                />
              </figure>
            </div>
          }

          <div className="mt-2" style={{ display: "flex", justifyContent: "center", paddingTop: "5px", paddingBottom: "5px", marginRight: "0px", backgroundColor: "#BAD2FF", color: "#001F8B", fontWeight: "600" }}>
            {getDataProfileDetail === null ? "-" : getDataProfileDetail?.firstname} {getDataProfileDetail === null ? "" : getDataProfileDetail?.lastname}
          </div>

          <div style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "40px", marginRight: "10px", backgroundColor: "white", marginTop: "10px" }}>
            <div style={{ fontSize: "13px", fontFamily: "sans-serif", fontWeight: "700" }}>
              Email
            </div>
            <div style={{ fontSize: "10px", display: "flex", height: "5vh" }}>
              <FaBookMedical style={{ fontSize: "20px", height: "100%", color: "#CC6600" }} />
              <div style={{ color: "#CC6600", fontSize: "12px", marginLeft: "10px", display: "flex", alignItems: "center", height: "100%", paddingBottom: "0px" }}>
                {getDataProfile?.email}
              </div>
            </div>
          </div>

          <div style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "40px", marginRight: "10px", backgroundColor: "white" }}>
            <div style={{ fontSize: "13px", fontFamily: "sans-serif", fontWeight: "700" }}>
              Tanggal Lahir
            </div>
            <div style={{ fontSize: "10px", display: "flex", height: "5vh" }}>
              <FaCalendarAlt style={{ fontSize: "20px", height: "100%", color: "#CC6600" }} />
              <div style={{ color: "#CC6600", fontSize: "12px", marginLeft: "10px", display: "flex", alignItems: "center", height: "100%" }}>
                {getDataProfileDetail === null ? "" : `${getDataProfileDetail.birth_place}`},&nbsp;
                {getDataProfileDetail === null ? "-" : `${getDataProfileDetail.birth_day}`.split("-")[2]}&nbsp;
                {getDataProfileDetail === null ? "" : `${getDataProfileDetail.birth_day}`.split("-")[1] === "01" ? "Januari" : "" ||
                  `${getDataProfileDetail.birth_day}`.split("-")[1] === "02" ? "Februari" : "" ||
                    `${getDataProfileDetail.birth_day}`.split("-")[1] === "03" ? "Maret" : "" ||
                      `${getDataProfileDetail.birth_day}`.split("-")[1] === "04" ? "April" : "" ||
                        `${getDataProfileDetail.birth_day}`.split("-")[1] === "05" ? "Mei" : "" ||
                          `${getDataProfileDetail.birth_day}`.split("-")[1] === "06" ? "Juni" : "" ||
                            `${getDataProfileDetail.birth_day}`.split("-")[1] === "07" ? "Juli" : "" ||
                              `${getDataProfileDetail.birth_day}`.split("-")[1] === "08" ? "Agustus" : "" ||
                                `${getDataProfileDetail.birth_day}`.split("-")[1] === "09" ? "September" : "" ||
                                  `${getDataProfileDetail.birth_day}`.split("-")[1] === "10" ? "Oktober" : "" ||
                                    `${getDataProfileDetail.birth_day}`.split("-")[1] === "11" ? "November" : "" ||
                                      `${getDataProfileDetail.birth_day}`.split("-")[1] === "12" ? "Desember" : ""}&nbsp;
                {getDataProfileDetail === null ? "" : `${getDataProfileDetail.birth_day}`.split("-")[0]}
              </div>
            </div>
          </div>

          <div style={{ paddingTop: "5px", paddingBottom: "5px", paddingLeft: "40px", marginRight: "10px", backgroundColor: "white" }}>
            <div style={{ fontSize: "13px", fontFamily: "sans-serif", fontWeight: "700" }}>
              Jenis Kelamin
            </div>
            <div style={{ fontSize: "10px", display: "flex", height: "5vh" }}>
              <FaTransgender style={{ fontSize: "20px", height: "100%", color: "#CC6600" }} />
              <div style={{ color: "#CC6600", fontSize: "12px", marginLeft: "10px", display: "flex", alignItems: "center", height: "100%" }}>
                {getDataProfileDetail === null ? "-" : capitalizeFirst(`${getDataProfileDetail?.gender}`)}
              </div>
            </div>
          </div>

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Usia & Tanggal Lahir
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaCalendarCheck style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%", paddingTop:"2px"}}>
                    24 Tahun, 22 April 1999
                </div>
              </div>
            </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Phone Number
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaMobileAlt style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                    {getDataProfileDetail === null?  "-" : getDataProfileDetail?.phone_number}
                </div>
              </div>
            </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Status
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaWpforms style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                    Belum Menikah
                </div>
              </div>
            </div> */}

          {/* <div style={{ paddingTop:"5px",paddingBottom:"5px",  paddingLeft:"40px",marginRight:"10px", backgroundColor:"white" }}>
              <div style={{fontSize:"13px",  fontFamily:"sans-serif", fontWeight:"700"}}>
                  Alamat
              </div>
              <div style={{ fontSize: "10px", display: "flex",  height:"5vh"}}>
                <FaAddressBook style={{fontSize:"20px",  height:"100%", color:"#CC6600"}}/>
                <div style={{color:"#CC6600",fontSize:"12px", marginLeft:"10px",  display:"flex", alignItems:"center",height:"100%"}}>
                {getDataProfileDetail === null?  "-" : getDataProfileDetail?.address}

                </div>
              </div>
            </div> */}
        </Col>
      </Row>
    </div>
  );
}

