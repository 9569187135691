import { useState, useEffect, useCallback } from "react";
import { Modal, Row, Col, Form } from "react-bootstrap";
import { API } from "../../../config/api";
import swal from "sweetalert";
import LoaderAction from "../../Loader/LoaderAction"
import { FaSave, FaTimes } from "react-icons/fa";



function MyModal(props) {
  const [loading, setLoading] = useState(false);

  const [dataRelation, setGetDataRelation] = useState([]);
  const token = localStorage.getItem("token");
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(100);
  const [pages, setPages] = useState();
  const [ascending, setAscending] = useState(0);
  const [rows, setRows] = useState(1);
  const [keyword, setKeyword] = useState("");
  const [query, setQuery] = useState("");
  const [msg, setMsg] = useState("");
  const [form, setForm] = useState({
    patient_relation: "",
  });

  let fetchParams = {
    headers: { "Authorization": `${token}`, "Content-Type": "application/json" }
  };


  const GetRepresentativeById = useCallback(async () => {
    try {
      setLoading(true);
      const response = await API.get(
        `/transaction-service/patients/relation/index?page=${page}&limit=${limit}&ascending=${ascending}&search=${keyword}`,
        {
          headers: {
            "Authorization": `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      if (response?.status === 200) {
        setGetDataRelation(response.data.data);
      }
    } catch (error) {
      swal({
        title: "Failed",
        text: `${error.response?.data?.message || "Something went wrong"}`,
        icon: "error",
        timer: 3000,
        buttons: false,
      });
    } finally {
      setLoading(false);
    }
  }, [token, page, limit, ascending, keyword]);

  
  
  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    try {
      e.preventDefault();
      setLoading(true)

      // Insert data for login process
      const response = await API.put(`transaction-service/patients/representative/${props?.id}`, {
        first_name: props?.first_name || '',
        last_name: props?.last_name || '',
        gender: props?.gender || '',
        date_birth: props?.date_birth || '',
        residence_address: props?.residence_address || '',
        phone_number: props?.phone_number || '',
        occupation: props?.occupation || '',
        nic_number: props?.nic_number || '',
        medical_record_code: props?.medical_record_code || '',
        nic_address: props?.nic_address || '-',
        patient_relation: form?.patient_relation,
      }, fetchParams);

      // Checking process
      if (response?.status === 200) {
        swal({
          title: 'Success',
          text: response.data.message,
          icon: 'success',
          timer: 5000,
          buttons: false
        })
        setForm({
          ...form,
          name: "",
          color: "",
        });
        props?.GetResponseData()
        // setGetOpenAdd(false)
        // setGetOpenEdit(false)
        props?.setShow(false)
        setLoading(false)
      }

    } catch (error) {
      swal({
        title: 'Failed',
        text: `${error.response.data.message}`,
        icon: 'error',
        timer: 3000,
        buttons: false
      });
      setLoading(false)
    }
  };

  useEffect(() => {
    if (props?.id) {
      GetRepresentativeById();
    }
  }, [props?.id, GetRepresentativeById]);


  return (
    <>
      {loading && <LoaderAction />}
      <Modal  {...props} backdrop={() => props?.setShow(false)} size="xs" aria-labelledby="contained-modal-title-vcenter" centered style={{ fontFamily: "sans-serif", border: "none" }}>
        <div style={{ width: "100%", display: "flex", padding: "10px 0px", backgroundColor: "#29B8FF" }}>
          <div style={{ flex: "92%", fontSize: "20px", display: "flex", alignItems: "center", paddingLeft: "10px", color: "white", fontWeight: "600" }}>
            Tambah Hubungan
          </div>
          <div style={{ flex: "8%", fontSize: "30px", display: "flex", alignItems: "center", justifyContent: "center", color: "white" }}>
            <FaTimes onClick={() => props?.setShow(false)} style={{ cursor: "pointer" }} />
          </div>
        </div>
        <Modal.Body style={{ backgroundColor: "", borderBottomLeftRadius: "5px", borderBottomRightRadius: "5px", border: "none", backgroundImage: "transparent", fontSize: "12px" }}>
          <Form>
            <Form.Group className="mb-3">
              <Form.Label>Pilih Hubungan Pasien</Form.Label>
              <Form.Select style={{ fontSize: "15px" }} onChange={handleChange} name="patient_relation">
                <option value="">- Pilih Hubungan Pasien -</option>
                {dataRelation.map((user, index) => (
                  <option key={index} value={user?.id}>
                    {user?.name}
                  </option>
                ))}
              </Form.Select>
              <div className="mt-4">
                <div
                  className="float-end"
                  onClick={handleSubmit}
                  style={{
                    border: "none",
                    backgroundColor: "#19BF00",
                    color: "white",
                    borderRadius: "3px",
                    fontSize: "12px",
                    padding: "5px 10px",
                    cursor: "pointer",
                  }}
                >
                  <FaSave style={{ marginRight: "4px", fontSize: "10px" }} />Simpan
                </div>
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default MyModal;
